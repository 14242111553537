import React, { useState, useEffect } from "react";
import Moment from "react-moment";
import txtYol from "../../helpers/dosyaiconları/txt.png";
import DialogScreen from "./DialogScreen";
import { connect } from "react-redux";
import moment from "moment";

import { Link } from "react-router-dom";

const MesajGiden = (props) => {
  const [uyari, setUyari] = useState(false);
  const [yonetici, setYonetici] = useState(false);
  const [isDurumlar, setIsDurumlar] = useState("");
  const [surec, setSurec] = useState("");

  const uyariAc = (e) => {
    setUyari(true);
  };

  useEffect(() => {
    if (sessionStorage.getItem("tokenKey")) setYonetici(true);
  }, []);

  useEffect(() => {
    setSurec(
      props.isGuncelleReducer.bilgiler && props.isGuncelleReducer.bilgiler.surec
    );
  }, [props.isGuncelleReducer.bilgiler]);

  useEffect(() => {
    setIsDurumlar(props.isGuncelleReducer.durumlar);
  }, [props.isGuncelleReducer.durumlar]);

  return (
    <aside key={props.icerik.id} className="isHikayesi giden-isHikayesi">
      <aside className="isItemKapsa gidenisKapsa">
        {props.icerik.durumu === 3 && props.icerik.silindi !== 1 ? (
          <i
            onClick={uyariAc}
            className="fa fa-times-circle mesajSilIcon"
            aria-hidden="true"
          ></i>
        ) : null}
        {uyari === true ? (
          <DialogScreen
            dialog={uyari}
            islem={"Mesajı"}
            olay={"silmek"}
            isid={props.icerik.isid}
            mesajId={props.icerik.id}
            dialogKapat={() => setUyari(false)}
          ></DialogScreen>
        ) : null}
        <aside className="isItem giden">
          <p className="gonderen-ad giden-ad">
            <i
              className="fa fa-user gonderenicon gidenicon"
              aria-hidden="true"
            ></i>

            <b>
              {props.icerik.kaydeden} (
              {props.icerik.durumadi
                ? props.icerik.durumadi
                : props.durumAdi[0] && props.durumAdi[0].tanim}
              ){" "}
            </b>
          </p>
          <aside className="mesaj-icerik">
            {props.icerik.dosya ? (
              <div>
                {props.icerik.dosya.substr(props.icerik.dosya.length - 4) ===
                  ".png" ||
                props.icerik.dosya.substr(props.icerik.dosya.length - 4) ===
                  ".jpg" ||
                props.icerik.dosya.substr(props.icerik.dosya.length - 4) ===
                  ".gif" ||
                props.icerik.dosya.substr(props.icerik.dosya.length - 5) ===
                  ".tiff" ||
                props.icerik.dosya.substr(props.icerik.dosya.length - 5) ===
                  ".jpeg" ? (
                  <div>
                    {props.icerik.silindi !== 1 ? (
                      <aside>
                        <Link
                          to={props.icerik.dosya.replace("arsiv/", "arsiv/k")}
                          target="_blank"
                        >
                          <img
                            className="mesaj-icerik-img"
                            src={props.icerik.dosya}
                            alt="Görsel Yüklenemedi..."
                          />
                        </Link>
                        <p className="gonderilen-icerik giden-icerik">
                          {props.icerik.dosyaaciklama !== "null" ? (
                            <span>{props.icerik.dosyaaciklama}</span>
                          ) : (
                            <span> </span>
                          )}
                        </p>
                      </aside>
                    ) : null}
                  </div>
                ) : null}
                {props.icerik.dosya.substr(props.icerik.dosya.length - 4) ===
                ".txt" ? (
                  <div>
                    <img src={txtYol} alt="Txt"></img>
                    <a href={props.icerik.dosya} target="_blank" download>
                      Txt Dosyasını Açmak İçin Tıklayın.
                    </a>
                    <p className="gonderilen-icerik giden-icerik">
                      {props.icerik.dosyaaciklama !== "null" ? (
                        <span>{props.icerik.dosyaaciklama}</span>
                      ) : (
                        <span> </span>
                      )}
                    </p>
                  </div>
                ) : null}
              </div>
            ) : null}
            {props.icerik.silindi === 1 ? (
              <p className="gonderilen-icerik giden-icerik">
                {`Bu içerik ${props.icerik.silen} tarafından ${moment(
                  props.icerik.silinmezamani
                ).format("LLL")} tarihinde silindi.`}
              </p>
            ) : (
              <p className="gonderilen-icerik giden-icerik">
                {props.icerik.aciklama !== null ? (
                  <span>{props.icerik.aciklama}</span>
                ) : (
                  <span> </span>
                )}
              </p>
            )}
          </aside>
        </aside>

        <p className="gndrmTrh giden-gndrmTr">
          <b className="gonderilen-saat">
            {<Moment format="DD MMMM YYYY HH:mm">{props.icerik.tarihi}</Moment>}
          </b>
        </p>
      </aside>
    </aside>
  );
};

const mapStateToProps = ({ isGuncelleReducer }) => {
  return {
    isGuncelleReducer,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MesajGiden);
