import {
  FETCH_LOADING_PENDING,
  FETCH_LOADING_FULFILLED,
  FETCH_LOADING_REJECTED,
} from "../actions/yoneticiAction";

const initialState = {
  error: null,
  done: false,
  fetching: false,
  ilk: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LOADING_PENDING:
      return { ...state, fetching: true, error: null, done: false };

    case FETCH_LOADING_FULFILLED:
      return {
        ...state,
        done: true,
        error: null,
        fetching: false,
      };
    case FETCH_LOADING_REJECTED:
      return {
        error: action.payload,
        fetching: false,
        done: false,
        ilk: false,
      };

    default:
      return state;
  }
};
