import { combineReducers } from "redux";
import userReducer from "../reducers/userReducer";
import bekleyenReducer from "../reducers/bekleyenReducer";
import devamEdenReducer from "../reducers/devamEdenReducer";
import tamamlananReducer from "../reducers/tamamlananReducer";
import kapatilanReducer from "./kapatilanReducer";
import isReducer from "../reducers/isReducer";
import silMesajReducer from "./silMesajReducer";
import bekleyenYoneticiReducer from "./bekleyenYoneticiReducer";
import isGeriAlReducer from "./isGeriAlReducer";
import kabulEtReducer from "./kabulEtReducer";
import metinGonderReducer from "./metinGonderReducer";
import loadingReducer from "./loadingReducer";
import dosyaGonderReducer from "./dosyaGonderReducer";
import reddetReducer from "./reddetReducer";
import mesajCevaplaMetinReducer from "./mesajCevaplaMetinReducer";
import isTamamlaReducer from "../reducers/isTamamlaReducer";
import dosyaCekReducer from "./dosyaCekReducer";
import hesabimReducer from "./hesabimReducer";
import yoneticiReducer from "./yoneticiReducer";
import sayilarReducer from "./sayilarReducer";
import yoneticiSiteReducer from "./yoneticiSiteReducer";
import siteTanimReducer from "./siteTanimReducer";
import siteSakinleriReducer from "./siteSakinleriReducer";
import isOlusturReducer from "./isOlusturReducer";
import isGuncelleReducer from "./isGuncelleReducer";
import parolaReducer from "./parolaReducer";
import yKullaniciBilgileriReducer from "./yKullaniciBilgieriReducer";

export default combineReducers({
  userReducer,
  bekleyenReducer,
  devamEdenReducer,
  tamamlananReducer,
  parolaReducer,
  kapatilanReducer,
  sayilarReducer,
  loadingReducer,
  siteSakinleriReducer,
  dosyaCekReducer,
  hesabimReducer,
  silMesajReducer,
  siteTanimReducer,
  mesajCevaplaMetinReducer,
  kabulEtReducer,
  isGeriAlReducer,
  isOlusturReducer,
  yKullaniciBilgileriReducer,
  reddetReducer,
  dosyaGonderReducer,
  metinGonderReducer,
  isTamamlaReducer,
  yoneticiSiteReducer,
  bekleyenYoneticiReducer,
  yoneticiReducer,
  isReducer,
  isGuncelleReducer,
});
