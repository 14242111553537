import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
//import { onLoginUser } from "../../redux/actions/userAction";
import { girisYap } from "../../redux/actions/userAction";
import { getCookie, yoneticiSorgula } from "../../redux/actions/yoneticiAction";
import { withRouter } from "react-router-dom";
import { RingLoader } from "react-spinners";
import SiteleriGetir from "../pages/SiteleriGetir";
import DialogFormScreen from "./DialogFormScreen";
import InputMask from "react-input-mask";
import HataMesaji from "../containers/HataMesaji";

const LoginPageForm = (props) => {
  useEffect(() => {}, []);
  const [bilgi, setBilgi] = useState(false);

  const [kulAdi, setKulAdi] = useState("");
  const [parola, setParola] = useState("");
  const [yonTel, setYonTel] = useState(
    getCookie("YCTelefon") ? getCookie("YCTelefon") : ""
  );

  const [buttonGizle, setButtonGizle] = useState(0);
  const [uyari, setUyari] = useState(false);

  const onChangeKulAdi = (event) => {
    setKulAdi(event.target.value);
  };

  const onChangeYoneticiTel = (event) => {
    setYonTel(event.target.value);
  };

  const onChangePass = (event) => {
    setParola(event.target.value);
  };

  const onSubmitLoginForm = (event) => {
    if (kulAdi !== "" && parola !== "") {
      //    props.onLoginUser(user.telNo, user.pass, props.history); // - sorgu atıldı
      props.girisYap(
        kulAdi
          .replace("(", "")
          .replace(")", "")
          .replace(" ", "")
          .replace("-", "")
          .replace("-", ""),
        parola,
        props.history
      );
    }
  };

  const submitYoneticiForm = (event) => {
    if (yonTel !== "") {
      // giris fonksiyonu burada çalışacak
      props.yoneticiSorgula(
        yonTel
          .replace("(", "")
          .replace(")", "")
          .replace(" ", "")
          .replace("-", "")
          .replace("-", ""),
        setButtonGizle
      );
    }
  };

  const parolaUnuttum = (event) => {
    setUyari(true);
  };

  return (
    <form
      className={`loginrightside ${props.yonetici === 1 ? "yntcarka" : ""} `}
      method="get"
    >
      {props.userReducer.fetching ? (
        <div className="load">
          <RingLoader size={100} color={"#e83538"} loading={true}></RingLoader>
        </div>
      ) : null}
      {props.yoneticiReducer.fetching ? (
        <div className="load">
          <RingLoader size={100} color={"#e83538"} loading={true}></RingLoader>
        </div>
      ) : null}
      {/* <div className="sabithgt">
        <aside className="inpformwrapper">
          <aside className="secim">
            <button
              className={`btn-ikili solbtn ${yonetici === 0 ? "solactif" : ""}`}
              onClick={gorevliyeGec}
            >
              Görevli/Personel Girişi
            </button>
            <button
              className={`btn-ikili sagbtn ${yonetici === 1 ? "sagactif" : ""}`}
              onClick={yoneticiyeGec}
            >
              Yönetici Girişi
            </button>
          </aside>
          <aside className="inpformwrapperkck">
            {yonetici === 0 ? (
              <h4 className="baslikLogin">Görevli/Personel Bilgileri</h4>
            ) : (
              <h4 className="baslikLogin">Yönetici Bilgileri</h4>
            )}

            <div className="sbth">
              <div className="inps tek mrgnsz">
                <div className="pst">
                  {yonetici === 0 ? (
                    <>
                      <label>
                        <span id="telNos">Cep Telefonu</span>
                      </label>
                      <InputMask
                        mask="(999) 999-99-99"
                        name="telNo"
                        placeholder="(5XX) XXX-XX-XX"
                        id="telNo"
                        type="text"
                        value={kulAdi}
                        onChange={onChangeKulAdi}
                      ></InputMask>
                    </>
                  ) : props.yoneticiReducer.yonetici.length > 0 ? null : (
                    <>
                      <label>
                        <span id="telNos">Cep Telefonu</span>
                      </label>
                      <InputMask
                        mask="(999) 999-99-99"
                        name="telNo"
                        id="telNo"
                        placeholder="(5XX) XXX-XX-XX"
                        type="text"
                        value={yonTel}
                        onChange={onChangeYoneticiTel}
                      ></InputMask>
                      <i className="fa fa-phone" aria-hidden="true"></i>{" "}
                    </>
                  )}
                </div>
              </div>

              {yonetici === 0 ? (
                <div className="inps tek">
                  <label>
                    <span>Parola</span>
                  </label>
                  <div className="pst">
                    <input
                      onChange={onChangePass}
                      placeholder="Parola giriniz"
                      id="pass"
                      name="pass"
                      type="password"
                    ></input>
                    <i className="fa fa-lock parla" aria-hidden="true"></i>{" "}
                  </div>
                </div>
              ) : null}
              {props.yoneticiReducer.error ? (
                <p className="errorYazi">{props.yoneticiReducer.error}</p>
              ) : null}
              {props.yoneticiReducer.done ? (
                <SiteleriGetir
                  setButtonGizle={setButtonGizle}
                  yonetici={yonetici}
                ></SiteleriGetir>
              ) : null}

              <div>
                {yonetici === 0 ? (
                  <div className="loginokbtn inps tek">
                    <button type="button" onClick={onSubmitLoginForm}>
                      Giriş Yap
                    </button>
                    {props.userReducer.error !== null ? (
                      <p className="errorYazi">Telefon No veya Şifre Hatalı!</p>
                    ) : null}
                  </div>
                ) : (
                  <div className="loginokbtn inps tek">
                    {buttonGizle === 0 ? (
                      <button type="button" onClick={submitYoneticiForm}>
                        Giriş Yap
                      </button>
                    ) : buttonGizle === 1 ? (
                      <button
                        style={{ display: "none" }}
                        type="button"
                        onClick={submitYoneticiForm}
                      >
                        Giriş Yap
                      </button>
                    ) : buttonGizle === 2 ? (
                      <button type="button" onClick={submitYoneticiForm}>
                        Tekrar Dene
                      </button>
                    ) : null}
                  </div>
                )}
              </div>
            </div>

            {yonetici === 0 ? (
              <aside className="passwordremember">
                <button
                  type="button"
                  className="parolamiUnuttumBtn"
                  onClick={parolaUnuttum}
                >
                  Parolamı Unuttum
                </button>
                {uyari === true ? (
                  <DialogFormScreen
                    dialog={uyari}
                    dialogKapat={() => setUyari(false)}
                  ></DialogFormScreen>
                ) : null}
              </aside>
            ) : (
              <aside className="passwordremember">
                <button type="button" className="parolamiUnuttumBtn">
                  Şifrenizi unuttuysanız (0282 606 01 90) destek isteyiniz..
                </button>
              </aside>
            )}
          </aside>
        </aside>
      </div> */}
    </form>
  );
};
const mapStateToProps = ({ userReducer, yoneticiReducer }) => {
  return {
    userReducer,
    yoneticiReducer,
  };
};

const mapDispatchToProps = {
  //onLoginUser,
  girisYap,
  yoneticiSorgula,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LoginPageForm));
