import React, { useState, useEffect } from "react";
import KanbanItem from "../pages/KanbanItem";
import { connect } from "react-redux";
import { bekleyenleriGetir } from "../../redux/actions/bekleyenAction";
import { yoneticiBekleyenleriGetir } from "../../redux/actions/bekleyenAction";
import { RingLoader } from "react-spinners";
import { API_YC_BASE } from "../../config/API_BASE";
import IsOlustur from "../pages/IsOlustur";
import { useDispatch } from "react-redux";

const Waiting = (props) => {
  window.history.pushState(null, null, window.location.href);
  window.onpopstate = function () {
    window.history.go(1);
  };
  const dispatch = useDispatch();
  const siteBilgileri = [];
  const [yonetici, setYonetici] = useState(false);
  const [table, setTable] = useState("");
  const [isTablosu, setIsTablosu] = useState("");
  const [isTurleri, setIsTurleri] = useState([
    { id: "", tanim: "İş Türü Seçebilirsiniz.." },
    { id: 1, tanim: "ÖNERİ İSTEK" },
    { id: 2, tanim: "BİLGİ TALEBİ" },
    { id: 3, tanim: "ARIZA" },
    { id: 5, tanim: "ÖDEME EMRİ" },
    { id: 4, tanim: "DİĞER" },
  ]);
  const [isAlanlari, setIsAlanlari] = useState("");
  const [personeller, setPersoneller] = useState("");

  useEffect(() => {
    if (sessionStorage.getItem("token")) {
      dispatch(bekleyenleriGetir());
      setYonetici(false);
    }
    if (sessionStorage.getItem("tokenKey")) {
      siteBilgileri.push(sessionStorage);
      // props.yoneticiBekleyenleriGetir(
      //   API_YC_BASE,
      //   sessionStorage.getItem("SiteID"),
      //   sessionStorage.getItem("SunucuID")
      // );

      dispatch(
        yoneticiBekleyenleriGetir(
          API_YC_BASE,
          sessionStorage.getItem("SiteID"),
          sessionStorage.getItem("SunucuID")
        )
      );

      setYonetici(true);
    }

    return () => {
      if (sessionStorage.getItem("token")) {
        bekleyenleriStateAta();
      }
    };
  }, []);

  // useEffect(() => {
  //   isTablosu &&
  //     isTablosu.forEach((v, i) => {
  //       console.log("deneme", v.isiolusturan);
  //       setYoneticiler((yoneticiler) => [...yoneticiler, v.isiolusturan]);
  //     });
  // }, [isTablosu]);

  // const onlyUnique = (gelen, index, aranacak) => {
  //   return aranacak.indexOf(gelen) === index;
  // };

  // useEffect(() => {
  //   console.log(yoneticiler);
  //   setSecilecekYoneticiler(yoneticiler && yoneticiler.filter(onlyUnique));
  // }, [yoneticiler]);

  useEffect(() => {
    setTable(props.bekleyenReducer.Table);
  }, [props.bekleyenReducer.Table]);

  const bekleyenleriStateAta = () => {
    setTable(props.bekleyenReducer.Table);
  };

  useEffect(() => {
    setIsTablosu(props.bekleyenYoneticiReducer.Table);
  }, [props.bekleyenYoneticiReducer.Table]);
  useEffect(() => {
    setIsAlanlari(props.siteTanimReducer.bilgiler.Table);
  }, [props.siteTanimReducer.bilgiler.Table]);
  useEffect(() => {
    setPersoneller(props.siteTanimReducer.personeller);
  }, [props.siteTanimReducer.personeller]);

  return (
    <div>
      {props.bekleyenReducer.fetching ? (
        <div className="load">
          <RingLoader size={100} color={"#D21113"} loading={true}></RingLoader>
        </div>
      ) : null}
      {props.loadingReducer.fetching ? (
        <div className="load">
          <RingLoader size={100} color={"#D21113"} loading={true}></RingLoader>
        </div>
      ) : null}

      <div className="secKanban">
        {sessionStorage.getItem("tokenKey") ? <IsOlustur></IsOlustur> : null}
        {yonetici ? (
          <div className="secNavWrapper">
            {isTablosu &&
              isTablosu.map((data, i) => {
                return (
                  <KanbanItem
                    key={i}
                    yonetici={yonetici}
                    yeniIs={false}
                    detaylar={data}
                    p={"bekleyen"}
                    gorevAdi={
                      isAlanlari &&
                      isAlanlari.filter((alan) => {
                        return Number(alan.id) === data.gorevid;
                      })
                    }
                    isinTuru={
                      isTurleri &&
                      isTurleri.filter((turs) => {
                        return Number(turs.id) === data.isinturu;
                      })
                    }
                    personel={
                      personeller &&
                      personeller.filter((pers) => {
                        return pers.kulid === data.kullaniciid;
                      })
                    }
                    isiolusturan={data.isiolusturan}
                    siteSakin={data.adsoyad}
                    durum={data.isindurumu}
                    id={data.id}
                    ad={data.siteadi ? data.siteadi : ""}
                    yenimesaj={data.yeni}
                    mesajokundu={data.okundu}
                    teslimtarihi={data.isintarihi}
                    olusturmatarihi={data.olusturmatarihi}
                    aciklama={data.isinaciklamasi}
                  />
                );
              })}
          </div>
        ) : (
          <div className="secNavWrapper">
            {table &&
              table.map((data, i) => {
                return (
                  <KanbanItem
                    detaylar={data}
                    key={i}
                    durum={data.isindurumu}
                    id={data.id}
                    p={"bekleyen"}
                    ad={data.siteadi}
                    yenimesaj={data.yeni}
                    mesajokundu={data.mesajokundu}
                    teslimtarihi={data.isintarihi}
                    olusturmatarihi={data.olusturmatarihi}
                    aciklama={data.isinaciklamasi}
                  />
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({
  bekleyenReducer,
  loadingReducer,
  bekleyenYoneticiReducer,
  siteTanimReducer,
}) => {
  return {
    bekleyenReducer,
    loadingReducer,
    bekleyenYoneticiReducer,
    siteTanimReducer,
  };
};
const mapDispatchToProps = {
  bekleyenleriGetir,
  yoneticiBekleyenleriGetir,
};

export default connect(mapStateToProps, mapDispatchToProps)(Waiting);
