import {
  CHECK_DOSYA_PENDING,
  CHECK_DOSYA_FULFILLED,
  CHECK_DOSYA_REJECTED
} from "../actions/isAction";

const initialState = {
  data: {},
  fetching: false,
  fetched: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CHECK_DOSYA_PENDING:
      return { ...state, fetching: true, fetched: false };

    case CHECK_DOSYA_FULFILLED:
      return {
        ...state,
        data: action.payload,
        fetching: false,
        fetched: true
      };
    case CHECK_DOSYA_REJECTED:
      return {
        error: action.payload,
        fetching: false,
        ...state,
        fetched: false
      };

    default:
      return state;
  }
};
