import axios from "axios";
import {
  getJson,
  dosyaGonderr,
  personelSmsGonder,
  loadingTrue,
  loadingFalse,
} from "./yoneticiAction";
import { postJson } from "./yoneticiAction";
import moment from "moment";
import { pgorsun } from "./yoneticiAction";
export const FETCH_ISDURUMU_PENDING = "FETCH_ISDURUMU_PENDING";
export const FETCH_ISDURUMU_FULLFILLED = "FETCH_ISDURUMU_FULLFILLED";
export const FETCH_ISDURUMUBILGILERI_FULLFILLED =
  "FETCH_ISDURUMUBILGILERI_FULLFILLED";

export const sorguYolBaslangic = "https://yonetici1.yonetimcell.com/";

export const guncellenecekIsiGetir = (isId) => {
  return (dispatch) => {
    dispatch(loadingTrue());
    getJson(
      `${sorguYolBaslangic}istakibi/sorgu`,
      `&sorguid=${1003}&id=${isId}${
        sessionStorage.getItem("proid")
          ? `&proid=${sessionStorage.getItem("proid")}`
          : ""
      }`,
      (data) => {
        data = data.data;
        if (data.Table && data.Table.length > 0) {
          dispatch({ type: FETCH_ISDURUMU_PENDING, fetching: true });
          if (data.Table[0].isindurumu === 7) {
            dispatch({
              type: FETCH_ISDURUMU_FULLFILLED,
              fetching: false,
              durumlar: [
                {
                  id: 4,
                  tanim: "İŞLEME GERİ ALINDI",
                  resim: "islemealindi.png",
                },
                { id: 7, tanim: "KAPATILDI", resim: "beklemede.png" },
              ],
            });
          } else if (data.Table[0].isindurumu === 6) {
            dispatch({
              type: FETCH_ISDURUMU_FULLFILLED,
              fetching: false,
              durumlar: [
                {
                  id: 4,
                  tanim: "İŞLEME GERİ ALINDI",
                  resim: "islemealindi.png",
                },
                { id: 6, tanim: "SİLİNDİ", resim: "tamamlandi.png" },
              ],
            });
          } else if (data.Table[0].isindurumu === 5) {
            dispatch({
              type: FETCH_ISDURUMU_FULLFILLED,
              fetching: false,
              durumlar: [
                {
                  id: 4,
                  tanim: "İŞLEME GERİ ALINDI",
                  resim: "islemealindi.png",
                },
                { id: 5, tanim: "TAMAMLANDI", resim: "tamamlandi.png" },
                { id: 6, tanim: "SİLİNDİ", resim: "tamamlandi.png" },
                { id: 7, tanim: "KAPATILDI", resim: "beklemede.png" },
              ],
            });
          } else if (data.Table[0].isindurumu === 4) {
            dispatch({
              type: FETCH_ISDURUMU_FULLFILLED,
              fetching: false,
              durumlar: [
                {
                  id: 4,
                  tanim: "İŞLEME GERİ ALINDI",
                  resim: "islemealindi.png",
                },
                { id: 5, tanim: "TAMAMLANDI", resim: "tamamlandi.png" },
                { id: 6, tanim: "SİLİNDİ", resim: "tamamlandi.png" },
              ],
            });
          } else if (data.Table[0].isindurumu === 3) {
            dispatch({
              type: FETCH_ISDURUMU_FULLFILLED,
              fetching: false,
              durumlar: [
                { id: 3, tanim: "GÜNCELLENDİ", resim: "islemealindi.png" },
                { id: 5, tanim: "TAMAMLANDI", resim: "tamamlandi.png" },
                { id: 6, tanim: "SİLİNDİ", resim: "tamamlandi.png" },
              ],
            });
          } else if (data.Table[0].isindurumu === 2) {
            dispatch({
              type: FETCH_ISDURUMU_FULLFILLED,
              fetching: false,
              durumlar: [
                { id: 2, tanim: "KABUL EDİLDİ", resim: "islemealindi.png" },
                { id: 3, tanim: "GÜNCELLENDİ", resim: "islemealindi.png" },
                { id: 5, tanim: "TAMAMLANDI", resim: "tamamlandi.png" },
                { id: 6, tanim: "SİLİNDİ", resim: "tamamlandi.png" },
              ],
            });
          } else if (data.Table[0].isindurumu === 1) {
            dispatch({
              type: FETCH_ISDURUMU_FULLFILLED,
              fetching: false,
              durumlar: [
                { id: 1, tanim: "BEKLEMEDE", resim: "beklemede.png" },
                { id: 2, tanim: "KABUL EDİLDİ", resim: "islemealindi.png" },
                { id: 3, tanim: "GÜNCELLENDİ", resim: "islemealindi.png" },
                { id: 5, tanim: "TAMAMLANDI", resim: "tamamlandi.png" },
                { id: 6, tanim: "SİLİNDİ", resim: "tamamlandi.png" },
              ],
            });
          } else if (data.Table[0].isindurumu === -1) {
            dispatch({
              type: FETCH_ISDURUMU_FULLFILLED,
              fetching: false,
              durumlar: [
                { id: -1, tanim: "REDDEDİLDİ", resim: "beklemede.png" },
                { id: 1, tanim: "BEKLEMEDE", resim: "beklemede.png" },
                { id: 2, tanim: "KABUL EDİLDİ", resim: "islemealindi.png" },
                { id: 3, tanim: "GÜNCELLENDİ", resim: "islemealindi.png" },
                { id: 5, tanim: "TAMAMLANDI", resim: "tamamlandi.png" },
                { id: 6, tanim: "SİLİNDİ", resim: "tamamlandi.png" },
              ],
            });
          }

          dispatch({
            type: FETCH_ISDURUMUBILGILERI_FULLFILLED,
            fetching: false,
            isBilgileri: {
              secilidurum: data.Table[0].isindurumu,
              simdikidurum: data.Table[0].isindurumu,
              isinaciklamasi: data.Table[0].isinaciklamasi,
              uyeid: data.Table[0].uyeid,
              adsoyad: data.Table[0].adsoyad,
              telefon: data.Table[0].telefon,
              eposta: data.Table[0].email,
              blok: data.Table[0].blok,
              kapino: data.Table[0].kapino,
              olusturmatarihi: data.Table[0].olusturmatarihi,
              teslimtarihi: data.Table[0].isintarihi,
              simdikiteslimtarihi: data.Table[0].isintarihi,
              seciligorev: data.Table[0].gorevid,
              simdikigorev: data.Table[0].gorevid,
              secilipersonel: data.Table[0].kullaniciid
                ? data.Table[0].kullaniciid
                : null,
              simdikipersonel: data.Table[0].kullaniciid
                ? data.Table[0].kullaniciid
                : null,
              talepid: data.Table[0].talepid,
              secilitur: data.Table[0].isinturu,
              simdikitur: data.Table[0].isinturu,
              isiolusturan: data.Table[0].isiolusturan,
              siteadi: data.Table[0].siteadi,
              proid: data.Table[0].proid,
              pgorsun: data.Table[0].pgorsun,

              dosyalar: data.Table1,
              surec: data.Table2,
              seciliyor: false,
            },
          });
          dispatch(loadingFalse());
        }
      }
    );
  };
};

export const personelGorsunCheck = (isId, int2) => {
  getJson(
    `${sorguYolBaslangic}istakibi/sorgu`,
    `&sorguid=1109&id=${isId}&int2=${int2 === true || int2 === null ? 1 : ""}`,
    (dt) => {}
  );
};

export const dosyaKaydets = (
  simdikipersonel,
  simdikipersonelTel,
  gelen,
  dosya,
  isid,
  talepid,
  dosyagosteryolu,
  uyeid
) => {
  return (dispatch) => {
    dosyaGonderr("arsiv", dosya, 200, 0, 748, 0, (data) => {
      dispatch(
        postJson(
          `${sorguYolBaslangic}/istakibi/postsorgu`,
          {
            SiteId: sessionStorage.getItem("SiteID"),
            SunucuId: sessionStorage.getItem("SunucuID"),
            sorguid: 1102,
            id: isid,
            string2: gelen,
            string1: `${dosyagosteryolu}arsiv/${data}`,
            string3: sessionStorage.getItem("KullaniciId"),
            isiolusturan: sessionStorage.getItem("KullaniciAd"),
            proid: sessionStorage.getItem("proid")
              ? sessionStorage.getItem("proid")
              : "",
          },
          -1,
          (dt) => {
            if (dt.Table) {
              dt = dt.Table;
            }
            dispatch(
              isOkunduIptal(simdikipersonel, isid, (sonuc) => {
                if (talepid) {
                  dispatch(
                    postJson(
                      `${sorguYolBaslangic}mesajlar/cevapla`,
                      {
                        mesajid: talepid,
                        metin: `${gelen}(${sessionStorage.getItem(
                          "KullaniciAd"
                        )})`,
                        uyeid: uyeid,
                        mailbaslik: null,
                        dosya: `${dosyagosteryolu}arsiv/${data}`,
                        issurecid: dt && dt[0] ? dt[0].id : null,
                      },
                      0,
                      (msj) => {
                        dispatch(
                          bildirimGonder(
                            simdikipersonel,
                            simdikipersonelTel,
                            "İş için güncelleme alındı.",
                            "Dosya Eklendi.",
                            (ss) => {
                              dispatch(guncellenecekIsiGetir(isid));
                            }
                          )
                        );
                      }
                    )
                  );
                } else {
                  dispatch(
                    bildirimGonder(
                      simdikipersonel,
                      simdikipersonelTel,
                      "İş için güncelleme alındı.",
                      "Dosya Eklendi.",
                      (ss) => {
                        dispatch(guncellenecekIsiGetir(isid));
                      }
                    )
                  );
                }
              })
            );
          }
        )
      );
    });
  };
};

export const isOkunduIptal = (simdikipersonel, isid, sonuc) => {
  return (dispatch) => {
    if (!simdikipersonel) {
      dispatch(
        getJson(
          `${sorguYolBaslangic}istakibi/sorgu`,
          `&sorguid=1113&id=${isid}`,
          (dt) => {
            sonuc(1);
          }
        )
      );
    } else {
      sonuc(1);
    }
  };
};

export const bildirimGonder = (
  secilipersonel,
  personeller,
  baslik,
  metin,
  sonuc
) => {
  return (dispatch) => {
    if (secilipersonel) {
      dispatch(
        postJson(
          `${sorguYolBaslangic}bildirim/gonder/istakibi`,
          [
            {
              username: personeller.filter((pers) => {
                return pers.kulid === secilipersonel;
              })[0].telefon,
              baslik: baslik,
              icerik: `${
                metin
                  ? metin.length > 100
                    ? metin.substr(0, 100) + "..."
                    : metin
                  : " "
              }`,
            },
          ],
          -1,
          (dd) => {
            sonuc(1);
          }
        )
      );
    } else {
      sonuc(1);
    }
  };
};

export const isGuncels = function (
  id,
  { ...gelenIsBilgileri },
  personeller,
  personelSms,
  metin
) {
  return function (dispatch) {
    if (gelenIsBilgileri.secilidurum < 5) {
      dispatch(
        aciklamaGuncelle(
          id,
          metin,
          gelenIsBilgileri.talepid,
          gelenIsBilgileri.uyeid,
          gelenIsBilgileri.secilipersonel,
          personeller,
          metin ? 1 : 0,
          function (sonuc) {
            dispatch(
              gorevGuncelle(
                id,
                gelenIsBilgileri.simdikigorev,
                gelenIsBilgileri.simdikigorev !== gelenIsBilgileri.seciligorev
                  ? 1
                  : 0,
                function (sonuc) {
                  dispatch(
                    personelguncelle(
                      gelenIsBilgileri,
                      personelSms,
                      id,
                      personeller,
                      gelenIsBilgileri.simdikipersonel,
                      gelenIsBilgileri.simdikipersonel !==
                        gelenIsBilgileri.secilipersonel
                        ? 1
                        : 0,
                      function (sonuc) {
                        dispatch(
                          teslimtarihiguncelle(
                            id,
                            gelenIsBilgileri.talepid,
                            gelenIsBilgileri.simdikipersonel,
                            personeller,
                            gelenIsBilgileri.isinaciklamasi,
                            gelenIsBilgileri.uyeid,
                            gelenIsBilgileri.simdikiteslimtarihi,
                            gelenIsBilgileri.teslimtarihi !==
                              gelenIsBilgileri.simdikiteslimtarihi
                              ? 1
                              : 0,
                            function (sonuc) {
                              dispatch(
                                durumguncelle(
                                  id,
                                  gelenIsBilgileri.simdikipersonel,
                                  personeller,
                                  gelenIsBilgileri.talepid,
                                  gelenIsBilgileri.simdikidurum,
                                  gelenIsBilgileri.simdikidurum,
                                  gelenIsBilgileri.uyeid,
                                  gelenIsBilgileri.isinaciklamasi,
                                  gelenIsBilgileri.secilidurum,
                                  gelenIsBilgileri.simdikidurum,
                                  gelenIsBilgileri.simdikidurum !==
                                    gelenIsBilgileri.secilidurum
                                    ? 1
                                    : 0,
                                  function (sonuc) {
                                    dispatch(
                                      turguncelle(
                                        id,
                                        gelenIsBilgileri.simdikitur,
                                        gelenIsBilgileri.simdikitur !==
                                          gelenIsBilgileri.secilitur
                                          ? 1
                                          : 0,
                                        function (sonuc) {
                                          dispatch(
                                            isOkunduIptal(
                                              gelenIsBilgileri.simdikipersonel,
                                              id,
                                              function (sonuc) {
                                                // yeni açıklamayı boşalt.
                                                // ve tekrar işi getir.
                                                // if (.smsicinsor()) {
                                                //     .bildirimgondersakin(sessionStorage.getItem('SiteAdi'), "Talebinizin durumu " + anako.durumlar().filter(function (k) { return self.secilidurum() == k.id })[0].tanim + " olarak güncellendi.", function (sonuc) {
                                                //         if (telkontrol(.telefon())) {
                                                //             mesajsor("Site Sakinine Sms ile durum bilgisi gönderilsin mi?", "altko.sakinsmsgonderfunction()");
                                                //         }
                                                //     });
                                                // }
                                                dispatch(
                                                  guncellenecekIsiGetir(id)
                                                );
                                              }
                                            )
                                          );
                                        }
                                      )
                                    );
                                  }
                                )
                              );
                            }
                          )
                        );
                      }
                    )
                  );
                }
              )
            );
          }
        )
      );
    } else {
      if (gelenIsBilgileri.secilidurum !== gelenIsBilgileri.simdikidurum) {
        //mesajsor("İşin durumunu " + anako.durumlar().filter(function (k) { return self.secilidurum() == k.id })[0].tanim + " olarak değiştirmek istediğinize emin misiniz?", "altko.durumguncelle2()");
      }
    }
  };
};

// export const durumguncelle2 = (gelenIsBilgileri) => {
//   durumguncelle(id,secilipersonel,personeller,talepid, , function (sonuc) {
//     self.yeniaciklama(null);
//     self.isigetir();
//     if (self.smsicinsor() && self.secilidurum() > 4) {
//         self.bildirimgondersakin(sessionStorage.getItem('SiteAdi'), "Talebinizin durumu " + anako.durumlar().filter(function (k) { return self.secilidurum() == k.id })[0].tanim + " olarak güncellendi.", function (sonuc) {
//             if (telkontrol(self.telefon())) {
//                 mesajsor("Site Sakinine Sms ile durum bilgisi gönderilsin mi?", "altko.sakinsmsgonderfunction()");
//             }
//         });
//     }
// });
// }

export const durumguncelle = (
  isid,
  secilipersonel,
  secilipersonelTel,
  talepid,
  simdikidurum,
  simdikidurumtanimi,
  uyeid,
  isinaciklamasi,
  secilidurum,
  isindurumtanimi,
  guncelle,
  sonuc
) => {
  return (dispatch) => {
    if (guncelle === 1) {
      getJson(
        `${sorguYolBaslangic}istakibi/sorgu`,
        "&sorguid=1107&id=" +
          isid +
          "&durumu=" +
          simdikidurum +
          "&string3=" +
          sessionStorage.getItem("KullaniciId") +
          "&isiolusturan=" +
          sessionStorage.getItem("KullaniciAd") +
          `${
            sessionStorage.getItem("proid")
              ? `&proid=${sessionStorage.getItem("proid")}`
              : ""
          }`,
        function (dt) {
          if (talepid && simdikidurum > secilidurum) {
            //sms için sor tam burada olmalı.
            dispatch(
              postJson(
                `${sorguYolBaslangic}mesajlar/cevapla`,
                {
                  mesajid: talepid,
                  metin:
                    'TALEBİNİZİN DURUMU "' +
                    isindurumtanimi +
                    '" OLARAK GÜNCELLENDİ' +
                    " (" +
                    sessionStorage.getItem("KullaniciAd") +
                    ")",
                  uyeid: uyeid,
                  mailbaslik: null,
                },
                0,
                function (data) {
                  dispatch(
                    bildirimGonder(
                      secilipersonel,
                      secilipersonelTel,
                      "İşin durumu " +
                        isindurumtanimi +
                        " olarak güncellendi..",
                      isinaciklamasi,
                      function (ss) {
                        sonuc(1);
                      }
                    )
                  );
                }
              )
            );
          } else {
            dispatch(
              bildirimGonder(
                secilipersonel,
                secilipersonelTel,
                "İşin durumu " + simdikidurumtanimi + " olarak güncellendi..",
                isinaciklamasi,
                function (ss) {
                  sonuc(1);
                }
              )
            );
          }
          // durumun güncellendiği yer.
        }
      );
    } else {
      sonuc(1);
    }
  };
};

export const aciklamaGuncelle = (
  isid,
  yeniAciklama,
  talepid,
  uyeid,
  secilipersonel,
  secilipersonelTel,
  guncelle,
  sonuc
) => {
  return (dispatch) => {
    if (guncelle === 1) {
      dispatch(
        postJson(
          `${sorguYolBaslangic}istakibi/postsorgu`,
          {
            SiteId: sessionStorage.getItem("SiteID"),
            SunucuId: sessionStorage.getItem("SunucuID"),
            sorguid: 1103,
            id: isid,
            string2: yeniAciklama,
            string3: sessionStorage.getItem("KullaniciId"),
            proid: sessionStorage.getItem("proid")
              ? sessionStorage.getItem("proid")
              : "",

            isiolusturan: sessionStorage.getItem("KullaniciAd"),
          },
          -1,
          (dt) => {
            if (dt.Table) {
              dt = dt.Table;
            }
            if (talepid) {
              dispatch(
                postJson(
                  `${sorguYolBaslangic}mesajlar/cevapla`,
                  {
                    mesajid: talepid,
                    metin: `${yeniAciklama}(${sessionStorage.getItem(
                      "KullaniciAd"
                    )})`,
                    uyeid: uyeid,
                    mailbaslik: null,
                    issurecid: dt && dt[0] ? dt[0].id : null,
                  },
                  0,
                  (data) => {
                    dispatch(
                      bildirimGonder(
                        secilipersonel,
                        secilipersonelTel,
                        "İş için güncelleme alındı.",
                        `${yeniAciklama}`,
                        (ss) => {
                          sonuc(1);
                        }
                      )
                    );
                  }
                )
              );
            } else {
              dispatch(
                bildirimGonder(
                  secilipersonel,
                  secilipersonelTel,
                  "İş için güncelleme alındı.",
                  `${yeniAciklama}`,
                  (ss) => {
                    sonuc(1);
                  }
                )
              );
            }
          }
        )
      );
    } else {
      sonuc(1);
    }
  };
};

export const gorevGuncelle = (isid, seciligorev, guncelle, sonuc) => {
  return (dispatch) => {
    if (guncelle === 1) {
      getJson(
        `${sorguYolBaslangic}istakibi/sorgu`,
        `&sorguid=1105&id=${isid}&gorevid=${seciligorev}&string3=${sessionStorage.getItem(
          "KullaniciId"
        )}&isiolusturan=${sessionStorage.getItem("KullaniciAd")}${
          sessionStorage.getItem("proid")
            ? `&proid=${sessionStorage.getItem("proid")}`
            : ""
        }`,
        (dt) => {
          // secili gorev ile simdiki gorevi değiştirdiği yer
          // bende durum farklı ben ön tarafta değiştirmiştim
          //onun için buraya şimdiki gorevi de koyabiliriz.
          //Denemeye göre hareket edelim
          dispatch(sonuc(1));
        }
      );
    } else {
      sonuc(1);
    }
  };
};

export const turguncelle = (isid, secilitur, guncelle, sonuc) => {
  return (dispatch) => {
    if (guncelle === 1) {
      getJson(
        `${sorguYolBaslangic}istakibi/sorgu`,
        "&sorguid=1108&id=" + isid + "&int2=" + (secilitur ? secilitur : ""),
        function (dt) {
          // simdiki tur ile guncellediği yer burası
          dispatch(sonuc(1));
        }
      );
    } else {
      sonuc(1);
    }
  };
};

export const personelguncelle = (
  secilenler,
  personelSms,
  isid,
  personeller,
  secilipersonel,
  guncelle,
  sonuc
) => {
  return (dispatch) => {
    if (guncelle === 1) {
      getJson(
        `${sorguYolBaslangic}istakibi/sorgu`,
        "&sorguid=1106&id=" +
          isid +
          "&string2=" +
          secilipersonel +
          "&string3=" +
          sessionStorage.getItem("KullaniciId") +
          "&isiolusturan=" +
          sessionStorage.getItem("KullaniciAd") +
          `${
            sessionStorage.getItem("proid")
              ? `&proid=${sessionStorage.getItem("proid")}`
              : ""
          }`,
        function (dt) {
          // simdiki personel ile güncellendiği yer
          // burada patladığında secili personel yerine sendeki personel objesini gondermeyi dene
          dispatch(
            personelSmsGonder(
              { ...secilenler },
              personelSms,
              secilipersonel,
              personeller,
              function (cevap) {
                sonuc(1);
              }
            )
          );
        }
      );
    } else {
      sonuc(1);
    }
  };
};

export const teslimtarihiguncelle = (
  isid,
  talepid,
  secilipersonel,
  secilipersonelTel,
  isinaciklamasi,
  uyeid,
  teslimtarihi,
  guncelle,
  sonuc
) => {
  return (dispatch) => {
    if (guncelle === 1) {
      getJson(
        `${sorguYolBaslangic}istakibi/sorgu`,
        `&sorguid=1104&id=${isid}&tamamlanmatarihi=${moment(
          teslimtarihi
        ).format("YYYY-MM-DD")}&string2=${moment(teslimtarihi).format(
          "L"
        )}&string3=${sessionStorage.getItem(
          "KullaniciId"
        )}&isiolusturan=${sessionStorage.getItem("KullaniciAd")}${
          sessionStorage.getItem("proid")
            ? `&proid=${sessionStorage.getItem("proid")}`
            : ""
        }`,
        function (dt) {
          if (talepid) {
            dispatch(
              postJson(
                `${sorguYolBaslangic}mesajlar/cevapla`,
                {
                  mesajid: talepid,
                  metin:
                    'TALEBİNİZİN TESLİM TARİHİ "' +
                    moment(teslimtarihi).format("L") +
                    '" OLARAK GÜNCELLENDİ' +
                    " (" +
                    sessionStorage.getItem("KullaniciAd") +
                    ")",
                  uyeid: uyeid,
                  mailbaslik: null,
                },
                0,
                function (data) {
                  dispatch(
                    bildirimGonder(
                      secilipersonel,
                      secilipersonelTel,
                      "İşin teslim tarihi " +
                        moment(teslimtarihi).format("L") +
                        " olarak güncellendi..",
                      isinaciklamasi,
                      function (ss) {
                        sonuc(1);
                      }
                    )
                  );
                }
              )
            );
          } else {
            dispatch(
              bildirimGonder(
                secilipersonel,
                secilipersonelTel,
                "İşin teslim tarihi " + teslimtarihi + " olarak güncellendi..",
                isinaciklamasi,
                function (ss) {
                  sonuc(1);
                }
              )
            );
          }
          // guncellendiği yer
          // self.simdikiteslimtarihi(self.teslimtarihi());
        }
      );
    } else {
      sonuc(1);
    }
  };
};

export const surecsill = function (isid, id, talepid) {
  return (dispatch) => {
    getJson(
      `${sorguYolBaslangic}istakibi/sorgu`,
      "&sorguid=1112&id=" +
        id +
        "&isiolusturan=" +
        sessionStorage.getItem("KullaniciAd") +
        `${
          sessionStorage.getItem("proid")
            ? `&proid=${sessionStorage.getItem("proid")}`
            : ""
        }`,
      function (dt) {
        dispatch(
          postJson(
            `${sorguYolBaslangic}mesajlar/cevapla`,
            {
              mesajid: talepid,
              metin: null,
              uyeid: null,
              mailbaslik: null,
              sil: id,
            },
            0,
            function (data) {
              dispatch(guncellenecekIsiGetir(isid));
            }
          )
        );
      }
    );
  };
};
