import React from "react";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";

import { kullaniciguncelle } from "../../redux/actions/isAction";
import { bilgilerimiGetir } from "../../redux/actions/isAction";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));
const Basarili = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: props.giris ? "center" : "left",
        }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        {props.giris ? (
          <>
            <Alert onClose={handleClose} severity="success">
              Sms Onayı Geçerli!
            </Alert>
          </>
        ) : (
          <>
            {props.hesabimReducer.error == null ? (
              <Alert onClose={handleClose} severity="success">
                Bilgileriniz başarıyla güncellendi!
              </Alert>
            ) : (
              <Alert onClose={handleClose} severity="error">
                Bilgileriniz güncellenirken bir hata oluştu!
              </Alert>
            )}
          </>
        )}
      </Snackbar>
    </div>
  );
};

const mapStateToProps = ({ userReducer, hesabimReducer }) => {
  return {
    userReducer,
    hesabimReducer,
  };
};
const mapDispatchToProps = {
  kullaniciguncelle,
  bilgilerimiGetir,
};
export default connect(mapStateToProps, mapDispatchToProps)(Basarili);
