import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { kullaniciguncelle } from "../../redux/actions/isAction";
import { bilgilerimiGetir } from "../../redux/actions/isAction";
import { RingLoader } from "react-spinners";
import Basarili from "../containers/Basarili";

const Account = (props) => {
  useEffect(() => {
    if (sessionStorage.getItem("token")) {
      props.bilgilerimiGetir();
    }
    if (sessionStorage.getItem("tokenKey")) {
      setYonetici(1);
    }
  }, []);

  const [yonetici, setYonetici] = useState(0);

  useEffect(() => {
    setAd(props.hesabimReducer.user.ad);
  }, [props.hesabimReducer.user.ad]);
  useEffect(() => {
    setEpost(props.hesabimReducer.user.eposta);
  }, [props.hesabimReducer.user.eposta]);

  const [tel, setTel] = useState(sessionStorage.getItem("userName"));

  const [isAd, setAd] = useState("");
  const [eposta, setEpost] = useState("");
  const [pass, setPass] = useState("");

  const onChangeAd = (e) => {
    setAd(e.target.value);
  };

  const onChangeEposta = (e) => {
    setEpost(e.target.value);
  };

  const onChangePass = (e) => {
    setPass(e.target.value);
  };

  const formSubmit = (e) => {
    props.kullaniciguncelle(tel, isAd, eposta, pass);
  };

  return (
    <div>
      {props.hesabimReducer.bilgiGoster ? <Basarili></Basarili> : null}
      {yonetici === 0 ? (
        <div>
          {props.hesabimReducer.fetching ? (
            <div className="load">
              <RingLoader
                size={100}
                color={"#648541"}
                loading={true}
              ></RingLoader>
            </div>
          ) : null}
          <div className="accountWrapper">
            <form>
              <label>Ad Soyad</label>
              <input
                onChange={onChangeAd}
                type="text"
                defaultValue={props.hesabimReducer.user.ad}
              ></input>
              <label>E-Mail</label>
              <input
                onChange={onChangeEposta}
                defaultValue={props.hesabimReducer.user.eposta}
                type="email"
              ></input>
              {/* telefon disabled olacak */}
              <label>Telefon</label>
              <input type="text" defaultValue={tel} readOnly></input>
              {/* telefon disabled olacak */}
              <label>Parola</label>
              <input onChange={onChangePass} type="password"></input>
            </form>
            <button className="btn btn-onay" onClick={formSubmit} type="submit">
              Bilgilerimi Güncelle
            </button>
          </div>
        </div>
      ) : (
        <div>
          <p>Yönetici Görevli Tanımlama Ekranı</p>
        </div>
      )}
    </div>
  );
};
const mapStateToProps = ({ userReducer, hesabimReducer }) => {
  return {
    userReducer,
    hesabimReducer,
  };
};
const mapDispatchToProps = {
  kullaniciguncelle,
  bilgilerimiGetir,
};
export default connect(mapStateToProps, mapDispatchToProps)(Account);
