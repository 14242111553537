import axios from "axios";
export const FETCH_USER_PENDING = "FETCH_USER_PENDING";
export const FETCH_USER_FULFILLED = "FETCH_USER_FULFILLED";
export const FETCH_USER_REJECTED = "FETCH_USER_REJECTED";

export const girisYapBekle = () => {
  return {
    type: FETCH_USER_PENDING,
    fetching: true,
  };
};
export const girisYapCalis = (token, telNo) => {
  return {
    type: FETCH_USER_FULFILLED,
    fetching: false,
    telNo: telNo,
    payload: token,
  };
};
export const girisYapHata = (error) => {
  return {
    type: FETCH_USER_REJECTED,
    fetching: false,
    payload: error,
  };
};

export const girisYap = (telNo, pass, history) => {
  return function (dispatch) {
    dispatch(girisYapBekle());
    axios
      .post(
        `/token`,
        `grant_type=password&username=${telNo}&password=${pass}`,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((result) => {
        sessionStorage.setItem("token", result.data.access_token);

        sessionStorage.setItem("userName", result.data.userName);

        dispatch(girisYapCalis(result.data.access_token, result.data.userName));

        window.location.href = "/index.html";
      })
      .catch((error) => {
        dispatch(girisYapHata(error.message));
        history.push("/index.html");
      });
  };
};
