import {
  MESAJ_CEVAPLA_PENDING,
  MESAJ_CEVAPLA_FULFILLED,
  MESAJ_CEVAPLA_REJECTED
} from "../actions/isAction";

const initialState = {
  data: {},
  metin: [],
  fetching: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MESAJ_CEVAPLA_PENDING:
      return { ...state, fetching: true };

    case MESAJ_CEVAPLA_FULFILLED:
      return {
        ...state,
        fetching: false,
        metin: action.payload,
        data: action.payload
      };
    case MESAJ_CEVAPLA_REJECTED:
      return { error: action.payload, fetching: false, ...state };

    default:
      return state;
  }
};
