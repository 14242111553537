import {
  METIN_IS_PENDING,
  METIN_IS_FULFILLED,
  METIN_IS_REJECTED
} from "../actions/isAction";

const initialState = {
  data: {},
  fetching: false,
  fetched: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case METIN_IS_PENDING:
      return { ...state, fetching: true, fetched: false };

    case METIN_IS_FULFILLED:
      return {
        ...state,
        data: action.payload,
        fetching: false,
        fetched: true
      };
    case METIN_IS_REJECTED:
      return {
        error: action.payload,
        fetching: false,
        fetched: false,
        ...state
      };

    default:
      return state;
  }
};
