import React, { useEffect, useState } from "react";
import bekleyenIconYolu from "../../helpers/images/time-leftmavi.png";
import devamEdenIconYolu from "../../helpers/images/islemdekiler.png";
import Moment from "react-moment";
import { connect } from "react-redux";
import { guncellenecekIsiGetir } from "../../redux/actions/isGuncelleActions";
import DialogScreen from "../pages/DialogScreen";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { Switch, Route, NavLink } from "react-router-dom";

const KanbanItem = (props) => {
  const [yonetici, setYonetici] = useState(false);
  const [modal, setModal] = useState(false);
  const [uyari, setUyari] = useState(false);

  const [windowsize, setWindowSize] = useState({ width: undefined });
  const [genislet, setGenislet] = useState(true);
  useEffect(() => {
    if (
      sessionStorage.getItem("tokenKey") &&
      !sessionStorage.getItem("token")
    ) {
      setYonetici(true);
    }
    if (
      sessionStorage.getItem("token") &&
      !sessionStorage.getItem("tokenKey")
    ) {
      setYonetici(false);
    }
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
      });
      if (windowsize.width < 1200) {
        setGenislet(false);
      } else {
        setGenislet(true);
      }
    }

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, [windowsize.width]);

  const openModal = (e) => {
    if (sessionStorage.getItem("tokenKey")) {
      setModal(!modal);
      setUyari(true);
    }
  };

  return (
    <div className="kanbanItemWrapper">
      {uyari ? (
        <DialogScreen
          open={openModal}
          p={props.p}
          yonetici={true}
          dialog={uyari}
          isid={props.id}
          islem={"İşi"}
          olay={"güncellemek"}
          dialogKapat={() => setUyari(false)}
        ></DialogScreen>
      ) : null}
      {!yonetici ? (
        <aside>
          <NavLink
            to={!yonetici ? `bekleyendetay/${props.id}` : ``}
            className="kanbanItemClick"
          >
            <aside
              className={`${
                props.mesajokundu === 0 || props.yenimesaj !== 0
                  ? "msjokunmadi"
                  : "msjokndu"
              } kanbanItem`}
              onClick={openModal}
            >
              <aside className="itemLeft">
                {props.durum === 2 ? (
                  <img src={devamEdenIconYolu} alt="devam eden iş"></img>
                ) : null}
                {props.durum === 1 ? (
                  <img src={bekleyenIconYolu} alt="bekleyen iş"></img>
                ) : null}
              </aside>
              <aside className="itemTop">
                {props.ad !== null ? <h4>{props.ad}</h4> : <h4> </h4>}
                <p className="itemaciklama">{props.aciklama.replace(/<[^>]*>?/gm, ' ')}</p>

                <aside className="itemMiddle">
                  <aside className="itemBottomInfo">
                    <b className="itemtarih">
                      <span>Kayıt Tarihi: </span>{" "}
                      {
                        <Moment format="DD/MMM/YYYY">
                          {props.olusturmatarihi}
                        </Moment>
                      }
                    </b>
                    {props.teslimtarihi !== null ? (
                      <p className="itemtarih">
                        <span className="brolcak">Teslim Tarihi: </span>
                        <b>
                          {
                            <Moment format="DD/MMM/YYYY">
                              {props.teslimtarihi}
                            </Moment>
                          }
                        </b>
                      </p>
                    ) : null}
                  </aside>
                </aside>
              </aside>
              {props.yenimesaj !== 0 ? (
                <span className="yeniIsGeldi">
                  <b>{props.yenimesaj}</b>
                </span>
              ) : null}
            </aside>
          </NavLink>
        </aside>
      ) : (
        <aside>
          <div className="kanbanItemClick">
            <aside
              className={
                `${props.mesajokundu === 0 ? "msjokunmadi" : "msjokndu"}` +
                " kanbanItem"
              }
            >
              <aside className="itemLeft">
                {props.durum === 2 ? (
                  <img src={devamEdenIconYolu} alt="devam eden iş"></img>
                ) : null}
                {props.durum === 1 ? (
                  <img src={bekleyenIconYolu} alt="bekleyen iş"></img>
                ) : null}
              </aside>

              {genislet ? (
                <ExpandLessIcon
                  onClick={() => {
                    setGenislet(!genislet);
                  }}
                  className="expandicon"
                ></ExpandLessIcon>
              ) : (
                <ExpandMoreIcon
                  onClick={() => {
                    setGenislet(!genislet);
                  }}
                  className="expandicon"
                ></ExpandMoreIcon>
              )}
              <aside onClick={openModal} className="itemTop">
                {props.ad !== null ? <h4>{props.ad}</h4> : <h4> </h4>}

                <p className="itemaciklama">{props.aciklama.replace(/<[^>]*>?/gm, ' ')}</p>
                {genislet ? (
                  <aside className="itemMiddle">
                    <aside className="itemBottomInfo">
                      <b className="itemtarih">
                        <span>Kayıt Tarihi:</span>
                        {
                          <Moment format="DD/MMM/YYYY">
                            {props.olusturmatarihi}
                          </Moment>
                        }
                      </b>
                      <b className="itemtarih">
                        <span>İş Oluşturan:</span>
                        {props.isiolusturan && props.isiolusturan
                          ? props.isiolusturan
                          : "---"}
                      </b>
                      <b className="itemtarih">
                        <span>İş Türü:</span>
                        {props.isinTuru[0] && props.isinTuru[0].tanim
                          ? props.isinTuru[0].tanim
                          : "---"}
                      </b>
                      <b className="itemtarih">
                        <span>Personel:</span>
                        {props.personel[0] && props.personel[0].ad
                          ? props.personel[0].ad
                          : "---"}
                      </b>
                      <b className="itemtarih">
                        <span>İş Alanı:</span>
                        {props.gorevAdi && props.gorevAdi[0]
                          ? props.gorevAdi[0].tanim
                          : "---"}
                      </b>
                      <b className="itemtarih">
                        <span>Site Sakini:</span>

                        {props.siteSakin && props.siteSakin
                          ? props.siteSakin
                          : "---"}
                      </b>
                      {props.teslimtarihi !== null ? (
                        <p className="itemtarih">
                          <span className="brolcak"> Teslim Tarihi:</span>
                          <b>
                            {
                              <Moment format="DD/MMM/YYYY">
                                {props.teslimtarihi}
                              </Moment>
                            }
                          </b>
                        </p>
                      ) : null}
                    </aside>
                  </aside>
                ) : null}
              </aside>
              {props.yenimesaj !== 0 ? (
                <span className="yeniIsGeldi">
                  <span>
                    <b>{props.yenimesaj}</b>
                  </span>
                </span>
              ) : null}
            </aside>
          </div>
        </aside>
      )}
    </div>
  );
};

const mapStateToProps = ({ isGuncelleReducer }) => {
  return {
    isGuncelleReducer,
  };
};
const mapDispatchToProps = {
  guncellenecekIsiGetir,
};

export default connect(mapStateToProps, mapDispatchToProps)(KanbanItem);
