import React from "react";

import Header from "../containers/Header";
import SectionDashboard from "../containers/SectionDashboard";
import LoginPage from "./LoginPage";
import { FETCH_SAYILAR_FULFILLED } from "../../redux/actions/bekleyenAction";

const DashboardPage = (props) => {

  return (
    <div>
      {sessionStorage.getItem("token") || sessionStorage.getItem("tokenKey") ? (
        <div>
          <Header></Header>
          <SectionDashboard></SectionDashboard>
        </div>
      ) : (
        <LoginPage></LoginPage>
      )}
    </div>
  );
};
export default DashboardPage;
