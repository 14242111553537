import {
  FETCH_IS_PENDING,
  FETCH_IS_FULFILLED,
  FETCH_IS_REJECTED
} from "../actions/isAction";

const initialState = {
  data: [],
  fetching: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_IS_PENDING:
      return { ...state, fetching: true };

    case FETCH_IS_FULFILLED:
      return {
        ...state,
        fetching: false,

        data: action.payload
      };
    case FETCH_IS_REJECTED:
      return { error: action.payload, fetching: false, ...state };

    default:
      return state;
  }
};
