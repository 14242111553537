import {
  FETCH_KAPAT_PENDING,
  FETCH_KAPAT_FULFILLED,
  FETCH_KAPAT_REJECTED,
} from "../actions/kapatilanActions";

const initialState = {
  Table: [],
  yoneticiKapatilanlar: [],
  fetching: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_KAPAT_PENDING:
      return { ...state, fetching: true };

    case FETCH_KAPAT_FULFILLED:
      return {
        ...state,
        fetching: false,
        yoneticiKapatilanlar: action.payload,
        Table: action.payload.Table,
      };
    case FETCH_KAPAT_REJECTED:
      return { error: action.payload, fetching: false, ...state };

    default:
      return state;
  }
};
