import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { RingLoader } from "react-spinners";
import SiteleriGetir from "../pages/SiteleriGetir";
import DialogFormScreen from "./DialogFormScreen";
import { girisYap } from "../../redux/actions/userAction";

import loginlogo from "../../helpers/images/istakibison2.png";
import LoginPageForm from "../pages/LoginPageForm";
import androidicon from "../../helpers/images/istakibiandroid.svg";
import iosicon from "../../helpers/images/istakibiios.svg";
import { getCookie, yoneticiSorgula } from "../../redux/actions/yoneticiAction";
import InputMask from "react-input-mask";

const LoginPage = (props) => {
  const [yonetici, setYonetici] = useState(0);
  const [buttonGizle, setButtonGizle] = useState(0);
  const [uyari, setUyari] = useState(false);
  const [bilgi, setBilgi] = useState(false);
  const [secildi, setSecildi] = useState(0);
  const [kulAdi, setKulAdi] = useState("");
  const [parola, setParola] = useState("");
  const [yonTel, setYonTel] = useState(
    getCookie("YCTelefon") ? getCookie("YCTelefon") : ""
  );
  const onChangeKulAdi = (event) => {
    setKulAdi(event.target.value);
  };

  const onChangeYoneticiTel = (event) => {
    setYonTel(event.target.value);
  };

  const onChangePass = (event) => {
    setParola(event.target.value);
  };

  const onSubmitLoginForm = (event) => {
    if (kulAdi !== "" && parola !== "") {
      //    props.onLoginUser(user.telNo, user.pass, props.history); // - sorgu atıldı
      props.girisYap(
        kulAdi
          .replace("(", "")
          .replace(")", "")
          .replace(" ", "")
          .replace("-", "")
          .replace("-", ""),
        parola,
        props.history
      );
    }
  };

  const submitYoneticiForm = (event) => {
    if (yonTel !== "") {
      // giris fonksiyonu burada çalışacak
      props.yoneticiSorgula(
        yonTel
          .replace("(", "")
          .replace(")", "")
          .replace(" ", "")
          .replace("-", "")
          .replace("-", ""),
        setButtonGizle
      );
    }
  };

  const parolaUnuttum = (event) => {
    setUyari(true);
  };
  const yoneticiyeGec = (event) => {
    event.preventDefault();
    setYonetici(1);
  };

  const gorevliyeGec = (event) => {
    event.preventDefault();
    setYonetici(0);
  };
  return (
    <div className="overflowcancel">
      <main className="istakipwrapper">
        <section className="loginsection">
          <div className="loginwrapper desktop">
            <aside className="loginleftside">
              <form className="loginForm">
                <figure className="logowrapper">
                  <img src={loginlogo} alt="logo" className="loginlogo" />
                  <figcaption>
                    <p
                      style={{
                        fontFamily: "Roboto",
                        fontWeight: "100",
                        fontSize: "18px",
                      }}
                    >
                      Yönetimcell Site Yönetim Programı İş Takip Uygulaması
                      <br />
                    </p>
                  </figcaption>
                </figure>
                <div>
                  <aside className="secim">
                    <button
                      className={`btn-ikili solbtn ${
                        yonetici === 0 ? "solactif" : ""
                      }`}
                      onClick={gorevliyeGec}
                    >
                      <div
                        className={
                          yonetici == 0
                            ? "radiusGirisCheckActiveWrap radiusGirisCheck"
                            : "radiusGirisCheck"
                        }
                      >
                        <div
                          className={`${
                            yonetici == 0 ? "radiusGirisCheckActive" : ""
                          }`}
                        ></div>
                      </div>
                      Görevli/Personel Girişi
                    </button>
                    <button
                      className={`btn-ikili sagbtn ${
                        yonetici === 1 ? "sagactif" : ""
                      }`}
                      onClick={yoneticiyeGec}
                    >
                      <div
                        className={
                          yonetici == 1
                            ? "radiusGirisCheckActiveWrap radiusGirisCheck"
                            : "radiusGirisCheck"
                        }
                      >
                        <div
                          className={`${
                            yonetici == 1 ? "radiusGirisCheckActive" : ""
                          }`}
                        ></div>
                      </div>
                      Yönetici Girişi
                    </button>
                  </aside>
                </div>
                <div>
                  <div className="inps tek mrgnsz">
                    <div className="pst">
                      {yonetici === 0 ? (
                        <>
                          <i className="fa fa-phone" aria-hidden="true"></i>

                          <InputMask
                            mask="(999) 999-99-99"
                            name="telNo"
                            placeholder="Telefon Numarası"
                            id="telNo"
                            type="text"
                            value={kulAdi}
                            onChange={onChangeKulAdi}
                          ></InputMask>
                        </>
                      ) : props.yoneticiReducer.yonetici.length > 0 ? null : (
                        <>
                          <i className="fa fa-phone" aria-hidden="true"></i>
                          <InputMask
                            mask="(999) 999-99-99"
                            name="telNo"
                            id="telNo"
                            placeholder="Telefon Numarası"
                            type="text"
                            value={yonTel}
                            onChange={onChangeYoneticiTel}
                          ></InputMask>
                        </>
                      )}
                    </div>
                  </div>
                  <div>
                    {yonetici === 0 ? (
                      <div className="inps tek">
                        <div className="pst">
                          <i className="fa fa-lock" aria-hidden="true"></i>
                          <input
                            onChange={onChangePass}
                            placeholder="Şifre"
                            id="pass"
                            name="pass"
                            type="password"
                          ></input>
                        </div>
                      </div>
                    ) : null}
                    {props.yoneticiReducer.error ? (
                      <p className="errorYazi">{props.yoneticiReducer.error}</p>
                    ) : null}
                    {props.yoneticiReducer.done ? (
                      <SiteleriGetir
                        setButtonGizle={setButtonGizle}
                        yonetici={yonetici}
                      ></SiteleriGetir>
                    ) : null}
                  </div>
                  {yonetici === 0 ? (
                    <aside className="passwordremember">
                      <button
                        type="button"
                        className="parolamiUnuttumBtn"
                        onClick={parolaUnuttum}
                      >
                        Şifremi Unuttum
                      </button>
                      {uyari === true ? (
                        <DialogFormScreen
                          dialog={uyari}
                          dialogKapat={() => setUyari(false)}
                        ></DialogFormScreen>
                      ) : null}
                    </aside>
                  ) : (
                    <aside className="passwordremember">
                      <button
                        type="button"
                        className="parolamiUnuttumBtn sifremUnuttumBilgi"
                      >
                        Şifrenizi unuttuysanız (0282 606 01 90) destek
                        isteyiniz..
                      </button>
                    </aside>
                  )}
                  <div>
                    {yonetici === 0 ? (
                      <div
                        style={{ marginBottom: "20px" }}
                        className="loginokbtn inps tek"
                      >
                        <button type="button" onClick={onSubmitLoginForm}>
                          Giriş Yap
                        </button>
                        {props.userReducer.error !== null ? (
                          <p className="errorYazi">
                            Telefon No veya Şifre Hatalı!
                          </p>
                        ) : null}
                        <aside className="mobileIconlar">
                          <a>
                            <img
                              className="iosicon"
                              src={iosicon}
                              alt="iosicon"
                            ></img>
                          </a>
                          <a
                            href="https://play.google.com/store/apps/details?id=com.yonetimcell.istakibi"
                            target="_blank"
                          >
                            <img src={androidicon} alt="androidicon"></img>
                          </a>
                        </aside>
                      </div>
                    ) : (
                      <div className="loginokbtn inps tek">
                        {buttonGizle === 0 ? (
                          <button type="button" onClick={submitYoneticiForm}>
                            Giriş Yap
                          </button>
                        ) : buttonGizle === 1 ? (
                          <button
                            style={{ display: "none" }}
                            type="button"
                            onClick={submitYoneticiForm}
                          >
                            Giriş Yap
                          </button>
                        ) : buttonGizle === 2 ? (
                          <button type="button" onClick={submitYoneticiForm}>
                            Tekrar Dene
                          </button>
                        ) : null}
                        <aside
                          style={buttonGizle == 1 ? { display: "none" } : {}}
                          className="mobileIconlar"
                        >
                          <a>
                            <img
                              className="iosicon"
                              src={iosicon}
                              alt="iosicon"
                            ></img>
                          </a>
                          <a
                            href="https://play.google.com/store/apps/details?id=com.yonetimcell.istakibi"
                            target="_blank"
                          >
                            <img src={androidicon} alt="androidicon"></img>
                          </a>
                        </aside>
                      </div>
                    )}
                  </div>
                </div>
              </form>
              <p className="infoYc">
                [ Yönetimcell İş Takibi Uygulaması © 2019 YONETIMCELL ] <br />
                Güvenlik: SSL 256 Bit Şifreleme / Yönetimcell Bilgi
                Teknolojileri Tic. Ltd. Şti. <br /> İletişim / Destek: 0.282 606
                01 90
              </p>
            </aside>
            <LoginPageForm yonetici={yonetici}></LoginPageForm>
          </div>
        </section>
      </main>
    </div>
  );
};
const mapStateToProps = ({ userReducer, yoneticiReducer }) => {
  return {
    userReducer,
    yoneticiReducer,
  };
};

const mapDispatchToProps = {
  //onLoginUser,
  girisYap,
  yoneticiSorgula,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LoginPage));
