import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { RingLoader } from "react-spinners";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { connect } from "react-redux";
import InputMask from "react-input-mask";
import { parolaUnuttum } from "../../redux/actions/isAction";

const DialogFormScreen = (props) => {
  const [open, setOpen] = useState(props.dialog);
  const [telNo, setTelNo] = useState(null);

  const smsGonderOnay = () => {
    if (telNo !== null) {
      props.parolaUnuttum(
        telNo
          .replace("(", "")
          .replace(")", "")
          .replace(" ", "")
          .replace("-", "")
          .replace("-", "")
      );
    } else {
      setTelNo(null);
    }
  };

  const handleClose = () => {
    setOpen(props.dialogKapat);
  };

  const onChange = (e) => {
    setTelNo(e.target.value);
  };

  return (
    <div className="max-heg">
      {/* {props.isReducer.fetching ? (
        <div className="load">
          <RingLoader size={100} color={"#D21113"} loading={true}></RingLoader>
        </div>
      ) : null} */}
      <Dialog
        style={{ zIndex: "9999999999", maxHeight: "50%", top: "22%" }}
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <div className="max-heg">
          <DialogContent className="inps inpTekli">
            <DialogContentText>
              Lütfen telefon numaranızı giriniz.
            </DialogContentText>
            <InputMask
              className="tekInp"
              autoFocus
              mask="(999) 999-99-99"
              name="telNo"
              id="telNo"
              type="text"
              onChange={onChange}
            ></InputMask>
            {props.parolaReducer.error === false ? (
              ""
            ) : (
              <p className="hataMesaj">Kullanıcı Bulunamadı</p>
            )}
            {props.parolaReducer.data !== null ? (
              <p id="onayMesaj" className="onayMesaj">
                Sms Gönderimi Başarılı!
              </p>
            ) : null}
          </DialogContent>
        </div>
        <div>
          <DialogActions>
            <Button onClick={smsGonderOnay} className="btn-onayAlert">
              Gönder
            </Button>
            <Button onClick={handleClose} className="btn-redAlert">
              İptal
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
};
const mapStateToProps = ({ parolaReducer }) => {
  return {
    parolaReducer,
  };
};
const mapDispatchToProps = {
  parolaUnuttum,
};

export default connect(mapStateToProps, mapDispatchToProps)(DialogFormScreen);
