import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { isGuncels } from "../../redux/actions/isGuncelleActions";

const HataMesaji = (props) => {
  const [open, setOpen] = useState(props.dialog);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClose = () => {
    setOpen(props.onClose);
  };
  const durumDegis = () => {
    props.isGuncels(
      props.isid,
      props.isBilgileri,
      props.personeller,
      props.personelSms,
      props.metin
    );
    setOpen(props.onClose);
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        className="kucukDialog"
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {props.neMesaji === "bilgilendirme" ? (
            <aside className="warnIcon">
              <p>{"Bilgilendirme!"}</p>
              <i className="fa fa-exclamation-triangle" aria-hidden="true"></i>
            </aside>
          ) : props.neMesaji === "hata" ? (
            <aside className="errIcon">
              <p>{"Hata Mesajı!"}</p>
              <i className="fa fa-exclamation-triangle" aria-hidden="true"></i>
            </aside>
          ) : null}
        </DialogTitle>
        {props.hata ? (
          <DialogContent>
            <DialogContentText className="htaMsjIcerik">
              {props.hata}
            </DialogContentText>
          </DialogContent>
        ) : null}
        <DialogActions>
          <Button
            autoFocus
            className="btn-onayAlert bilgiMesaji"
            onClick={durumDegis}
          >
            Tamam
          </Button>
          <Button
            autoFocus
            className="btn-redAlert bilgiMesaji"
            onClick={handleClose}
          >
            İptal
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
const mapStateToProps = ({ loadingReducer }) => {
  return {
    loadingReducer,
  };
};

const mapDispatchToProps = {
  isGuncels,
};

export default connect(mapStateToProps, mapDispatchToProps)(HataMesaji);
