import React from "react";

const About = props => {
  return (
    <div className="aboutWrapper">
      <p className="hakkimizda">
        Bu uygulama Yönetimcell Bilgi Teknolojileri Tic. Ltd. Şti. tarafından,
        Yönetimcell Site Yönetim Programı kullanan profesyonel yönetim
        şirketleri ve site-apartman yönetimlerinin, iş takip süreçlerini
        yönetebilmeleri amacıyla, sisteme tanımladıkları görevli personel
        tarafından kullanılmak üzere geliştirilmiştir.<br></br> <br></br> Bu uygulama üzerinden
        giriş yapan görevli, kendisine tanımlanan işleri görebilir,
        güncelleyebilir ve durumunu değiştirerek sonuçlandırabilir.<br></br> <br></br> Bu uygulama
        üzerinden gönderilen mesajlar ve içerik, ilgili yönetici ve varsa site
        sakini tarafından görülmektedir.
      </p>
      <aside className="sirketBilgileri">
      <p>Yönetimcell Bilgi Teknolojileri Tic.Ltd.Şti</p>
      <p>Telefon: 0.282 606 01 90</p>
      <p>E-Posta: bilgi@yonetimcell.com.tr</p>
      </aside>
    </div>
  );
};

export default About;
