import axios from "axios";
import sayilariGoster from "./bekleyenAction";

export const FETCH_IS_PENDING = "FETCH_IS_PENDING";
export const FETCH_IS_FULFILLED = "FETCH_IS_FULFILLED";
export const FETCH_IS_REJECTED = "FETCH_IS_REJECTED";

export const REDDET_IS_PENDING = "REDDET_IS_PENDING";
export const REDDET_IS_FULFILLED = "REDDET_IS_FULFILLED";
export const REDDET_IS_REJECTED = "REDDET_IS_REJECTED";

export const KABUL_IS_PENDING = "KABUL_IS_PENDING";
export const KABUL_IS_FULFILLED = "KABUL_IS_FULFILLED";
export const KABUL_IS_REJECTED = "KABUL_IS_REJECTED";

export const TAMAMLANDI_IS_PENDING = "TAMAMLANDI_IS_PENDING";
export const TAMAMLANDI_IS_FULFILLED = "TAMAMLANDI_IS_FULFILLED";
export const TAMAMLANDI_IS_REJECTED = "TAMAMLANDI_IS_REJECTED";

export const METIN_IS_FULFILLED = "METIN_IS_FULFILLED";
export const METIN_IS_PENDING = "METIN_IS_PENDING";
export const METIN_IS_REJECTED = "METIN_IS_REJECTED";

export const DOSYA_IS_FULFILLED = "DOSYA_IS_FULFILLED";
export const DOSYA_IS_PENDING = "DOSYA_IS_PENDING";
export const DOSYA_IS_REJECTED = "DOSYA_IS_REJECTED";

export const MESAJ_SIL_PENDING = "MESAJ_SIL_PENDING";
export const MESAJ_SIL_FULFILLED = "MESAJ_SIL_FULFILLED";
export const MESAJ_SIL_REJECTED = "MESAJ_SIL_REJECTED";

export const CHECK_DOSYA_FULFILLED = "CHECK_DOSYA_FULFILLED";
export const CHECK_DOSYA_PENDING = "CHECK_DOSYA_PENDING";
export const CHECK_DOSYA_REJECTED = "CHECK_DOSYA_REJECTED";

export const GERI_IS_PENDING = "GERI_IS_PENDING";
export const GERI_IS_FULFILLED = "GERI_IS_FULFILLED";
export const GERI_IS_REJECTED = "GERI_IS_REJECTED";

export const MESAJ_CEVAPLA_PENDING = "MESAJ_CEVAPLA_PENDING";
export const MESAJ_CEVAPLA_FULFILLED = "MESAJ_CEVAPLA_FULFILLED";
export const MESAJ_CEVAPLA_REJECTED = "MESAJ_CEVAPLA_REJECTED";

export const FETCH_BILGI_PENDING = "FETCH_BILGI_PENDING";
export const FETCH_BILGI_FULFILLED = "FETCH_BILGI_FULFILLED";
export const FETCH_BILGI_REJECTED = "FETCH_BILGI_REJECTED";

export const FETCH_SMS_PENDING = "FETCH_SMS_PENDING";
export const FETCH_SMS_FULFILLED = "FETCH_SMS_FULFILLED";
export const FETCH_SMS_REJECTED = "FETCH_SMS_REJECTED";

var currentToken = sessionStorage.getItem("token");

export const isiCagir = () => {
  return {
    type: FETCH_IS_PENDING,
    okmu: false,
    fetching: false,
  };
};

export const isGelsin = (is) => {
  return {
    type: FETCH_IS_FULFILLED,
    okmu: true,
    fetching: true,
    payload: is,
  };
};
export const isHata = (error) => {
  return {
    type: FETCH_IS_REJECTED,
    okmu: false,
    fetching: false,
    payload: error,
  };
};

export const isKabulCagir = (is) => {
  return {
    type: KABUL_IS_PENDING,
    fetching: false,
  };
};

export const isKabul = (is) => {
  return {
    type: KABUL_IS_FULFILLED,
    fetching: true,
    payload: is,
  };
};
export const isKabulHata = (error) => {
  return {
    type: KABUL_IS_REJECTED,
    fetching: false,
    payload: error,
  };
};

export const isRedBekle = (is) => {
  return {
    type: REDDET_IS_PENDING,
    fetching: false,
  };
};

export const isRedOl = (is) => {
  return {
    type: REDDET_IS_FULFILLED,
    fetching: true,
    payload: is,
  };
};

export const isRedHata = (error) => {
  return {
    type: REDDET_IS_REJECTED,
    fetching: false,
    payload: error,
  };
};

export const isTamamlaniyor = () => {
  return {
    type: TAMAMLANDI_IS_PENDING,
    fetching: false,
  };
};

export const isTamam = (is) => {
  return {
    type: TAMAMLANDI_IS_FULFILLED,
    fetching: true,
    payload: is,
  };
};
export const isTamamHata = (error) => {
  return {
    type: TAMAMLANDI_IS_REJECTED,
    fetching: false,
    payload: error,
  };
};
export const metinGonderBekle = () => {
  return {
    type: METIN_IS_PENDING,
    fetching: true,
  };
};

export const metinGonder = (metin) => {
  return {
    type: METIN_IS_FULFILLED,
    fetching: false,
    fetched: true,
    payload: metin,
  };
};
export const metinGonderHata = (error) => {
  return {
    type: METIN_IS_REJECTED,
    fetching: false,
    payload: error,
  };
};
export const silMesajBekle = () => {
  return {
    type: MESAJ_SIL_PENDING,
    fetching: false,
  };
};
export const silMesaj = (mesaj) => {
  return {
    type: MESAJ_SIL_FULFILLED,
    fetching: true,
    payload: mesaj,
  };
};

export const silMesajHata = (error) => {
  return {
    type: MESAJ_SIL_REJECTED,
    fetching: false,
    payload: error,
  };
};

export const dosyaGonderBekle = (dosya) => {
  return {
    type: DOSYA_IS_PENDING,
    fetching: false,
  };
};

export const dosyaGonder = (dosya) => {
  return {
    type: DOSYA_IS_FULFILLED,
    fetching: true,
    payload: dosya,
  };
};

export const dosyaGonderHata = (error) => {
  return {
    type: DOSYA_IS_REJECTED,
    fetching: false,
    payload: error,
  };
};
export const dosyaCheckBekle = (dosya) => {
  return { type: CHECK_DOSYA_PENDING, fetching: true, okmu: false };
};

export const dosyaCheck = (dosya) => {
  return {
    type: CHECK_DOSYA_FULFILLED,
    fetching: false,
    okmu: false,
    payload: dosya,
  };
};

export const dosyaCheckHata = (error) => {
  return {
    type: CHECK_DOSYA_REJECTED,
    fetching: false,
    okmu: false,
    payload: error,
  };
};

export const isiGeriGetirBekle = () => {
  return {
    type: GERI_IS_PENDING,
    fetching: false,
  };
};

export const isiGeriGetir = (is) => {
  return {
    type: GERI_IS_FULFILLED,
    fetching: true,
    payload: is,
  };
};

export const isiGeriGetirHata = (error) => {
  return {
    type: GERI_IS_REJECTED,
    fetching: false,
    payload: error,
  };
};
export const mesajCevaplaBekle = () => {
  return {
    type: MESAJ_CEVAPLA_PENDING,
    fetching: true,
  };
};
export const mesajCevapla = (model) => {
  return {
    type: MESAJ_CEVAPLA_FULFILLED,
    fetching: false,
    payload: model,
  };
};
export const mesajCevaplaHata = (error) => {
  return {
    type: MESAJ_CEVAPLA_REJECTED,
    fetching: false,
    payload: error,
  };
};
export const bilgilerGeliyor = () => {
  return {
    type: FETCH_BILGI_PENDING,
    fetching: true,
  };
};
export const bilgilerGeldi = (data) => {
  return {
    type: FETCH_BILGI_FULFILLED,
    fetching: false,
    payload: data,
  };
};
export const bilgilerGelmediHata = (error) => {
  return {
    type: FETCH_BILGI_REJECTED,
    fetching: false,
    payload: error,
  };
};

export const smsgonderildi = (data) => {
  return {
    type: FETCH_SMS_FULFILLED,
    fetching: false,
    payload: data,
  };
};
export const smsGonderilmediHata = (error) => {
  return {
    type: FETCH_SMS_REJECTED,
    fetching: false,
    payload: error,
  };
};
export const smsGonderiliyor = () => {
  return {
    type: FETCH_SMS_PENDING,
    fetching: true,
  };
};

export const isiGetir = (id) => {
  if (parseInt(id)) {
    return function (dispatch) {
      dispatch(isiCagir());
      axios
        .get(`/yc/sorgu?sorguid=1000&id=${id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + currentToken,
          },
        })
        .then((response) => {
          const isler = response.data;

          dispatch(isGelsin(isler));
        });
    };
  }
};

export const isiKabulEt = (kabulId, history) => {
  return function (dispatch) {
    dispatch(isKabulCagir());
    axios
      .get(`/yc/sorgu?sorguid=1010&id=${kabulId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + currentToken,
        },
      })
      .then((response) => {
        const isKabulOl = response.data;
        dispatch(isKabul(isKabulOl));
        history.push(`/islemgorendetay/${kabulId}`);
      });
  };
};

export const isiTamamla = (tamamId, talepid, uyeid, siteid, ad) => {
  return function (dispatch) {
    axios
      .get(`/yc/sorgu?sorguid=1012&id=${tamamId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + currentToken,
        },
      })
      .then((response) => {
        if (talepid) {
          dispatch(
            mesajcevapla(
              response.data.Table[0].id,
              "TALEBİNİZİN DURUMU TAMAMLANDI OLARAK GÜNCELLENDİ",
              talepid,
              uyeid,
              siteid,
              tamamId,
              null,
              null,
              ad
            )
          );
        }
        dispatch(isiGetir(tamamId));
      });
  };
};

export const icerikgonder = (
  sorgu,
  icerik,
  talepid,
  uyeid,
  siteid,
  isid,
  ad
) => {
  return function (dispatch) {
    axios
      .get("/yc/sorgu?" + sorgu, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + currentToken,
        },
      })
      .then((result) => {
        if (talepid) {
          dispatch(
            mesajcevapla(
              result.data.Table[0].id,
              icerik,
              talepid,
              uyeid,
              siteid,
              isid,
              null,
              null,
              ad
            )
          );
        }
        dispatch(isiGetir(isid));
      })
      .catch((error) => {});
  };
};

export const mesajcevapla = (
  id,
  metin,
  talepid,
  uyeid,
  siteid,
  isid,
  dosyaYolu,
  sil,
  ad
) => {
  return function (dispatch) {
    try {
      axios
        .post(
          `/mesajlar/cevapla`,
          {
            siteid: siteid,
            mesajid: talepid,
            metin: metin,
            uyeid: uyeid,
            issurecid: id,
            dosya: dosyaYolu,
            sil: sil,
            ad: ad,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + currentToken,
            },
          }
        )
        .then((result) => {
          dispatch(isiGetir(isid));
        })
        .catch((error) => {});
    } catch (e) {
      console.log(e);
    }
  };
};
export const dosyaCek = (
  id,
  dosyaYolu,
  dosyaAciklamasi,
  talepid,
  uyeid,
  siteid,
  ad
) => {
  return function (dispatch) {
    try {
      axios
        .get(
          `/yc/sorgu?sorguid=1021&id=${id}&string1=${dosyaYolu}&string2=${dosyaAciklamasi}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + currentToken,
            },
          }
        )
        .then((result) => {
          if (talepid) {
            dispatch(
              mesajcevapla(
                result.data.Table[0].id,
                dosyaAciklamasi,
                talepid,
                uyeid,
                siteid,
                id,
                dosyaYolu,
                null,
                ad
              )
            );
          }
          dispatch(isiGetir(id));
        });
    } catch (e) {
      dispatch(dosyaCheckHata(e));
    }
  };
};

export const isiGeriAl = (tamamId, talepid, uyeid, siteid, ad) => {
  return function (dispatch) {
    axios
      .get(`/yc/sorgu?sorguid=1013&id=${tamamId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + currentToken,
        },
      })
      .then((response) => {
        if (talepid) {
          dispatch(
            mesajcevapla(
              response.data.Table[0].id,
              "TALEBİNİZİN DURUMU İŞLEME GERİ ALINDI OLARAK GÜNCELLENDİ",
              talepid,
              uyeid,
              siteid,
              tamamId,
              null,
              null,
              ad
            )
          );
        }
        dispatch(isiGetir(tamamId));
      });
  };
};

export const isiReddet = (id, history) => {
  return function (dispatch) {
    axios
      .get(`/yc/sorgu?sorguid=1011&id=${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + currentToken,
        },
      })
      .then((response) => {
        const isiReddets = response.data;
        dispatch(isRedOl(isiReddets));
        history.push("/bekleyen");
      });
  };
};

export const mesajiSil = (mesajid, talepid, uyeid, siteid, isid, ad) => {
  return function (dispatch) {
    axios
      .get(`/yc/sorgu?sorguid=1112&id=${mesajid}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + currentToken,
        },
      })
      .then((response) => {
        if (talepid) {
          dispatch(
            mesajcevapla(
              mesajid,
              null,
              talepid,
              uyeid,
              siteid,
              isid,
              null,
              1,
              ad
            )
          );
        }
        dispatch(isiGetir(isid));
      });
  };
};

export const kullaniciguncelle = (kullaniciusername, ad, eposta, sifre) => {
  return function (dispatch) {
    dispatch({ type: "GUNCELLE" });
    axios
      .get(
        `/account/kullaniciguncelle?telno=${kullaniciusername}&ad=${ad}&eposta=${eposta}&sifre=${sifre}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + currentToken,
          },
        }
      )
      .then((response) => {
        dispatch({ type: "BASARILIGUNCELLEME" });
        setTimeout(() => {
          dispatch(bilgilerimiGetir());
        }, 3000);
      })
      .catch((err) => {
        console.log("err geldi");
        dispatch({ type: "HATALIGUNCELLEME", error: err });
      });
  };
};

export const bilgilerimiGetir = () => {
  return function (dispatch) {
    axios
      .get(`/yc/sorgu?sorguid=998`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + currentToken,
        },
      })
      .then((response) => {
        dispatch(bilgilerGeldi(response.data.Table[0]));
      })
      .catch((error) => dispatch(bilgilerGelmediHata(error.message)));
  };
};

export const parolaUnuttum = (telNo) => {
  return function (dispatch) {
    dispatch(smsGonderiliyor());
    axios
      .get(`/account/SifremiUnuttum?telno=${telNo}`, {
        method: "GET",
        headers: {},
      })
      .then((response) => {
        dispatch(smsgonderildi(response.status));
      })
      .catch((error) => dispatch(smsGonderilmediHata(error.message)));
  };
};
