import React, { useEffect } from "react";
import { Switch, Route, NavLink } from "react-router-dom";
import {
  getJson,
  kullaniciBilgileriniGetir,
} from "../../redux/actions/yoneticiAction";
import Waiting from "../pages/Waiting";
import Procces from "../pages/Procces";
import Success from "../pages/Success";
import LoginPage from "../pages/LoginPage";
import KanbanDetail from "../pages/KanbanDetail";
import { connect } from "react-redux";
import KanbanDevamDetail from "../pages/KanbanDevamDetail";
import Closed from "../pages/Closed";
import About from "../pages/About";
import { bilgilerimiGetir } from "../../redux/actions/isAction";
import { RingLoader } from "react-spinners";
import Account from "../pages/Account";
import { useDispatch } from "react-redux";
import sayilariGoster from "../../redux/actions/bekleyenAction";

const SectionDashboard = (props) => {
  const dispatch = useDispatch();

  const surelisorgu = () => {
    getJson(
      "https://yonetici1.yonetimcell.com/istakibi/sorgu",
      `&sorguid=997${
        sessionStorage.getItem("proid")
          ? `&proid=${sessionStorage.getItem("proid")}`
          : ""
      }`,
      (dt) => {
        dispatch(sayilariGoster(dt.data.Table[0]));
      }
    );
  };
  useEffect(() => {
    if (sessionStorage.getItem("tokenKey")) {
      setInterval(surelisorgu, 95000);
    }
  }, []);
  useEffect(() => {
    if (sessionStorage.getItem("tokenKey")) {
      props.kullaniciBilgileriniGetir(
        sessionStorage.getItem("SiteID"),
        sessionStorage.getItem("SunucuID")
      );
    }
  }, []);
  return (
    <div className="kanbanWrapper">
      {props.loadingReducer.fetching ? (
        <div className="load">
          <RingLoader size={100} color={"#D21113"} loading={true}></RingLoader>
        </div>
      ) : null}
      <div className="routeMenu">
        <ul className="routeUl">
          <li>
            <NavLink
              activeClassName="aktiful"
              className="navlinksroute"
              to={`/bekleyen`}
            >
              Bekleyen
              {props.sayilarReducer.data.bekleyen > 0 ? (
                <p className="sayi kirmizi">
                  {props.sayilarReducer.data.bekleyen}/
                  {props.sayilarReducer.data.bekleyentumu}
                </p>
              ) : (
                <p className="sayi">
                  {props.sayilarReducer.data.bekleyen}/
                  {props.sayilarReducer.data.bekleyentumu}
                </p>
              )}
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName="aktiful"
              className="navlinksroute"
              to="/islemgoren"
            >
              Devam Eden
              {props.sayilarReducer.data.devameden > 0 ? (
                <p className="sayi kirmizi">
                  {props.sayilarReducer.data.devameden}/
                  {props.sayilarReducer.data.devamedentumu}
                </p>
              ) : (
                <p className="sayi">
                  {props.sayilarReducer.data.devameden}/
                  {props.sayilarReducer.data.devamedentumu}
                </p>
              )}
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName="aktiful"
              className="navlinksroute"
              to="/tamamlanan"
            >
              Tamamlanan
              {props.sayilarReducer.data.tamamlanan > 0 ? (
                <p className="sayi kirmizi">
                  {props.sayilarReducer.data.tamamlanan}/
                  {props.sayilarReducer.data.tamamlanantumu}
                </p>
              ) : (
                <p className="sayi">
                  {props.sayilarReducer.data.tamamlanan}/
                  {props.sayilarReducer.data.tamamlanantumu}
                </p>
              )}
            </NavLink>
          </li>
          <li className="mobilKapatIsler">
            <NavLink
              activeClassName="aktiful"
              className="navlinksroute"
              to="/kapatilan"
            >
              Kapatılan
              <p className="sayi">{props.sayilarReducer.data.kapatilantumu}</p>
            </NavLink>
          </li>
        </ul>
      </div>

      <div className="secilenSayfa">
        <Switch>
          <Route path="/islemgoren" exact component={Procces}></Route>
          <Route path="/tamamlanan" exact component={Success}></Route>
          <Route path="/kapatilan" exact component={Closed}></Route>
          <Route
            path="/bekleyendetay/:id"
            exact
            render={(props) => (
              <KanbanDetail id={props.match.params.id}></KanbanDetail>
            )}
          />
          <Route
            path="/islemgorendetay/:id"
            exact
            render={(props) => (
              <KanbanDevamDetail id={props.match.params.id}></KanbanDevamDetail>
            )}
          />
          <Route path="/account" exact component={Account}></Route>
          <Route path="/about" exact component={About}></Route>

          <Route path="/bekleyen" exact component={Waiting}></Route>

          <Route path="/" component={Waiting}></Route>
        </Switch>
      </div>
    </div>
  );
};
const mapStateToProps = ({ sayilarReducer, loadingReducer }) => {
  return {
    sayilarReducer,
    loadingReducer,
  };
};
const mapDispatchToProps = { kullaniciBilgileriniGetir };

export default connect(mapStateToProps, mapDispatchToProps)(SectionDashboard);
