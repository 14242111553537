import {
  FETCH_BILGI_PENDING,
  FETCH_BILGI_FULFILLED,
  FETCH_BILGI_REJECTED,
} from "../actions/isAction";

const initialState = {
  user: {},
  error: null,
  done: false,
  bilgiGoster: false,
  fetching: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BILGI_PENDING:
      return { fetching: true, error: null, done: false, bilgiGoster: false };

    case FETCH_BILGI_FULFILLED:
      return {
        ...state,
        user: action.payload,
        done: true,
        error: null,
        fetching: false,
        bilgiGoster: false,
      };
    case "GUNCELLE":
      return {
        ...state,
        fetching: true,
        error: null,
        bilgiGoster: false,
      };

    case "BASARILIGUNCELLEME":
      return {
        ...state,
        fetching: false,
        bilgiGoster: true,
      };
    case "HATALIGUNCELLEME":
      return {
        ...state,
        error: action.error,
        bilgiGoster: true,
        fetching: false,
      };
    case FETCH_BILGI_REJECTED:
      return { error: action.payload, fetching: false, done: false };

    default:
      return state;
  }
};
