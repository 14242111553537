import {
  FETCH_SITESAKINLERI_PENDING,
  FETCH_SITESAKINLERI_FULFILLED,
  FETCH_KAPILAR_FULFILLED,
  FETCH_SITESAKINI_FULFILLED,
  FETCH_SITESAKINLERI_REJECTED,
} from "../actions/yoneticiAction";

const initialState = {
  bilgiler: {},
  kapilar: {},
  sakin: [],
  error: null,
  done: false,
  fetching: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SITESAKINLERI_PENDING:
      return { fetching: true, error: null, done: false };

    case FETCH_SITESAKINLERI_FULFILLED:
      return {
        ...state,
        bilgiler: action.payload,
        done: true,
        error: null,
        fetching: false,
      };
    case FETCH_KAPILAR_FULFILLED:
      return {
        ...state,
        kapilar: action.payload,
        done: true,
        error: null,
        fetching: false,
      };
    case FETCH_SITESAKINI_FULFILLED:
      return {
        ...state,
        sakin: action.payload,
        done: true,
        error: null,
        fetching: false,
      };
    case FETCH_SITESAKINLERI_REJECTED:
      return { error: action.payload, fetching: false, done: false };

    default:
      return state;
  }
};
