import axios from "axios";
import { getJson } from "./yoneticiAction";
import { sorguYolBaslangic } from "./isGuncelleActions";

export const FETCH_KAPAT_PENDING = "FETCH_KAPAT_PENDING";
export const FETCH_KAPAT_FULFILLED = "FETCH_KAPAT_FULFILLED";
export const FETCH_KAPAT_REJECTED = "FETCH_KAPAT_REJECTED";

var currentToken = sessionStorage.getItem("token");

export const kapatilanCagir = () => {
  return {
    type: FETCH_KAPAT_PENDING,
    fetching: true,
  };
};

export const kapatilanGelsin = (kapatilanlar) => {
  return {
    type: FETCH_KAPAT_FULFILLED,
    payload: kapatilanlar,
    fetching: false,
  };
};
export const kapatilanHata = (error) => {
  return {
    type: FETCH_KAPAT_REJECTED,
    fetching: false,
    payload: error,
  };
};

export const kapatilanlariGetir = () => {
  return function (dispatch) {
    dispatch(kapatilanCagir());
    axios
      .get("/yc/sorgu?sorguid=10004", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + currentToken,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          const kapatilanlar = response.data;
          dispatch(kapatilanGelsin(kapatilanlar));
        } else {
          window.location.href = "/index.html";
          sessionStorage.clear();
        }
      })
      .catch((error) => {
        dispatch(kapatilanHata(error.message));
        window.location.href = "/index.html";
        sessionStorage.clear();
      });
  };
};

export const yoneticiKapatilanlariGetir = (basTarih, sonTarih) => {
  return (dispatch) => {
    dispatch(kapatilanCagir());
    getJson(
      `${sorguYolBaslangic}istakibi/sorgu`,
      `&sorguid=1006&durumu=7&guncellemetarihi=${basTarih}&tamamlanmatarihi=${sonTarih}${
        sessionStorage.getItem("proid")
          ? `&proid=${sessionStorage.getItem("proid")}`
          : ""
      }`,
      (dt) => {
        dispatch({
          type: FETCH_KAPAT_FULFILLED,
          fetching: false,
          payload: dt.data.Table,
        });
      }
    );
  };
};
