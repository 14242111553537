import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { isiGetir } from "../../redux/actions/isAction";
import { isiTamamla } from "../../redux/actions/isAction";
import { dosyaCek } from "../../redux/actions/isAction";
import { isiGeriAl } from "../../redux/actions/isAction";
import { mesajiSil } from "../../redux/actions/isAction";
import { RingLoader } from "react-spinners";
import Moment from "react-moment";
import PropTypes from "prop-types";
import DialogScreen from "./DialogScreen";
import MesajGiden from "./MesajGiden";
import MesajGelen from "./MesajGelen";
import { icerikgonder } from "../../redux/actions/isAction";

//Asenkton Olayının Çözülmesi
var mAsyncDosya = true;
var mAsyncMesaj = true;
var mAsyncIsiGetir = true;
var mAsyncMesajSil = true;
var mAsyncLoading = true;
//Asenkton Olayının Çözülmesi End

const KanbanDevamDetail = (props) => {
  const [isMesaj, setMesaj] = useState(null);
  const [isDosya, setDosya] = useState(null);

  //  var surecid = props.mesajMetinGonder.data.Table[0].id;

  const [isDosyaAdi, setDosyaAdi] = useState(null);
  const [gozukenDosyaAd, setGozukenDosyaAd] = useState("");
  const [uyari, setUyari] = useState(false);
  const [model, setModel] = useState({
    mesajid: null,
    metin: isMesaj,
    uyeid: null,
    issurecid: null,
  });
  useEffect(() => {
    if (props.id) {
      props.isiGetir(props.id);
    }
  }, []);
  // useEffect(() => {
  //   console.log("2 useeffect");
  //   props.mesajCevaplaMetin(17897, model);
  // }, [props.isReducer.fetching]);

  //Asenkron Fetching işlemleri

  // if (props.metinGonderReducer.fetching) {
  //   if (mAsyncMesaj) {
  //     mAsyncIsiGetir = true;
  //     props.isiGetir(props.id);
  //   }
  //   mAsyncMesaj = false;
  // }

  // if (props.silMesajReducer.fetching) {
  //   if (mAsyncMesajSil) {
  //     mAsyncMesajSil = true;
  //     props.isiGetir(props.id);
  //   }
  //   mAsyncMesajSil = false;
  // }
  //Asenkron Fetching işlemleri End

  const Table = props.isReducer.data.Table;
  const icerikTable = props.isReducer.data.Table2;
  const dosyaTable = props.isReducer.data.Table1 && props.isReducer.data.Table1;
  const Table3 = props.isReducer.data.Table3;

  const isiTamamla = (event) => {
    setUyari(true);

    //props.isiTamamla(props.id);
    // setTimeout(() => {
    //   window.location.href = "/islemgoren";
    // }, 1000);
  };
  const isiGeriAls = (event) => {
    setUyari(true);
  };
  const [isLoading, setLoading] = useState(false);

  const uploadFile = (e) => {
    const reader = new FileReader();
    const dosyalar = e.target.files;
    var siteid = props.isReducer.data.Table[0].siteid;

    setLoading(true);

    var proid = props.isReducer.data.Table[0].proid;

    if (dosyalar[0]) {
      setGozukenDosyaAd(dosyalar[0].name);
    } else {
      setGozukenDosyaAd(null);
      setDosyaAdi(null);
    }

    if (dosyalar[0]) {
      reader.readAsDataURL(dosyalar[0]);
    }
    reader.onload = async (e) => {
      var tokenKey = sessionStorage.getItem("token");
      const data = new FormData();
      data.append("uploadedImage", dosyalar[0]);
      data.append("tokenKey", tokenKey);
      const res = await fetch(
        `https://dosya.yonetimcell.com/dosya/dosyayukle/arsiv/9999/${
          proid !== null && proid !== 0 ? 10000000 + proid : siteid
        }/200/0/748/0/`,
        {
          method: "POST",
          body: data,
        }
      );

      const fileName = await res.json();
      if (res.status === 200) {
        setLoading(false);
      } else {
        setLoading(false);
        alert("Bir Hata Oluştu! Dosyayı Tekrar Yükleyin.");
      }
      if (
        fileName.substr(fileName.length - 4) === ".png" ||
        fileName.substr(fileName.length - 4) === ".jpg" ||
        fileName.substr(fileName.length - 4) === ".gif" ||
        fileName.substr(fileName.length - 5) === ".jpeg" ||
        fileName.substr(fileName.length - 5) === ".tiff"
      ) {
        setDosya(
          `//dosya.yonetimcell.com/dosya/${
            proid !== null && proid !== 0 ? 10000000 + proid : siteid
          }/arsiv/k${fileName}`
        );
      } else {
        setDosya(
          `//dosya.yonetimcell.com/dosya/${
            proid !== null && proid !== 0 ? 10000000 + proid : siteid
          }/arsiv/${fileName}`
        );
      }

      setDosyaAdi(fileName);
    };
  };

  const onChangeMesaj = (event) => {
    setMesaj(event.target.value);
  };

  const mesajiGonder = (event) => {
    //Loadagin Çalışıyor.
    if (isDosya !== null) {
      if (isMesaj === null) {
        document.getElementById("mesajYaz").value = "";
      }
      mAsyncDosya = true;

      props.dosyaCek(
        Table[0].id,
        isDosya,
        isMesaj,
        Table[0].talepid,
        Table[0].uyeid,
        Table[0].siteid,
        Table3[0].ad
      );

      document.getElementById("gorunenDosyaAdi").value = "";
      document.getElementById("myFile").value = "";
      setGozukenDosyaAd(null);
      setMesaj(null);

      setDosyaAdi(null);
      setDosya(null);
      document.getElementById("mesajYaz").value = "";
    } else {
      if (isMesaj === null) {
        alert("Mesaj Boş Gönderilemez");
      } else {
        props.icerikgonder(
          "sorguid=1020&id=" + Table[0].id + "&string1=" + isMesaj,
          isMesaj,
          Table[0].talepid,
          Table[0].uyeid,
          Table[0].siteid,
          Table[0].id,
          Table3[0].ad
        );
      }
      setMesaj(null);
      document.getElementById("mesajYaz").value = "";
      setGozukenDosyaAd(null);
    }
  };

  return (
    <div>
      {isLoading === true ? (
        <div className="load">
          <RingLoader size={100} color={"#D21113"} loading={true}></RingLoader>
        </div>
      ) : null}

      {props.isReducer.fetching ? (
        <div className="load">
          <RingLoader size={100} color={"#D21113"} loading={true}></RingLoader>
        </div>
      ) : null}
      {props.dosyaGonderReducer.fetching ? (
        <div className="load">
          <RingLoader size={100} color={"#D21113"} loading={true}></RingLoader>
        </div>
      ) : null}
      {props.dosyaCekReducer.fetching ? (
        <div className="load">
          <RingLoader size={100} color={"#D21113"} loading={true}></RingLoader>
        </div>
      ) : null}
      {props.metinGonderReducer.fetching ? (
        <div className="load">
          <RingLoader size={100} color={"#D21113"} loading={true}></RingLoader>
        </div>
      ) : null}

      {props.isTamamlaReducer.fetching ? (
        <div className="load">
          <RingLoader size={100} color={"#D21113"} loading={true}></RingLoader>
        </div>
      ) : null}

      {props.isGeriAlReducer.fetching ? (
        <div className="load">
          <RingLoader size={100} color={"#D21113"} loading={true}></RingLoader>
        </div>
      ) : null}

      <div className="popUpAlert">
        {Table &&
          Table.map((item) => (
            <aside key={item.id} className="popUpWrapper">
              {item.siteadi !== null ? (
                <h4 className="siteAdi">{item.siteadi}</h4>
              ) : (
                <h4 className="siteAdid">{item.siteadi}</h4>
              )}

              <aside className="siteBilgileri">
                <aside className="cols colssol">
                  <p>
                    İşi Oluşturan: <b>{item.isiolusturan}</b>
                  </p>
                  <p>
                    İşin Durumu: <b>{item.durumadi}</b>
                  </p>
                </aside>
                <aside className="cols colsorta">
                  <p className="olusturmaTarihi">
                    Oluşturulma Tarihi:{" "}
                    <b>
                      {
                        <Moment format="DD MMMM YYYY">
                          {item.olusturmatarihi}
                        </Moment>
                      }
                    </b>
                  </p>
                  {item.isintarihi !== null ? (
                    <p className="teslimTarihi">
                      Teslim Tarihi:{" "}
                      <b>
                        {
                          <Moment format="DD MMMM YYYY">
                            {item.isintarihi}
                          </Moment>
                        }
                      </b>
                    </p>
                  ) : (
                    <p></p>
                  )}
                </aside>
                {item.uyeid !== null ? (
                  <div className="sitesakinibilgileris">
                    <p className="sitasknbslk">Site Sakini Bilgileri</p>
                    <aside className="cols colssag">
                      <aside className="ikiliDiv">
                        <p>
                          Ad Soyad: <b>{item.adsoyad}</b>
                        </p>
                        <p>
                          Daire: <b>{item.blok}</b> <b>{item.kapino}</b>
                        </p>
                      </aside>
                      <aside className="ikiliDiv">
                        {item.telefon !== null ? (
                          <p>
                            Telefon: <b>{item.telefon}</b>
                          </p>
                        ) : null}
                        {item.email !== null ? (
                          <p>
                            E-Mail: <b>{item.email}</b>
                          </p>
                        ) : null}
                      </aside>
                    </aside>
                  </div>
                ) : null}
              </aside>
              <aside className="isinAciklamasiBaslik">
                {dosyaTable &&
                  dosyaTable.map((dosya) =>
                    dosya.dosya.substr(dosya.dosya.length - 4) === ".png" ||
                    dosya.dosya.substr(dosya.dosya.length - 4) === ".jpg" ||
                    dosya.dosya.substr(dosya.dosya.length - 5) === ".jpeg" ||
                    dosya.dosya.substr(dosya.dosya.length - 5) === ".tiff" ? (
                      <aside key={dosya.id} className="gorselVarsa">
                        <img src={dosya.dosya} alt={dosya.aciklama}></img>
                        <p>
                          Dosya Açıklaması: <b>{dosya.aciklama}</b>
                        </p>
                      </aside>
                    ) : (
                      <aside className="txtGibiDosya">
                        <button
                          onClick={() => window.open(`${dosya.dosya}`)}
                          className="dosyaIse"
                        >
                          Dosyayı Aç
                        </button>
                      </aside>
                    )
                  )}
                {item.isinaciklamasi}
              </aside>
              <div className="popWrap">
                {icerikTable &&
                  icerikTable.map((icerik) =>
                    item.kullaniciid === icerik.kullaniciid ? (
                      <MesajGiden
                        key={icerik.id}
                        icerik={icerik}
                        isiGetir={isiGetir}
                      ></MesajGiden>
                    ) : (
                      <MesajGelen key={icerik.id} icerik={icerik}></MesajGelen>
                    )
                  )}
              </div>
              {item.isindurumu === 5 ? (
                <aside className="buttonlar">
                  {uyari === true ? (
                    <DialogScreen
                      dialog={uyari}
                      isid={item.id}
                      islem={"İşi"}
                      olay={"geri almak"}
                      dialogKapat={() => setUyari(false)}
                    ></DialogScreen>
                  ) : null}
                  <button
                    className="btn btn-onay btn-tekli"
                    onClick={isiGeriAls}
                  >
                    İşi Geri Al
                  </button>
                </aside>
              ) : (
                <aside className="altGonder">
                  {item.isindurumu !== 7 ? (
                    <div>
                      <div className="upload-btn-wrapper">
                        <button className="dsyagndrbtn">Dosya Yükle</button>
                        <input
                          type="file"
                          id="myFile"
                          onChange={uploadFile}
                          name="myfile"
                        />
                        <span id="gorunenDosyaAdi" className="gozukenDosyaAd">
                          {gozukenDosyaAd}
                        </span>
                      </div>
                      <aside className="icerik-gonder-wrap">
                        <textarea
                          id="mesajYaz"
                          name="mesajYaz"
                          onChange={onChangeMesaj}
                          className="mesaj-gonder-textarea"
                          placeholder={
                            isDosyaAdi !== null
                              ? "Dosya Açıklaması Girin."
                              : "Mesaj Girin."
                          }
                        ></textarea>
                      </aside>
                      {uyari === true ? (
                        <DialogScreen
                          dialog={uyari}
                          isid={item.id}
                          islem={"İşi"}
                          olay={"tamamlamak"}
                          dialogKapat={() => setUyari(false)}
                        ></DialogScreen>
                      ) : null}

                      <aside className="buttonlar">
                        <button className="btn btn-onay" onClick={mesajiGonder}>
                          Mesajı Gönder
                        </button>
                        <button
                          className="btn btn-tamamla"
                          onClick={isiTamamla}
                        >
                          İşi Tamamla
                        </button>
                      </aside>
                    </div>
                  ) : null}
                </aside>
              )}
            </aside>
          ))}
      </div>
    </div>
  );
};

const mapStateToProps = ({
  isReducer,
  isTamamlaReducer,
  isGeriAlReducer,
  silMesajReducer,
  metinGonderReducer,
  mesajCevaplaMetinReducer,
  dosyaCekReducer,
  dosyaGonderReducer,
}) => {
  return {
    isReducer,
    dosyaGonderReducer,
    mesajCevaplaMetinReducer,
    isGeriAlReducer,
    dosyaCekReducer,
    silMesajReducer,
    metinGonderReducer,
    isTamamlaReducer,
  };
};

const mapDispatchToProps = {
  isiGetir,
  isiGeriAl,
  dosyaCek,
  mesajiSil,
  isiTamamla,
  icerikgonder,
};

KanbanDevamDetail.propTypes = {
  isReducer: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(KanbanDevamDetail);
