import {
  FETCH_DEVAM_PENDING,
  FETCH_DEVAM_FULFILLED,
  FETCH_DEVAM_REJECTED,
} from "../actions/devamEdenAction";

const initialState = {
  Table: [],
  devamedenler: [],
  fetching: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DEVAM_PENDING:
      return { ...state, fetching: true };

    case FETCH_DEVAM_FULFILLED:
      return {
        ...state,
        fetching: false,
        devamedenler: action.payload,
        Table: action.payload.Table,
      };
    case FETCH_DEVAM_REJECTED:
      return { error: action.payload, fetching: false, ...state };

    default:
      return state;
  }
};
