import React, { useState } from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";

var buyukDosya = "";
const MesajGelen = (props) => {
  buyukDosya = props.icerik.dosya;

  return (
    <aside key={props.icerik.id} className="isHikayesi gelen">
      <aside className="isItemKapsa gelenisItem">
        <aside className="isItem gelen">
          <p className="gonderen-ad">
            <i className="fa fa-user gonderenicon" aria-hidden="true"></i>
            <b>
              {props.icerik.kaydeden} (
              {props.icerik.durumadi
                ? props.icerik.durumadi
                : props.durumAdi[0] && props.durumAdi[0].tanim}
              ){" "}
            </b>
          </p>
          <aside className="mesaj-icerik">
            {props.icerik.dosya && props.icerik.dosya ? (
              <div>
                {props.icerik.dosya.substr(props.icerik.dosya.length - 4) ===
                  ".png" ||
                props.icerik.dosya.substr(props.icerik.dosya.length - 4) ===
                  ".jpg" ||
                props.icerik.dosya.substr(props.icerik.dosya.length - 4) ===
                  ".gif" ||
                props.icerik.dosya.substr(props.icerik.dosya.length - 5) ===
                  ".tiff" ||
                props.icerik.dosya.substr(props.icerik.dosya.length - 5) ===
                  ".jpeg" ? (
                  <div>
                    {props.icerik.silindi !== 1 ? (
                      <aside>
                        <Link to={props.icerik.dosya} target="_blank">
                          <img
                            className="mesaj-icerik-img"
                            src={buyukDosya}
                            alt="Görsel Yüklenemedi..."
                          />
                        </Link>
                        <p className="gonderilen-icerik">
                          {props.icerik.dosyaaciklama !== "null" ? (
                            <span>{props.icerik.dosyaaciklama}</span>
                          ) : (
                            <span> </span>
                          )}
                        </p>
                      </aside>
                    ) : null}
                  </div>
                ) : (
                  <aside className="txtGibiDosya">
                    <button
                      onClick={() =>
                        window.open(`${props.icerik.dosya}, '_blank'`)
                      }
                      className="dosyaIse"
                    >
                      {props.icerik.dosya.substr(props.icerik.dosya.length - 4)}{" "}
                      Uzantılı Dosyayı Aç
                    </button>
                  </aside>
                )}
              </div>
            ) : null}
            <p className="gonderilen-icerik ">
              {props.icerik.aciklama !== null ? (
                <span>{props.icerik.aciklama}</span>
              ) : (
                <span> </span>
              )}
            </p>
          </aside>
        </aside>

        <p className="gndrmTrh">
          <b className="gonderilen-saat">
            {<Moment format="DD MMMM YYYY HH:mm">{props.icerik.tarihi}</Moment>}
          </b>
        </p>
      </aside>
    </aside>
  );
};

export default MesajGelen;
