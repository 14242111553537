import React, { useState } from "react";
import DialogScreen from "./DialogScreen";
import { useDispatch } from "react-redux";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";

const IsOlustur = () => {
  const [modal, setModal] = useState(false);
  const [uyari, setUyari] = useState(false);

  const popUpAc = (e) => {
    setModal(!modal);
    setUyari(true);
  };

  return (
    <div>
      <Fab className="fab" onClick={popUpAc} size="medium" aria-label="add">
        <AddIcon />
      </Fab>
      <div className="popUpWrap">
        {uyari ? (
          <DialogScreen
            yonetici={true}
            dialog={uyari}
            isid={"işid"}
            islem={"İşi"}
            olay={"oluşturmak"}
            dialogKapat={() => setUyari(false)}
          ></DialogScreen>
        ) : null}
      </div>
    </div>
  );
};

export default IsOlustur;
