import {
  FETCH_USER_PENDING,
  FETCH_USER_FULFILLED,
  FETCH_USER_REJECTED
} from "../actions/userAction";

const initialState = {
  user: {},
  access_token: {},
  telNo: "",
  error: null,
  done: false,
  fetching: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER_PENDING:
      return { fetching: true, error: null, done: false };

    case FETCH_USER_FULFILLED:
      return {
        ...state,
        access_token: action.payload,
        telNo: action.telNo,
        done: true,
        error: null,
        fetching: false
      };
    case FETCH_USER_REJECTED:
      return { error: action.payload, fetching: false, done: false };

    default:
      return state;
  }
};
