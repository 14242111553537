import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { kapatilanlariGetir } from "../../redux/actions/kapatilanActions";
import KanbanDevamItem from "../pages/KanbanDevamItem";
import { yoneticiKapatilanlariGetir } from "../../redux/actions/kapatilanActions";
import moment from "moment";
import Selects from "./Selects";
import DialogScreen from "./DialogScreen";
import KriterBulunamadi from "./KriterBulunamadi";

const Closed = (props) => {
  const [modal, setModal] = useState(false);
  const [uyari, setUyari] = useState(false);
  const [yonetici, setYonetici] = useState(false);
  const [yoneticiKapatilanlar, setYoneticiKapatilanlar] = useState(null);
  const [sorguTarih, setSorguTarih] = useState({
    baslangicTarihi: moment(`2020-${new Date().getMonth() + 1}-01`).format(
      "yyyy-MM-DD"
    ),
    sonTarih: moment(new Date()).format("yyyy-MM-DD"),
  });
  useEffect(() => {
    if (sessionStorage.getItem("token")) {
      props.kapatilanlariGetir();
      setYonetici(false);
    }
    if (sessionStorage.getItem("tokenKey")) {
      setYonetici(true);
    }
  }, []);
  const openModal = (e) => {
    if (sessionStorage.getItem("tokenKey")) {
      props.guncellenecekIsiGetir(props.id);
      setModal(!modal);
      setUyari(true);
    }
  };
  useEffect(() => {
    props.yoneticiKapatilanlariGetir(
      sorguTarih.baslangicTarihi,
      sorguTarih.sonTarih
    );
  }, [sorguTarih]);

  useEffect(() => {
    setYoneticiKapatilanlar(props.kapatilanReducer.yoneticiKapatilanlar);
  }, [props.kapatilanReducer.yoneticiKapatilanlar]);

  const handleClick = (e) => {
    e.preventDefault();
    setSorguTarih({ ...sorguTarih, [e.target.name]: e.target.value });
  };

  const kapatilanTable = props.kapatilanReducer.Table;

  return (
    <div>
      {uyari ? (
        <DialogScreen
          open={openModal}
          yonetici={true}
          dialog={uyari}
          isid={props.id}
          islem={"İşi"}
          olay={"güncellemek"}
          dialogKapat={() => setUyari(false)}
        ></DialogScreen>
      ) : null}
      {!yonetici ? (
        <aside className="secNavWrapper">
          {kapatilanTable &&
            kapatilanTable.map((item) => (
              <KanbanDevamItem
                key={item.id}
                id={item.id}
                devamEdenIs={item.id}
                durum={item.isindurumu}
                yenimesaj={item.yeni}
                ad={item.siteadi}
                teslimtarihi={item.isintarihi}
                olusturmatarihi={item.olusturmatarihi}
                aciklama={item.isinaciklamasi}
              ></KanbanDevamItem>
            ))}
        </aside>
      ) : (
        <aside>
          <div className="sorguluIsGetir">
            <aside className="inpt2">
              <label>Sorgu Tarih Aralığı</label>
              <input
                style={
                  yoneticiKapatilanlar.length > 0
                    ? { color: "#222", borderBottom: "2px solid #00e932" }
                    : { color: "#e31e25", borderBottom: "2px solid #e31e25" }
                }
                name="baslangicTarihi"
                onChange={handleClick}
                defaultValue={sorguTarih.baslangicTarihi}
                type="date"
              />
              <input
                style={
                  yoneticiKapatilanlar.length > 0
                    ? { color: "#222", borderBottom: "2px solid #00e932" }
                    : { color: "#e31e25", borderBottom: "2px solid #e31e25" }
                }
                name="sonTarih"
                onChange={handleClick}
                defaultValue={sorguTarih.sonTarih}
                type="date"
              />
            </aside>
          </div>
          <Selects is={"kapatilan"}></Selects>
          <aside>
            {yoneticiKapatilanlar.length > 0 ? (
              ""
            ) : (
              <KriterBulunamadi
                nerede={"Sorgu Tarih Aralığında"}
                islem={"Kapatılan"}
              ></KriterBulunamadi>
            )}
          </aside>
        </aside>
      )}
    </div>
  );
};
const mapStateToProps = ({ kapatilanReducer }) => {
  return {
    kapatilanReducer,
  };
};
const mapDispatchToProps = {
  kapatilanlariGetir,
  yoneticiKapatilanlariGetir,
};
export default connect(mapStateToProps, mapDispatchToProps)(Closed);
