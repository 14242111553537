import {
  FETCH_TAMAM_PENDING,
  FETCH_TAMAM_FULFILLED,
  FETCH_TAMAM_REJECTED,
} from "../actions/tamamlananAction";

const initialState = {
  Table: [],
  tamamlananlarYonetici: [],
  fetching: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TAMAM_PENDING:
      return { ...state, fetching: true };

    case FETCH_TAMAM_FULFILLED:
      return {
        ...state,
        fetching: false,
        tamamlananlarYonetici: action.payload,
        Table: action.payload.Table,
      };
    case FETCH_TAMAM_REJECTED:
      return { error: action.payload, fetching: false, ...state };

    default:
      return state;
  }
};
