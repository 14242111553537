import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import { RingLoader } from "react-spinners";
import { useTheme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { isiKabulEt } from "../../redux/actions/isAction";
import { isiReddet } from "../../redux/actions/isAction";
import { siteSakiniGetir } from "../../redux/actions/yoneticiAction";
import { isiGetir } from "../../redux/actions/isAction";
import { siteTanimlariniGetir } from "../../redux/actions/yoneticiAction";
import { isiGeriAl } from "../../redux/actions/isAction";
import { mesajiSil } from "../../redux/actions/isAction";
import { isiTamamla } from "../../redux/actions/isAction";
import { siteSakiniAraGetir } from "../../redux/actions/yoneticiAction";
import { isOlustur } from "../../redux/actions/yoneticiAction";
import { kapilariGetir } from "../../redux/actions/yoneticiAction";
import {
  bekleyenleriGetir,
  yoneticiBekleyenleriGetir,
} from "../../redux/actions/bekleyenAction";
import { yoneticiDevamEdenleriGetir } from "../../redux/actions/devamEdenAction";
import { withRouter, Link } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { dsyaSil } from "../../redux/actions/yoneticiAction";
import { dosyaKaydet } from "../../redux/actions/yoneticiAction";
import { proDosyaKaydet } from "../../redux/actions/yoneticiAction";
import MesajGiden from "../pages/MesajGiden";
import {
  personelGorsunCheck,
  guncellenecekIsiGetir,
} from "../../redux/actions/isGuncelleActions";
import { bindActionCreators } from "redux";
import moment from "moment";
import HataMesaji from "../containers/HataMesaji";
import { useDispatch } from "react-redux";
import { API_YC_BASE } from "../../config/API_BASE";
import { isGuncels } from "../../redux/actions/isGuncelleActions";
import { surecsill } from "../../redux/actions/isGuncelleActions";
import MesajGelen from "./MesajGelen";
import { dosyaKaydets } from "../../redux/actions/isGuncelleActions";
import { yoneticiTamamlananlariGetir } from "../../redux/actions/tamamlananAction";
import staticPersonelImg from "../../helpers/images/personel.png";
import staticSiteSakiniImg from "../../helpers/images/sitesakini.png";
import staticbuildingImg from "../../helpers/images/real-estate.png";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';


const DialogScreen = (props) => {
  const [open, setOpen] = useState(props.dialog);
  const [yeniIs, setYeniIs] = useState(true);
  const [isGuncelle, setIsGuncelle] = useState(false);

  const isTurleri = [
    { id: 0, tanim: "İş Türü Seçiniz.." },
    { id: 1, tanim: "ÖNERİ İSTEK" },
    { id: 2, tanim: "BİLGİ TALEBİ" },
    { id: 3, tanim: "ARIZA" },
    { id: 5, tanim: "ÖDEME EMRİ" },
    { id: 4, tanim: "DİĞER" },
  ];

  const [isAlaniTable, setIsAlaniTable] = useState(
    props.siteTanimReducer.bilgiler.Table
  );
  const [siteSakinBlok, setSiteSakinBlok] = useState(
    props.siteSakinleriReducer.bilgiler.Table1
  );
  const [siteSakinKapi, setSiteSakinKapi] = useState(
    props.siteSakinleriReducer.kapilar.Table
  );
  const [siteSakini, setSiteSakini] = useState(null);

  const [sakinler, setSakinler] = useState(
    props.siteSakinleriReducer.bilgiler.Table
  );
  const [yol, setYol] = useState("dosya.yonetimcell.com/dosya/");
  const [uyeAra, setUyeAra] = useState("");
  const [secilenBlok, setSecilenBlok] = useState({ id: 0, tanim: null });
  const [secilenKapi, setSecilenKapi] = useState(0);
  const [dosyaAd, setDosyaAd] = useState("");
  const [dosya, setDosya] = useState();
  const [isId, setIsId] = useState("");
  const [yuklenenDosyalar, setYuklenenDosyalar] = useState("");

  const [smsGonder, setSmsGonder] = useState(true);
  const [mailGonder, setMailGonder] = useState(false);

  const [personeller, setPersoneller] = useState(
    props.siteTanimReducer.personeller
  );

  const [pGorsun, setPGorsun] = useState(false);
  const [secilenPersonel, setSecilenPersonel] = useState({
    ad: null,
    kulid: null,
    telefon: null,
    eposta: null,
  });
  const [personelSms, setPersonelSms] = useState(false);
  const [arananUyelerToggle, setArananUyelerToggle] = useState(false);
  const [siteSakinSecerek, setSiteSakinSecerek] = useState(false);
  const dispatch = useDispatch();
  const [pro, setPro] = useState(false);

  const [secilenler, setSecilenler] = useState({
    SiteId: sessionStorage.getItem("SiteID"),
    SunucuId: sessionStorage.getItem("SunucuID"),
    adsoyad: null,
    blok: null,
    email: null,
    gorevid: null,
    guncellemetarihi: "",
    id: isId ? isId : null,
    int2: null,
    int3: 0,
    isinaciklamasi: "",
    isinadi: null,
    isindurumu: 1,
    isiolusturan: sessionStorage.getItem("KullaniciAd"),
    olusturmaTarihi: moment(new Date()).format("yyyy-MM-DD"),
    kapino: null,
    sorguid: 1110,
    string1: sessionStorage.getItem("KullaniciId"),
    string2: null,
    string3: !sessionStorage.getItem("proid")
      ? sessionStorage.getItem("SiteAdi")
      : null,
    talepid: null,
    tasinmazid: null,
    telefon: null,
    uyeid: null,
    proid: sessionStorage.getItem("proid")
      ? sessionStorage.getItem("proid")
      : null,
    websifre: null,
  });
  const [gelenIsBilgileri, setGelenIsBilgileri] = useState();
  const [isDurumlar, setIsDurumlar] = useState();
  const [surec, setSurec] = useState();
  const [isAlanlariSecilenGorev, setIsAlanlariSecilenGorev] = useState("");
  const [hataGoster, setHataGoster] = useState(false);
  const [metin, setMetin] = useState("");
  const [eminMisiniz, setEminMisiniz] = useState(false);
  const [genislet, setGenislet] = useState(false);
  const [windowsize, setWindowSize] = useState({ width: undefined });

  useEffect(() => {
    setGelenIsBilgileri(props.isGuncelleReducer.bilgiler);
  }, [props.isGuncelleReducer.bilgiler]);

  useEffect(() => {
    setYuklenenDosyalar(props.isOlusturReducer.dosyalar);
  }, [props.isOlusturReducer.dosyalar]);
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
      });
      if (windowsize.width < 1200) {
        setGenislet(false);
      } else {
        setGenislet(true);
      }
    }

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, [windowsize.width]);

  useEffect(() => {
    setYuklenenDosyalar([]);
    if (sessionStorage.getItem("tokenKey")) {
      if (sessionStorage.getItem("proid")) {
        setPro(true);
      }
      if (secilenBlok.id === 0) {
        setSiteSakini(null);
        setSiteSakinKapi(0);
        setSecilenler({
          ...secilenler,
          adsoyad: null,
          email: null,
          blok: null,
          kapino: null,
          id: null,
        });
      } else {
        setSecilenBlok({ ...secilenBlok, id: 0 });
      }
      if (props.olay === "oluşturmak") {
        setYeniIs(true);
      } else if (props.olay === "güncellemek") {
        dispatch(guncellenecekIsiGetir(props.isid));
        setYeniIs(false);
      }

      return () => {
        if (props.p === "Devam Eden") {
          dispatch(yoneticiDevamEdenleriGetir());
        }
        if (props.p === "bekleyen") {
          dispatch(
            yoneticiBekleyenleriGetir(
              API_YC_BASE,
              sessionStorage.getItem("SiteID"),
              sessionStorage.getItem("SunucuID")
            )
          );
        }
        if (props.p === "Tamamlanan") {
          dispatch(yoneticiTamamlananlariGetir());
        }
        if (props.p === "kapatilan") {
        }
        setYuklenenDosyalar([]);

        dispatch({
          type: "FETCH_DOSYALAR_BOSALT",
          dosyalar: [],
        });
      };
    }
  }, []);

  useEffect(() => {
    setIsAlaniTable(props.siteTanimReducer.bilgiler.Table);
  }, [props.siteTanimReducer.bilgiler.Table]);

  useEffect(() => {
    setSiteSakinBlok(props.siteSakinleriReducer.bilgiler.Table1);
  }, [props.siteSakinleriReducer.bilgiler.Table1]);

  useEffect(() => {
    if (secilenBlok.id !== 0 || uyeAra !== "") {
      setSiteSakinKapi(props.siteSakinleriReducer.kapilar.Table);
    }
  }, [props.siteSakinleriReducer.kapilar.Table, secilenBlok.id, uyeAra]);

  useEffect(() => {
    if (secilenBlok.id !== 0 && secilenKapi !== 0) {
      setSiteSakini([props.siteSakinleriReducer.sakin]);
    } else {
      if (uyeAra !== "") {
        setSiteSakini([props.siteSakinleriReducer.sakin]);
        setSiteSakinSecerek(true);
      }
    }
  }, [props.siteSakinleriReducer.sakin, secilenBlok.id, secilenKapi]);

  useEffect(() => {
    setIsId(
      props.isOlusturReducer.dosyalar[0] &&
        props.isOlusturReducer.dosyalar[0].isId
    );
    setSecilenler({
      ...secilenler,
      id:
        props.isOlusturReducer.dosyalar[0] &&
        props.isOlusturReducer.dosyalar[0].isId,
    });
  }, [props.isOlusturReducer.dosyalar]);

  useEffect(() => {
    if (secilenBlok.id !== 0 || uyeAra !== "") {
      setSecilenler({
        ...secilenler,
        adsoyad: props.siteSakinleriReducer.sakin.adsoyad
          ? props.siteSakinleriReducer.sakin.adsoyad
          : null,
        email: props.siteSakinleriReducer.sakin.eposta1
          ? props.siteSakinleriReducer.sakin.eposta1
          : null,
        kapino: props.siteSakinleriReducer.sakin.kapino
          ? props.siteSakinleriReducer.sakin.kapino
          : null,
        blok: props.siteSakinleriReducer.sakin.tanim
          ? props.siteSakinleriReducer.sakin.tanim
          : null,
        telefon: props.siteSakinleriReducer.sakin.telefon1
          ? props.siteSakinleriReducer.sakin.telefon1
          : null,
        tasinmazid: props.siteSakinleriReducer.sakin.id
          ? props.siteSakinleriReducer.sakin.id
          : null,
        websifre: props.siteSakinleriReducer.sakin.websifre
          ? props.siteSakinleriReducer.sakin.websifre
          : null,
        id:
          props.isOlusturReducer.dosyalar[0] &&
          props.isOlusturReducer.dosyalar[0].isId,
      });
    }
  }, [
    props.siteSakinleriReducer.sakin,
    props.isOlusturReducer.dosyalar,
    secilenBlok.id,
    uyeAra,
    isId,
  ]);

  useEffect(() => {
    setSakinler(props.siteSakinleriReducer.bilgiler.Table);
  }, [props.siteSakinleriReducer.bilgiler.Table]);

  useEffect(() => {
    setPersoneller(props.siteTanimReducer.personeller);
  }, [props.siteTanimReducer.personeller]);

  useEffect(() => {
    if (!yeniIs) {
      setIsAlanlariSecilenGorev(
        props.isGuncelleReducer.bilgiler &&
          `x${
            props.isGuncelleReducer.bilgiler.simdikigorev
              ? props.isGuncelleReducer.bilgiler.simdikigorev
              : 0
          }x`
      );
      setSecilenPersonel((secilenPersonel) => ({
        ...secilenPersonel,
        kulid:
          props.isGuncelleReducer.bilgiler &&
          props.isGuncelleReducer.bilgiler.simdikipersonel,
      }));
      setSecilenler((secilenler) => ({
        ...secilenler,
        int3:
          props.isGuncelleReducer.bilgiler &&
          props.isGuncelleReducer.bilgiler.pgorsun,
      }));
    }
  }, [props.isGuncelleReducer.bilgiler, yeniIs]);

  useEffect(() => {
    setSurec(
      props.isGuncelleReducer.bilgiler && props.isGuncelleReducer.bilgiler.surec
    );
  }, [props.isGuncelleReducer.bilgiler]);

  useEffect(() => {
    setIsDurumlar(props.isGuncelleReducer.durumlar);
  }, [props.isGuncelleReducer.durumlar]);

  const Table = props.isReducer.data.Table;
  const Table3 = props.isReducer.data.Table3;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  // const isAlaniSec = (e) => {
  //   setSecilenIsAlani(e.target.value);
  // };
  const secileniBul = (e) => {
    setSecilenler({ ...secilenler, [e.target.name]: e.target.value });
    if (e.target.name === "gorevid") {
      setIsAlanlariSecilenGorev("x" + e.target.value + "x");
      setGelenIsBilgileri({
        ...gelenIsBilgileri,
        simdikigorev: Number(e.target.value),
        simdikipersonel: null,
      });
    }
    if (e.target.name === "int3") {
      if (yeniIs) {
        if (!pGorsun) {
          setSecilenler({ ...secilenler, int3: 1 });
        } else {
          setSecilenler({ ...secilenler, int3: 0 });
        }
      } else {
        if (e.target.checked === true) {
          setSecilenler({ ...secilenler, int3: 1 });
          setGelenIsBilgileri({ ...gelenIsBilgileri, pgorsun: 1 });
          props.personelGorsunCheck(props.isid, e.target.checked);
        } else {
          setSecilenler({ ...secilenler, int3: 0 });
          setGelenIsBilgileri({
            ...gelenIsBilgileri,
            pgorsun: e.target.checked,
          });
          props.personelGorsunCheck(props.isid, e.target.checked);
        }
      }
      setPGorsun(!pGorsun);
    }
    if (e.target.name === "simdikiteslimtarihi") {
      setGelenIsBilgileri({
        ...gelenIsBilgileri,
        simdikiteslimtarihi: moment(e.target.value).format("YYYY-MM-DD"),
      });
    }

    if (pro && e.target.name === "string3") {
      const siteler = JSON.parse(sessionStorage.getItem("siteler"));
      var secilenSite = siteler.filter((sites) => {
        return (
          sites.SiteAdi.toLowerCase().indexOf(e.target.value.toLowerCase()) !==
          -1
        );
      });
      setSecilenler({
        ...secilenler,
        int1: secilenSite[0].ID,
        string3: e.target.value,
      });

      if (e.target.value === "Site Seçiniz") {
        setSecilenler({ ...secilenler, string3: null });
      }
    }
  };
  const isinTuruChange = (e) => {
    if (e.target.value !== 0) {
      setSecilenler({ ...secilenler, [e.target.name]: Number(e.target.value) });
      setGelenIsBilgileri({
        ...gelenIsBilgileri,
        simdikitur: Number(e.target.value),
      });
    }
  };
  const dosyaYukleFunc = (e) => {
    const reader = new FileReader();
    const dosyalar = e.target.files;
    if (yeniIs) {
      if (dosyalar[0]) {
        if (pro) {
          reader.readAsDataURL(dosyalar[0]);
          setDosyaAd(dosyalar[0].name);

          props.proDosyaKaydet(
            secilenler.isinaciklamasi,
            dosyalar[0],
            dosyaAd,
            isId ? isId : null,
            sessionStorage.getItem("SiteID"),
            setYuklenenDosyalar
          );
        } else {
          reader.readAsDataURL(dosyalar[0]);
          setDosyaAd(dosyalar[0].name);
          props.dosyaKaydet(
            secilenler.isinaciklamasi,
            dosyalar[0],
            dosyaAd,
            isId ? isId : null,
            sessionStorage.getItem("SiteID"),
            setYuklenenDosyalar
          );
        }
      } else {
        setDosyaAd(null);
      }
    } else {
      if (dosyalar[0]) {
        reader.readAsDataURL(dosyalar[0]);
        setDosyaAd(dosyalar[0].name);
        props.dosyaKaydets(
          gelenIsBilgileri.simdikipersonel,
          personeller,
          secilenler.isinaciklamasi,
          dosyalar[0],
          props.isid,
          gelenIsBilgileri.talepid,
          `//dosya.yonetimcell.com/dosya/${sessionStorage.getItem("SiteID")}/`,
          gelenIsBilgileri.uyeid
        );
      } else {
        setDosyaAd(null);
      }
    }
  };
  const siteSakinSmsFunc = (e) => {
    setSmsGonder(!smsGonder);
  };
  const siteSakinMailFunc = (e) => {
    setMailGonder(!mailGonder);
  };
  const personelSec = (e) => {
    var index = e.nativeEvent.target.selectedIndex;
    if (e.target.value !== "0") {
      setSecilenPersonel({
        ...secilenPersonel,
        ad: e.nativeEvent.target[index].text,
        kulid: e.target.value,
      });
      setSecilenler({ ...secilenler, string2: e.target.value });
      setGelenIsBilgileri({
        ...gelenIsBilgileri,
        simdikipersonel: e.target.value,
      });
    } else {
      setSecilenPersonel(null);
      setSecilenler({ ...secilenler, int3: 0 });
      setGelenIsBilgileri({
        ...gelenIsBilgileri,
        simdikipersonel: null,
      });
    }
  };
  const personelSmsGonders = (e) => {
    setPersonelSms(!personelSms);
  };

  const blokSec = (e) => {
    var index = e.nativeEvent.target.selectedIndex;

    if (Number(e.target.value) !== 0 && secilenKapi === 0) {
      setSecilenBlok({
        ...secilenBlok,
        id: Number(e.target.value),
        tanim: e.nativeEvent.target[index].text,
      });
      kapiSorgula(Number(e.target.value));
      setSiteSakinKapi(0);
      setSecilenKapi(0);
      setSiteSakini(null);
      setSecilenler({ ...secilenler, blok: e.nativeEvent.target[index].text });
    } else {
      setSecilenBlok({ ...secilenBlok, id: 0 });
      setSiteSakinKapi(0);
      setSecilenKapi(0);
      setSiteSakini(null);
      setSecilenler({
        ...secilenler,
        adsoyad: null,
        email: null,
        blok: null,
        kapino: null,
      });
    }
    if (Number(e.target.value) === 0 && secilenKapi !== 0) {
      setSecilenBlok({ ...secilenBlok, id: 0 });
      setSiteSakinKapi(0);
      setSecilenKapi(0);
      setSiteSakini(null);
      setSecilenler({
        ...secilenler,
        adsoyad: null,
        email: null,
        blok: null,
        kapino: null,
      });
    }
  };

  const kapiSorgula = (id) => {
    if (id !== 0) {
      props.kapilariGetir(
        sessionStorage.getItem("SiteID"),
        sessionStorage.getItem("SunucuID"),
        id
      );
    }
  };

  const kapiSec = (e) => {
    if (e.target.value !== 0 && e.target.value !== "0") {
      setSecilenKapi(e.target.value);
      setSecilenler({ ...secilenler, kapino: e.target.value });
      sakinSorgula(secilenBlok.id, e.target.value);
    }
  };
  const uyeAraFunc = (e) => {
    setUyeAra(e.target.value);
    setSiteSakinSecerek(true);
    setArananUyelerToggle(false);
  };

  if (sessionStorage.getItem("tokenKey")) {
    var filteredUye = sakinler.filter((sakins) => {
      return sakins.adsoyad.toLowerCase().indexOf(uyeAra.toLowerCase()) !== -1;
    });
  }

  const sakinSorgula = (blok, kapi) => {
    if (blok !== 0 && kapi !== 0) {
      props.siteSakiniGetir(
        sessionStorage.getItem("SiteID"),
        sessionStorage.getItem("SunucuID"),
        blok,
        kapi
      );
    }
  };

  const handleClickOpen = () => {
    if (sessionStorage.getItem("tokenKey")) {
      props.surecsill(props.isid, props.mesajId, gelenIsBilgileri.talepid);
    } else {
      props.mesajiSil(
        props.mesajId,
        Table[0].talepid,
        Table[0].uyeid,
        Table[0].siteid,
        Table[0].id,
        Table3[0].ad
      );
    }

    setOpen(props.dialogKapat);
  };
  const isiTamamla = () => {
    props.isiTamamla(
      Table[0].id,
      Table[0].talepid,
      Table[0].uyeid,
      Table[0].siteid,
      Table3[0].ad
    );
    setOpen(props.dialogKapat);
  };
  const isReddet = (event) => {
    props.isiReddet(props.isid, props.history);
    setOpen(props.dialogKapat);
  };

  const isKabulEt = () => {
    props.isiKabulEt(props.isid, props.history);
    setOpen(props.dialogKapat);
  };

  const isOlusturr = (e) => {
    if (secilenler.gorevid && secilenler.isinaciklamasi) {
      props.isOlustur(
        secilenler,
        personelSms,
        secilenPersonel,
        personeller,
        smsGonder,
        yuklenenDosyalar
      );
      setHataGoster(false);
      setOpen(props.dialogKapat);
    } else {
      setHataGoster(true);
    }
  };

  const isiGeriAld = (event) => {
    props.isiGeriAl(
      Table[0].id,
      Table[0].talepid,
      Table[0].uyeid,
      Table[0].siteid,
      Table3[0].ad
    );
    setOpen(props.dialogKapat);
  };

  const handleClose = () => {
    setOpen(props.dialogKapat);
  };
  const isDurumuSec = (e) => {
    setEminMisiniz(true);
    setGelenIsBilgileri({
      ...gelenIsBilgileri,
      simdikidurum: Number(e.target.value),
    });
  };
  const isiGuncelles = () => {
    props.isGuncels(
      props.isid,
      { ...gelenIsBilgileri },
      personeller,
      personelSms,
      metin
    );
    setMetin("");
  };

  return (
    <Dialog
      open={true}
      style={{}}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
    >
      {props.siteTanimReducer.fetching ? (
        <div className="load">
          <RingLoader size={100} color={"#D21113"} loading={true}></RingLoader>
        </div>
      ) : null}
      {props.loadingReducer.fetching ? (
        <div className="load">
          <RingLoader
            className="zindexli"
            size={100}
            color={"#648541"}
            loading={true}
          ></RingLoader>
        </div>
      ) : null}
      {hataGoster ? (
        <HataMesaji
          neMesaji={"bilgilendirme"}
          hata={
            !secilenler.gorevid
              ? "İşin Alanını Seçmediniz."
              : !secilenler.isinaciklamasi
              ? "İşin Açıklaması Boş Olamaz."
              : null
          }
          islem={"deneme"}
          neyi={"deneme2 neyi"}
          dialog={hataGoster}
          onClose={() => setHataGoster(false)}
        ></HataMesaji>
      ) : null}
      {eminMisiniz ? (
        <HataMesaji
          neMesaji={"bilgilendirme"}
          hata={`İşin durumunu ${
            isDurumlar.filter((durum) => {
              return durum.id === gelenIsBilgileri.simdikidurum;
            })[0].tanim
          } olarak değiştirmek istediğinize emin misiniz?`}
          islem={"deneme"}
          isid={props.isid}
          isBilgileri={gelenIsBilgileri}
          neyi={gelenIsBilgileri.secilidurum}
          neyle={gelenIsBilgileri.simdikidurum}
          personeller={personeller}
          personelSms={personelSms}
          metin={metin}
          dialog={eminMisiniz}
          onClose={() => setEminMisiniz(false)}
        ></HataMesaji>
      ) : null}
      {props.yonetici ? (
        <div className="df">
          {/* {yeniIs ? (
            <h5 className="kckbslik">Yeni İş Oluştur</h5>
          ) : (
            <h5 className="kckbslik">
              {gelenIsBilgileri && gelenIsBilgileri.isinaciklamasi}
            </h5>
          )} */}
          <div className="isOlusturForm">
            <div className="alt-kapsayici ikili-card">
              <div className="alt-ikili">
                <aside className="personelKapsayici">
                  <div className="ustksm">
                    <aside className="ustKisim">
                      <div className="isDurumu tekliCard">
                        {!yeniIs ? (
                          <aside className="ikili-item">
                            <p className="isBaslikOlstrm">İşin Durumu</p>
                            <select
                              value={
                                gelenIsBilgileri &&
                                gelenIsBilgileri.simdikidurum
                                  ? gelenIsBilgileri.simdikidurum
                                  : 0
                              }
                              onChange={isDurumuSec}
                            >
                              {isDurumlar &&
                                isDurumlar.map((data, i) => {
                                  return gelenIsBilgileri &&
                                    gelenIsBilgileri.simdikidurum ===
                                      data.id ? (
                                    <option value={data.id} key={i}>
                                      {data.tanim}
                                    </option>
                                  ) : (
                                    <option value={data.id} key={i}>
                                      {data.tanim}
                                    </option>
                                  );
                                })}
                            </select>
                          </aside>
                        ) : null}
                        <aside className="ikili-item">
                          <p
                            className={`isBaslikOlstrm ${
                              !secilenler.gorevid &&
                              gelenIsBilgileri &&
                              !gelenIsBilgileri.seciligorev
                                ? "zornluKirmizi"
                                : ""
                            }`}
                          >
                            İşin Alanı*
                          </p>
                          {yeniIs ? (
                            <select onChange={secileniBul} name={"gorevid"}>
                              <option value="">İş Alanı Seçiniz..</option>
                              {isAlaniTable &&
                                isAlaniTable.map((data, i) => {
                                  return (
                                    <option value={data.id} key={data.id}>
                                      {data.tanim}
                                    </option>
                                  );
                                })}
                            </select>
                          ) : (
                            <aside className="tekli-s">
                              {gelenIsBilgileri &&
                              gelenIsBilgileri.simdikigorev ? (
                                <select
                                  onChange={secileniBul}
                                  defaultValue={
                                    gelenIsBilgileri &&
                                    gelenIsBilgileri.simdikigorev
                                      ? gelenIsBilgileri.simdikigorev
                                      : ""
                                  }
                                  name={"gorevid"}
                                >
                                  {isAlaniTable &&
                                    isAlaniTable.map((data, i) => {
                                      return gelenIsBilgileri &&
                                        gelenIsBilgileri.simdikigorev ===
                                          data.id ? (
                                        <option value={data.id} key={data.id}>
                                          {gelenIsBilgileri &&
                                            gelenIsBilgileri.simdikigorev}
                                        </option>
                                      ) : (
                                        <option value={data.id} key={data.id}>
                                          {data.tanim}
                                        </option>
                                      );
                                    })}
                                </select>
                              ) : null}
                            </aside>
                          )}
                        </aside>
                        <aside className="ikili-item">
                          <p className="isBaslikOlstrm">İşin Türü</p>
                          {yeniIs ? (
                            <select
                              name={"int2"}
                              onChange={isinTuruChange}
                              value={Number(secilenler.int2)}
                            >
                              {isTurleri &&
                                isTurleri.map((data, i) => {
                                  return (
                                    <option key={data.id} value={data.id}>
                                      {data.tanim}
                                    </option>
                                  );
                                })}
                            </select>
                          ) : (
                            <select
                              name={"int2"}
                              onChange={isinTuruChange}
                              value={
                                gelenIsBilgileri && gelenIsBilgileri.simdikitur
                                  ? gelenIsBilgileri.simdikitur
                                  : 0
                              }
                            >
                              {isTurleri &&
                                isTurleri.map((data, i) => {
                                  return gelenIsBilgileri &&
                                    gelenIsBilgileri.simdikitur === data.id ? (
                                    <option key={data.id} value={data.id}>
                                      {data.tanim}
                                    </option>
                                  ) : (
                                    <option key={data.id} value={data.id}>
                                      {data.tanim}
                                    </option>
                                  );
                                })}
                            </select>
                          )}
                        </aside>
                        <div className="ikili-tek-wrap">
                          {yeniIs ? (
                            <aside className="ikili-item">
                              <p className="isBaslikOlstrm">Oluşturma Tarihi</p>
                              <b
                                name={"olusturmaTarihi"}
                                className="olstrmTarih"
                              >
                                {moment(secilenler.olusturmaTarihi).format(
                                  "LL"
                                )}
                              </b>
                            </aside>
                          ) : (
                            <aside className="ikili-item">
                              <p className="isBaslikOlstrm">Oluşturma Tarihi</p>
                              <b
                                name={"olusturmaTarihi"}
                                className="olstrmTarih"
                              >
                                {moment(
                                  gelenIsBilgileri &&
                                    gelenIsBilgileri.olusturmatarihi
                                ).format("LL")}
                              </b>
                            </aside>
                          )}
                          {yeniIs ? (
                            <aside className="ikili-item">
                              <p className="isBaslikOlstrm">Teslim Tarihi</p>
                              <input
                                name={"guncellemetarihi"}
                                className="teslmTarhInp"
                                min={new Date().toISOString().split("T")[0]}
                                type="date"
                                onChange={secileniBul}
                              ></input>
                            </aside>
                          ) : (
                            <aside className="ikili-item">
                              <p className="isBaslikOlstrm">Teslim Tarihi</p>
                              {/* <b name={"olusturmaTarihi"} className="olstrmTarih">
 {moment(
   gelenIsBilgileri && gelenIsBilgileri.teslimtarihi
        ).format("LL")}
            </b> */}
                              <input
                                name={"simdikiteslimtarihi"}
                                className="teslmTarhInp"
                                value={moment(
                                  gelenIsBilgileri &&
                                    gelenIsBilgileri.simdikiteslimtarihi
                                ).format("YYYY-MM-DD")}
                                type="date"
                                onChange={secileniBul}
                              ></input>
                            </aside>
                          )}
                        </div>
                      </div>
                    </aside>
                  </div>
                  <aside className="itemPersonel">
                    <aside className="gorselItem">
                      <p>Personel Bilgileri</p>
                      <img src={staticPersonelImg} alt="Personel"></img>
                    </aside>

                    {yeniIs ? (
                      <select
                        onChange={personelSec}
                        // value={secilenPersonel.kulid}
                        name={secilenPersonel && secilenPersonel.ad}
                      >
                        <option value={""}>Personel Seçiniz</option>
                        {personeller &&
                          personeller.map((data, i) => {
                            return isAlanlariSecilenGorev &&
                              data.isalanlari &&
                              data.isalanlari.indexOf(
                                isAlanlariSecilenGorev
                              ) !== -1 ? (
                              <option value={data.kulid} key={i}>
                                {data.ad}
                              </option>
                            ) : null;
                          })}
                      </select>
                    ) : (
                      <aside>
                        <select
                          onChange={personelSec}
                          value={
                            gelenIsBilgileri &&
                            gelenIsBilgileri.simdikipersonel !== null
                              ? gelenIsBilgileri.simdikipersonel
                              : ""
                          }
                          name={secilenPersonel && secilenPersonel.ad}
                        >
                          <option value={0}>Personel Seçiniz</option>
                          {personeller &&
                            personeller.map((data, i) => {
                              return isAlanlariSecilenGorev &&
                                data.isalanlari &&
                                data.isalanlari.indexOf(
                                  isAlanlariSecilenGorev
                                ) !== -1 ? (
                                <option key={i} value={data.kulid}>
                                  {data.ad}
                                </option>
                              ) : null;
                            })}
                        </select>
                      </aside>
                    )}

                    <aside className="inpcheckinfo">
                      <input
                        defaultValue={personelSms}
                        onChange={personelSmsGonders}
                        type="checkbox"
                      />
                      <p>
                        Personeli Sms ile bilgilendir. Personel seçimi
                        yapılmamışsa seçilen iş alanındaki tüm personeli
                        bilgilendir.
                      </p>
                    </aside>
                  </aside>
                  {pro ? (
                    <aside
                      className={`itemPersonel ${
                        gelenIsBilgileri && gelenIsBilgileri.adsoyad
                          ? ""
                          : "brd"
                      }`}
                    >
                      {yeniIs ? (
                        <aside className="itemPersonel">
                          <select onChange={secileniBul} name="string3">
                            <option>Site Seçiniz</option>
                            {JSON.parse(sessionStorage.getItem("siteler")).map(
                              (data, i) => {
                                return (
                                  <option key={data.ID}>{data.SiteAdi}</option>
                                );
                              }
                            )}
                          </select>
                        </aside>
                      ) : (
                        <div>
                          {gelenIsBilgileri && gelenIsBilgileri.siteadi ? (
                            <aside className="itemPersonel brd">
                              <aside className="gorselItem">
                                <p>Site Bilgileri</p>
                                <img src={staticbuildingImg} alt="Site"></img>
                              </aside>
                              {gelenIsBilgileri && gelenIsBilgileri.siteadi ? (
                                <aside className="bilgilerSecilen">
                                  <p>
                                    {gelenIsBilgileri &&
                                    gelenIsBilgileri.siteadi
                                      ? gelenIsBilgileri.siteadi
                                      : null}
                                  </p>
                                </aside>
                              ) : null}
                            </aside>
                          ) : null}
                        </div>
                      )}
                    </aside>
                  ) : null}
                  <div
                    className={`cardInsan ${
                      gelenIsBilgileri && gelenIsBilgileri.adsoyad ? "" : "brd"
                    }`}
                  >
                    {gelenIsBilgileri && gelenIsBilgileri.adsoyad ? (
                      <aside className="gorselItem">
                        <p>Site Sakini Bilgileri</p>
                        <img src={staticSiteSakiniImg} alt="Site Sakini"></img>
                      </aside>
                    ) : null}

                    {yeniIs ? (
                      <aside className="itemPersonel">
                        <aside>
                          {uyeAra === "" ? (
                            <aside className="siteItem">
                              <select
                                onChange={blokSec}
                                value={secilenBlok.id}
                                name={secilenBlok.tanim}
                              >
                                <option name={0} value={0}>
                                  Blok Seçiniz
                                </option>
                                {siteSakinBlok &&
                                  siteSakinBlok.map((data, i) => {
                                    return (
                                      <option
                                        key={i}
                                        value={data.id}
                                        name={data.tanim}
                                      >
                                        {data.tanim}
                                      </option>
                                    );
                                  })}
                              </select>
                              <select onChange={kapiSec} value={secilenKapi}>
                                <option value={0}>Kapı No Seçiniz</option>
                                {siteSakinKapi &&
                                  siteSakinKapi.map((data, i) => {
                                    return (
                                      <option key={data.kapino}>
                                        {data.kapino}
                                      </option>
                                    );
                                  })}
                              </select>
                            </aside>
                          ) : null}
                        </aside>

                        <aside className="uyeArawrap">
                          <i className="fa fa-search" aria-hidden="true"></i>
                          <input
                            value={uyeAra}
                            onChange={uyeAraFunc}
                            placeholder="Üye Ara"
                          ></input>
                        </aside>

                        {uyeAra !== "" && arananUyelerToggle === false ? (
                          <div className="kisitliHeight">
                            {filteredUye &&
                              filteredUye.map((data, i) => {
                                return (
                                  <aside
                                    onClick={() => {
                                      props.siteSakiniAraGetir(
                                        sessionStorage.getItem("SiteID"),
                                        sessionStorage.getItem("SunucuID"),
                                        data.uyeid
                                      );
                                      setSecilenler({
                                        ...secilenler,
                                        uyeid: data.uyeid ? data.uyeid : null,
                                        tasinmazid: data.id ? data.id : null,
                                        websifre: data.websifre
                                          ? data.websifre
                                          : null,
                                      });
                                      setArananUyelerToggle(true);
                                    }}
                                    key={i}
                                    className="bilgilerSecilen"
                                  >
                                    {uyeAra !== "" ? (
                                      <aside className="tekItem">
                                        {data.adsoyad ? (
                                          <p>{data.adsoyad}</p>
                                        ) : (
                                          <p>Ad Soyad Bulunamadı</p>
                                        )}
                                        {data.blokid || data.kapino ? (
                                          <p>
                                            Blok/Kapı: {data.blokid} /{" "}
                                            {data.kapino}
                                          </p>
                                        ) : null}
                                      </aside>
                                    ) : null}
                                  </aside>
                                );
                              })}
                          </div>
                        ) : null}
                        {siteSakini &&
                          siteSakini.map((data, i) => {
                            return (
                              <aside key={i} className="bilgilerSecilen">
                                <aside>
                                  {data.adsoyad ? (
                                    <p>Ad Soyad: {data.adsoyad}</p>
                                  ) : null}
                                  {data.tanim || data.kapino ? (
                                    <p>
                                      Blok/Kapı : {data.tanim} / {data.kapino}
                                    </p>
                                  ) : null}
                                  {data.eposta1 ? (
                                    <p>E-Posta: {data.eposta1}</p>
                                  ) : null}
                                  {data.telefon1 ? (
                                    <p>Telefon Numarası : {data.telefon1}</p>
                                  ) : null}
                                </aside>
                              </aside>
                            );
                          })}
                      </aside>
                    ) : (
                      <aside
                        className={`bilgilerSecilen ${
                          gelenIsBilgileri && gelenIsBilgileri.adsoyad
                            ? ""
                            : "brd"
                        }`}
                      >
                        <aside>
                          {gelenIsBilgileri && gelenIsBilgileri.adsoyad ? (
                            <p>
                              {gelenIsBilgileri && gelenIsBilgileri.adsoyad}
                            </p>
                          ) : null}
                          {(gelenIsBilgileri && gelenIsBilgileri.kapino) ||
                          (gelenIsBilgileri && gelenIsBilgileri.blok) ? (
                            <p>
                              {gelenIsBilgileri && gelenIsBilgileri.blok} /{" "}
                              {gelenIsBilgileri && gelenIsBilgileri.kapino}
                            </p>
                          ) : null}
                          {gelenIsBilgileri && gelenIsBilgileri.eposta ? (
                            <p>
                              {" "}
                              {gelenIsBilgileri && gelenIsBilgileri.eposta}
                            </p>
                          ) : null}
                          {gelenIsBilgileri && gelenIsBilgileri.telefon ? (
                            <p>
                              {" "}
                              {gelenIsBilgileri && gelenIsBilgileri.telefon}
                            </p>
                          ) : null}{" "}
                          {gelenIsBilgileri && gelenIsBilgileri.adsoyad ? (
                            <aside className="inpilepyanyana">
                              <input
                                onChange={secileniBul}
                                checked={
                                  gelenIsBilgileri &&
                                  gelenIsBilgileri.pgorsun === 1
                                    ? true
                                    : false
                                }
                                name={"int3"}
                                type="checkbox"
                              />
                              <b>
                                Site Sakininin Telefon/E-Posta bilgilerini
                                personel görebilir.
                              </b>
                            </aside>
                          ) : null}
                        </aside>
                      </aside>
                    )}
                    {siteSakini && siteSakini.length > 0 ? (
                      <div className="npsoldanal">
                        {secilenler.uyeid ? (
                          <aside>
                            <aside className="inpcheckinfo kckinpcheck">
                              <input
                                defaultChecked={true}
                                defaultValue={smsGonder}
                                onChange={siteSakinSmsFunc}
                                type="checkbox"
                              />
                              <p>Site Sakinini Sms ile bilgilendir.</p>
                            </aside>
                            <aside className="inpcheckinfo kckinpcheck">
                              <input
                                disabled={true}
                                checked={true}
                                value={mailGonder}
                                onChange={siteSakinMailFunc}
                                type="checkbox"
                              />
                              <p>Site Sakinini E-Posta ile bilgilendir.</p>
                            </aside>
                          </aside>
                        ) : (
                          <aside>
                            <aside className="inpcheckinfo kckinpcheck">
                              <input
                                defaultValue={smsGonder}
                                onChange={siteSakinSmsFunc}
                                type="checkbox"
                              />
                              <p>Site Sakinini Sms ile bilgilendir.</p>
                            </aside>
                            <aside className="inpcheckinfo kckinpcheck">
                              <input
                                disabled={true}
                                value={mailGonder}
                                onChange={siteSakinMailFunc}
                                type="checkbox"
                              />
                              <p>Site Sakinini E-Posta ile bilgilendir.</p>
                            </aside>
                          </aside>
                        )}
                        {siteSakini !== null ? (
                          <aside className="inpcheckinfo kckinpcheck">
                            <input
                              onChange={secileniBul}
                              value={secilenler.int3}
                              name={"int3"}
                              type="checkbox"
                            />
                            <p>
                              Site Sakininin ({secilenler.adsoyad})
                              Telefon/E-Posta bilgilerini
                              {secilenPersonel && secilenPersonel.ad
                                ? ` Personel (${secilenPersonel.ad}) `
                                : " Personel "}
                              görebilir.
                            </p>
                          </aside>
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                </aside>
              </div>

              <aside className="mesajKapsayici">
                <p className="mobildegor">İş Hikayesi</p>
                {!yeniIs ? (
                  <div className="tekSatirH">
                    <aside className={`tekstr algn`}>
                      <p className="isaciklamabslk">
                        {gelenIsBilgileri && ReactHtmlParser(gelenIsBilgileri.isinaciklamasi)}
                      </p>
                      {gelenIsBilgileri &&
                      gelenIsBilgileri.dosyalar &&
                      !yeniIs ? (
                        <aside className="kapsayicidosyagstr">
                          {gelenIsBilgileri.dosyalar &&
                            gelenIsBilgileri.dosyalar.map((v, i) => {
                              return (
                                <aside key={i}>
                                  <aside className="kckdosyagstrwrp">
                                    {/* <span className="dosyaSils">
                         <i
                           onClick={() => {
                             props.dsyaSil(
                               v.id,
                               v.dosya,
                               v.isId,
                               yuklenenDosyalar
                             );
                           }}
                           className="fa fa-window-close"
                           aria-hidden="true"
                         ></i>
                       </span> */}
                                    {v.dosya.substr(v.dosya.length - 4) ===
                                      ".png" ||
                                    v.dosya.substr(v.dosya.length - 4) ===
                                      ".jpg" ||
                                    v.dosya.substr(v.dosya.length - 5) ===
                                      ".jpeg" ||
                                    v.dosya.substr(v.dosya.length - 5) ===
                                      ".tiff" ? (
                                      <Link
                                        to={v.dosya.replace(
                                          "arsiv/",
                                          "arsiv/k"
                                        )}
                                        target="_blank"
                                      >
                                        <img
                                          className="kucukDosyaGstr"
                                          src={v.dosya.replace(
                                            "arsiv/",
                                            "arsiv/k"
                                          )}
                                          alt={v.dosya.replace(
                                            "arsiv/",
                                            "arsiv/k"
                                          )}
                                        />
                                      </Link>
                                    ) : (
                                      <aside className="txtGibiDosya">
                                        <Link
                                          to={`//${yol}${sessionStorage.getItem(
                                            "SiteID"
                                          )}/arsiv/${v.dosya}`}
                                          target="_blank"
                                        >
                                          <button className="dosyaIses">
                                            {v.dosya.substr(v.dosya.length - 4)}{" "}
                                            Uzantılı Dosyayı Aç
                                          </button>
                                        </Link>
                                      </aside>
                                    )}
                                  </aside>
                                </aside>
                              );
                            })}
                        </aside>
                      ) : null}
                    </aside>
                  </div>
                ) : null}
                {yeniIs ? (
                  <div className="tekSatirH">
                    <aside className="tekstr algn"></aside>
                  </div>
                ) : null}
                <aside
                  className={`${
                    yeniIs ? "mesajItem" : "mesajItem exMesajItem"
                  }`}
                >
                  {
                    yeniIs ? (
                      <aside className="isolustrflx">
                        <div className="minheight">
                          <aside className="kapsayicidosyagstr">
                            {yuklenenDosyalar &&
                              yuklenenDosyalar.map((v, i) => {
                                return (
                                  <aside key={i}>
                                    <aside className="kckdosyagstrwrp">
                                      <span className="dosyaSils">
                                        <i
                                          onClick={() => {
                                            props.dsyaSil(
                                              v.id,
                                              v.dosya,
                                              v.isId,
                                              yuklenenDosyalar
                                            );
                                          }}
                                          className="fa fa-window-close"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                      {v.dosya.substr(v.dosya.length - 4) ===
                                        ".png" ||
                                      v.dosya.substr(v.dosya.length - 4) ===
                                        ".jpg" ||
                                      v.dosya.substr(v.dosya.length - 5) ===
                                        ".jpeg" ||
                                      v.dosya.substr(v.dosya.length - 5) ===
                                        ".tiff" ? (
                                        <Link
                                          to={`${
                                            !pro
                                              ? `//${yol}${sessionStorage.getItem(
                                                  "SiteID"
                                                )}/arsiv/k${v.dosya}`
                                              : `//${yol}${
                                                  10000000 +
                                                  parseInt(
                                                    sessionStorage.getItem(
                                                      "proid"
                                                    )
                                                  )
                                                }/arsiv/k${v.dosya}`
                                          }`}
                                          target="_blank"
                                        >
                                          <img
                                            className="kucukDosyaGstr"
                                            src={`${
                                              !pro
                                                ? `//${yol}${sessionStorage.getItem(
                                                    "SiteID"
                                                  )}/arsiv/k${v.dosya}`
                                                : `//${yol}${
                                                    10000000 +
                                                    parseInt(
                                                      sessionStorage.getItem(
                                                        "proid"
                                                      )
                                                    )
                                                  }/arsiv/k${v.dosya}`
                                            }`}
                                            alt={v.dosya}
                                          />
                                        </Link>
                                      ) : (
                                        <aside className="txtGibiDosya">
                                          <Link
                                            to={`${
                                              !pro
                                                ? `//${yol}${sessionStorage.getItem(
                                                    "SiteID"
                                                  )}/arsiv/k${v.dosya}`
                                                : `//${yol}${
                                                    10000000 +
                                                    parseInt(
                                                      sessionStorage.getItem(
                                                        "proid"
                                                      )
                                                    )
                                                  }/arsiv/k${v.dosya}`
                                            }`}
                                            target="_blank"
                                          >
                                            <button className="dosyaIses">
                                              {v.dosya.substr(
                                                v.dosya.length - 4
                                              )}{" "}
                                              Uzantılı Dosyayı Aç
                                            </button>
                                          </Link>
                                        </aside>
                                      )}
                                    </aside>
                                  </aside>
                                );
                              })}
                          </aside>
                        </div>
                        <div className="btnKaps">
                          <div className="upload-btn-wrapper isOlusturDosyaBtn">
                            <aside className="olstrTxt">
                              {yuklenenDosyalar &&
                              yuklenenDosyalar.length < 3 ? (
                                <aside className="crsrpnt">
                                  <aside className="gncltxtbtnkps">
                                    <button
                                      className={`${
                                        yeniIs
                                          ? "dsyagndrbtn yn"
                                          : "dsyagndrbtn"
                                      }`}
                                    >
                                      <i
                                        className={`${
                                          yeniIs
                                            ? "fa fa-camera saga"
                                            : "fa fa-camera"
                                        }`}
                                        aria-hidden="true"
                                      />
                                      {`${yeniIs ? "Dosya Yükle" : ""}`}
                                    </button>
                                    <input
                                      onChange={dosyaYukleFunc}
                                      type="file"
                                      id="myFile"
                                      name="myfile"
                                    />
                                  </aside>
                                </aside>
                              ) : (
                                <aside>
                                  <p>En Fazla 3 Dosya Ekleyebilirsiniz.</p>
                                </aside>
                              )}

                              <textarea
                                onChange={secileniBul}
                                name={"isinaciklamasi"}
                                value={secilenler.isinaciklamasi}
                                className="msj"
                                placeholder="İşin Açıklamasını Giriniz."
                              ></textarea>
                            </aside>
                          </div>
                        </div>
                      </aside>
                    ) : (
                      <aside className="isolustrflx">
                        <div className="minheight">
                          {surec &&
                            surec.map((data, i) =>
                              data.yoneticiid ? (
                                <MesajGiden
                                  id={data.id}
                                  key={i}
                                  durumAdi={
                                    isDurumlar &&
                                    isDurumlar.filter((durum) => {
                                      return durum.id === data.durumu;
                                    })
                                  }
                                  icerik={data}
                                ></MesajGiden>
                              ) : (
                                <MesajGelen
                                  id={data.id}
                                  key={i}
                                  durumAdi={
                                    isDurumlar &&
                                    isDurumlar.filter((durum) => {
                                      return durum.id === data.durumu;
                                    })
                                  }
                                  icerik={data}
                                ></MesajGelen>
                              )
                            )}
                        </div>
                        <div className="btnKaps">
                          <div className="upload-btn-wrapper isOlusturDosyaBtn">
                            <aside className="olstrTxt">
                              {yuklenenDosyalar &&
                              yuklenenDosyalar.length < 3 ? (
                                <aside className="crsrpnt">
                                  {gelenIsBilgileri &&
                                  gelenIsBilgileri.simdikidurum < 5 ? (
                                    <aside className="gncltxtbtnkps">
                                      <button
                                        className={`${
                                          yeniIs
                                            ? "dsyagndrbtn yn"
                                            : "dsyagndrbtn yn"
                                        }`}
                                      >
                                        <i
                                          className={`${
                                            yeniIs
                                              ? "fa fa-camera saga"
                                              : "fa fa-camera"
                                          }`}
                                          aria-hidden="true"
                                        ></i>
                                        {` Dosya Yükle`}
                                      </button>
                                      <input
                                        onChange={dosyaYukleFunc}
                                        type="file"
                                        id="myFile"
                                        name="myfile"
                                      />
                                    </aside>
                                  ) : null}
                                </aside>
                              ) : (
                                <aside>
                                  <p>En Fazla 3 Dosya Ekleyebilirsiniz.</p>
                                </aside>
                              )}
                              {gelenIsBilgileri &&
                              gelenIsBilgileri.simdikidurum < 5 ? (
                                <textarea
                                  className="msj"
                                  value={metin}
                                  onChange={(e) => setMetin(e.target.value)}
                                  placeholder="Güncelleme Girin."
                                ></textarea>
                              ) : (
                                <p className="tlptmm">
                                  Talep tamamlandığı için içerik
                                  oluşturamazsınız.
                                </p>
                              )}
                            </aside>
                          </div>
                        </div>
                      </aside>
                    )
                    //<MesajGiden></MesajGiden>
                  }
                </aside>
                <div className="uclsn">
                  <DialogContent className="muideneme">
                    <DialogActions>
                      {props.olay === "silmek" ? (
                        <Button
                          onClick={handleClickOpen}
                          className="btn-onayAlert"
                        >
                          Mesajı Sil
                        </Button>
                      ) : null}
                      {props.olay === "tamamlamak" ? (
                        <Button onClick={isiTamamla} className="btn-onayAlert">
                          Onayla
                        </Button>
                      ) : null}

                      {props.olay === "kabul etmek" ? (
                        <Button onClick={isKabulEt} className="btn-onayAlert">
                          Kabul Et
                        </Button>
                      ) : null}

                      {props.olay === "geri almak" ? (
                        <Button onClick={isiGeriAld} className="btn-onayAlert">
                          Kabul Et
                        </Button>
                      ) : null}

                      {props.olay === "reddetmek" ? (
                        <Button onClick={isReddet} className="btn-onayAlert">
                          Onayla
                        </Button>
                      ) : null}
                      {props.olay === "güncellemek" ? (
                        <Button
                          onClick={isiGuncelles}
                          className="btn-onayAlert btn-kck"
                        >
                          Güncelle
                        </Button>
                      ) : null}
                      {props.olay === "oluşturmak" ? (
                        <Button
                          onClick={isOlusturr}
                          className="btn-onayAlert olusturBtn"
                        >
                          Kaydet
                        </Button>
                      ) : null}
                      {props.olay === "oluşturmak" ? (
                        <Button
                          onClick={handleClose}
                          className="btn-redAlert olusturBtn"
                          color="primary"
                        >
                          İptal
                        </Button>
                      ) : (
                        <Button
                          onClick={handleClose}
                          className="btn-redAlert btn-kck"
                          color="primary"
                        >
                          İptal
                        </Button>
                      )}
                    </DialogActions>
                  </DialogContent>
                </div>
              </aside>
            </div>
          </div>
        </div>
      ) : (
        <DialogContent className="kucukSoruSor" id="alert-dialog-title">
          {`${props.islem} ${props.olay} istediğinize emin misiniz?`}
          <DialogActions className="kucukbtns">
            {props.olay === "silmek" ? (
              <Button onClick={handleClickOpen} className="btn-onayAlert">
                Mesajı Sil
              </Button>
            ) : null}
            {props.olay === "tamamlamak" ? (
              <Button onClick={isiTamamla} className="btn-onayAlert">
                Onayla
              </Button>
            ) : null}

            {props.olay === "kabul etmek" ? (
              <Button onClick={isKabulEt} className="btn-onayAlert">
                Kabul Et
              </Button>
            ) : null}

            {props.olay === "geri almak" ? (
              <Button onClick={isiGeriAld} className="btn-onayAlert">
                Kabul Et
              </Button>
            ) : null}

            {props.olay === "reddetmek" ? (
              <Button onClick={isReddet} className="btn-onayAlert">
                Onayla
              </Button>
            ) : null}
            {props.olay === "güncellemek" ? (
              <Button onClick={isiGuncelles} className="btn-onayAlert">
                Güncelle
              </Button>
            ) : null}
            {props.olay === "oluşturmak" ? (
              <Button onClick={isOlusturr} className="btn-onayAlert olusturBtn">
                Onayla
              </Button>
            ) : null}

            {props.olay === "oluşturmak" ? (
              <Button
                onClick={handleClose}
                className="btn-redAlert olusturBtn"
                color="primary"
              >
                İptal
              </Button>
            ) : (
              <Button
                onClick={handleClose}
                className="btn-redAlert"
                color="primary"
              >
                İptal
              </Button>
            )}
          </DialogActions>
        </DialogContent>
      )}
    </Dialog>
  );
};
const mapStateToProps = ({
  isReducer,
  silMesajReducer,
  isTamamlaReducer,
  kabulEtReducer,
  isGeriAlReducer,
  siteTanimReducer,
  reddetReducer,
  loadingReducer,
  siteSakinleriReducer,
  isOlusturReducer,
  yoneticiReducer,
  isGuncelleReducer,
}) => {
  return {
    silMesajReducer,
    kabulEtReducer,
    reddetReducer,
    isGeriAlReducer,
    isTamamlaReducer,
    isReducer,
    loadingReducer,
    siteSakinleriReducer,
    isOlusturReducer,
    isGuncelleReducer,
    siteTanimReducer,
    yoneticiReducer,
  };
};
function mapDispatchToProps(dispatch) {
  return {
    mesajiSil: bindActionCreators(mesajiSil, dispatch),
    isiTamamla: bindActionCreators(isiTamamla, dispatch),
    isiKabulEt: bindActionCreators(isiKabulEt, dispatch),
    isiReddet: bindActionCreators(isiReddet, dispatch),
    isiGeriAl: bindActionCreators(isiGeriAl, dispatch),
    bekleyenleriGetir: bindActionCreators(bekleyenleriGetir, dispatch),
    isiGetir: bindActionCreators(isiGetir, dispatch),
    isOlustur: bindActionCreators(isOlustur, dispatch),
    siteSakiniGetir: bindActionCreators(siteSakiniGetir, dispatch),
    kapilariGetir: bindActionCreators(kapilariGetir, dispatch),
    dosyaKaydet: bindActionCreators(dosyaKaydet, dispatch),
    proDosyaKaydet: bindActionCreators(proDosyaKaydet, dispatch),
    siteSakiniAraGetir: bindActionCreators(siteSakiniAraGetir, dispatch),
    dsyaSil: bindActionCreators(dsyaSil, dispatch),
    yoneticiDevamEdenleriGetir: bindActionCreators(
      yoneticiDevamEdenleriGetir,
      dispatch
    ),
    yoneticiTamamlananlariGetir: bindActionCreators(
      yoneticiTamamlananlariGetir,
      dispatch
    ),
    siteTanimlariniGetir: bindActionCreators(siteTanimlariniGetir, dispatch),
    personelGorsunCheck,
    isGuncels: bindActionCreators(isGuncels, dispatch),
    surecsill: bindActionCreators(surecsill, dispatch),
    dosyaKaydets: bindActionCreators(dosyaKaydets, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DialogScreen));
