import axios from "axios";
import sayilariGoster from "./bekleyenAction";
import { getJson } from "./yoneticiAction";
import { sorguYolBaslangic } from "./isGuncelleActions";
export const FETCH_TAMAM_PENDING = "FETCH_TAMAM_PENDING";
export const FETCH_TAMAM_FULFILLED = "FETCH_TAMAM_FULFILLED";
export const FETCH_TAMAM_REJECTED = "FETCH_TAMAM_REJECTED";

var currentToken = sessionStorage.getItem("token");

export const tamamlananCagir = () => {
  return {
    type: FETCH_TAMAM_PENDING,
    fetching: true,
  };
};

export const tamamlananGelsin = (tamamlananlar) => {
  return {
    type: FETCH_TAMAM_FULFILLED,
    payload: tamamlananlar,
    fetching: false,
  };
};
export const tamamlananHata = (error) => {
  return {
    type: FETCH_TAMAM_REJECTED,
    payload: error,
    fetching: false,
  };
};

export const tamamlananlariGetir = () => {
  return function (dispatch) {
    dispatch(tamamlananCagir());
    axios
      .get("/yc/sorgu?sorguid=10003", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + currentToken,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          const tamamlananlar = response.data;
          const sayilarT = response.data.Table1[0];
          dispatch(tamamlananGelsin(tamamlananlar));
          //
          dispatch(sayilariGoster(sayilarT));
          //
        } else {
          window.location.href = "/index.html";
          sessionStorage.clear();
        }
      })
      .catch((error) => {
        dispatch(tamamlananHata(error.message));
        window.location.href = "/index.html";
        sessionStorage.clear();
      });
  };
};

export const yoneticiTamamlananlariGetir = () => {
  return (dispatch) => {
    dispatch(tamamlananCagir());
    getJson(
      `${sorguYolBaslangic}istakibi/sorgu`,
      `&sorguid=1005${
        sessionStorage.getItem("proid")
          ? `&proid=${sessionStorage.getItem("proid")}`
          : ""
      }`,
      (dt) => {
        dispatch({
          type: FETCH_TAMAM_FULFILLED,
          fetching: false,
          payload: dt.data.Table,
        });
      }
    );
  };
};
