import {
  FETCH_ISDURUMU_PENDING,
  FETCH_ISDURUMU_FULLFILLED,
  FETCH_ISDURUMUBILGILERI_FULLFILLED,
} from "../actions/isGuncelleActions";

const initialState = {
  bilgiler: {},
  error: null,
  durumlar: [],
  done: false,
  fetching: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ISDURUMU_PENDING:
      return { fetching: true, error: null, done: false };
    case FETCH_ISDURUMUBILGILERI_FULLFILLED:
      return {
        ...state,
        bilgiler: action.isBilgileri,
        done: true,
        error: null,
        fetching: false,
      };
    case FETCH_ISDURUMU_FULLFILLED:
      return {
        ...state,
        durumlar: action.durumlar,
        done: true,
        error: null,
        fetching: false,
      };

    default:
      return state;
  }
};
