import axios from "axios";
import sayilariGoster, {
  yoneticiBekleyenleriGetir,
  sayilariCagir,
} from "../actions/bekleyenAction";
import { API_YC_BASE } from "../../config/API_BASE";
import { guncellenecekIsiGetir } from "./isGuncelleActions";
import ErrorFoundPage from "../../components/pages/ErrorFoundPage";
export const FETCH_YONETICI_PENDING = "FETCH_YONETICI_PENDING";
export const FETCH_YONETICI_FULFILLED = "FETCH_YONETICI_FULFILLED";
export const FETCH_YONETICI_REJECTED = "FETCH_YONETICI_REJECTED";

export const FETCH_LOADING_PENDING = "FETCH_LOADING_PENDING";
export const FETCH_LOADING_FULFILLED = "FETCH_LOADING_FULFILLED";
export const FETCH_LOADING_REJECTED = "FETCH_LOADING_REJECTED";

export const FETCH_KAPILAR_FULFILLED = "FETCH_KAPILAR_FULFILLED";
export const FETCH_SITESAKINI_FULFILLED = "FETCH_SITESAKINI_FULFILLED";

export const FETCH_SITEBILGI_PENDING = "FETCH_SITEBILGI_PENDING";
export const FETCH_SITEBILGI_FULFILLED = "FETCH_SITEBILGI_FULFILLED";
export const FETCH_SITEBILGI_REJECTED = "FETCH_SITEBILGI_REJECTED";

export const FETCH_SITESAKINLERI_PENDING = "FETCH_SITESAKINLERI_PENDING";
export const FETCH_SITESAKINLERI_FULFILLED = "FETCH_SITESAKINLERI_FULFILLED";
export const FETCH_SITESAKINLERI_REJECTED = "FETCH_SITESAKINLERI_REJECTED";

export const FETCH_SITETANIM_PENDING = "FETCH_SITETANIM_PENDING";
export const FETCH_SITETANIM_FULFILLED = "FETCH_SITETANIM_FULFILLED";
export const FETCH_SITETANIM_REJECTED = "FETCH_SITETANIM_REJECTED";

export const FETCH_ISOLUSTUR_PENDING = "FETCH_ISOLUSTUR_PENDING";
export const FETCH_ISOLUSTUR_FULFILLED = "FETCH_ISOLUSTUR_FULFILLED";
export const FETCH_ISOLUSTUR_REJECTED = "FETCH_ISOLUSTUR_REJECTED";

export const FETCH_YKULLANICI_FULFILLED = "FETCH_YKULLANICI_FULFILLED";
export const FETCH_YKULLANICI_PENDING = "FETCH_YKULLANICI_PENDING";
export const FETCH_YKULLANICI_REJECTED = "FETCH_YKULLANICI_REJECTED";

export const FETCH_POST_FULFILLED = "FETCH_POST_FULFILLED";
export const FETCH_DOSYASIL_FULFILLED = "FETCH_DOSYASIL_FULFILLED";
export const FETCH_DOSYALAR_FULFILLED = "FETCH_DOSYALAR_FULFILLED";
export const FETCH_POSTJSON_FULLFILLED = "FETCH_POSTJSON_FULLFILLED";
export const HATA_GOSTER = "HATA_GOSTER";

var currentToken = sessionStorage.getItem("tokenKey");

export const loadingTrue = () => {
  return {
    type: FETCH_LOADING_PENDING,
    fetching: true,
  };
};
export const loadingFalse = () => {
  return {
    type: FETCH_LOADING_FULFILLED,
    fetching: false,
  };
};

export const yoneticiSorguBekle = () => {
  return {
    type: FETCH_YONETICI_PENDING,
    fetching: true,
  };
};
export const yoneticiSorguCalis = (res) => {
  return {
    type: FETCH_YONETICI_FULFILLED,
    fetching: false,
    payload: res,
  };
};
export const yoneticiSorguHata = (error) => {
  return {
    type: FETCH_YONETICI_REJECTED,
    fetching: false,
    payload: error,
  };
};

export const sitebilgileriBekle = () => {
  return {
    type: FETCH_SITEBILGI_PENDING,
    fetching: true,
  };
};
export const sitebilgileriok = (res) => {
  return {
    type: FETCH_SITEBILGI_FULFILLED,
    fetching: false,
    payload: res,
  };
};

export const sitebilgileriHata = (error) => {
  return {
    type: FETCH_SITEBILGI_REJECTED,
    fetching: false,
    payload: error,
  };
};

export const siteSakinleriBekle = () => {
  return {
    type: FETCH_SITESAKINLERI_PENDING,
    fetching: true,
  };
};
export const siteSakinleriOk = (res) => {
  return {
    type: FETCH_SITESAKINLERI_FULFILLED,
    fetching: false,
    payload: res,
  };
};

export const siteSakinOk = (res) => {
  return {
    type: FETCH_SITESAKINI_FULFILLED,
    fetching: false,
    payload: res,
  };
};
export const kapilarOk = (res) => {
  return {
    type: FETCH_KAPILAR_FULFILLED,
    fetching: false,
    payload: res,
  };
};

export const siteSakinleriHata = (error) => {
  return {
    type: FETCH_SITESAKINLERI_REJECTED,
    fetching: false,
    payload: error,
  };
};

export const siteTanimlariBekle = () => {
  return {
    type: FETCH_SITETANIM_PENDING,
    fetching: true,
  };
};

export const ykullaniciBigileriOk = (res) => {
  return {
    type: FETCH_YKULLANICI_FULFILLED,
    fetching: false,
    payload: res,
  };
};
export const ykullaniciBigileriHata = (error) => {
  return {
    type: FETCH_YKULLANICI_REJECTED,
    fetching: false,
    payload: error,
  };
};
export const yKulliniciBilgileriGetir = () => {
  return {
    type: FETCH_YKULLANICI_PENDING,
    fetching: true,
    done: false,
  };
};
export const siteTanimlariOk = (res) => {
  return {
    type: FETCH_SITETANIM_FULFILLED,
    fetching: false,
    payload: res,
  };
};
export const siteTanimlariHata = (error) => {
  return {
    type: FETCH_SITETANIM_REJECTED,
    fetching: false,
    payload: error,
  };
};

export const isOlusturBekle = () => {
  return {
    type: FETCH_ISOLUSTUR_PENDING,
    fetching: true,
  };
};

export const isOlusturOk = (res) => {
  return {
    type: FETCH_ISOLUSTUR_FULFILLED,
    fetching: false,
    payload: res,
  };
};
export const isOlusturHata = (error) => {
  return {
    type: FETCH_ISOLUSTUR_REJECTED,
    fetching: false,
    payload: error,
  };
};
export const hataDon = (error) => {
  return {
    type: HATA_GOSTER,
    fetching: false,
    payload: error,
  };
};

export const yoneticiSorgula = (telNo, setButtonGizle) => {
  return function (dispatch) {
    dispatch(yoneticiSorguBekle());
    axios
      .get(
        `https://yonetici1.yonetimcell.com/account/telnoilesitegetir?telNo=${telNo}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        dispatch(yoneticiSorguCalis(response.data));
        // dispatch(kontrol(telNo));
        setCookie("YCTelefon", telNo, 30);
        setButtonGizle(1);
        sessionStorage.setItem(
          "siteler",
          JSON.stringify(response.data[0].Siteler)
        );
      })
      .catch((err) => {
        sessionStorage.clear();
        dispatch(hataDon("Girdiğiniz Bilgiler Hatalı!"));
      });
  };
};

export function setCookie(name, value, days) {
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    var expires = "; expires=" + date.toGMTString();
  } else var expires = "";
  document.cookie = name + "=" + value + expires + "; path=/";
}

export function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) {
      return c.substring(nameEQ.length, c.length);
    }
  }
  return null;
}

export const kontrol = (
  userName,
  password,
  smskodu,
  kodid,
  beniHatirla,
  setButtonGizle,
  setSmsModal
) => {
  let yckodid = getCookie("YCkodid");
  let yckod = getCookie("YCkod");

  var telefon1 = "" + getCookie("YCTelefon") + "";

  if (telefon1.length == 10) {
    var telefon2 = userName.slice(0, 10);
    if (telefon1 != telefon2) {
      yckodid = "";
      yckod = "";
    }
  }

  return (dispatch) => {
    axios
      .get(
        `https://yonetici1.yonetimcell.com/account/girissmskodus?telNo=${userName}&yckodid=${
          yckodid ? yckodid : ""
        }&s=${password}`
      )
      .then((res) => {
        if (res.data == 1) {
          //sms kod sorma
          setSmsModal(true);
          dispatch({ type: "KODSORMA_FULFILLED" });
          dispatch({ type: "KODID_FULFILLED", payload: yckodid });
          dispatch(
            yoneticiGirisYap(
              userName,
              password,
              smskodu,
              yckodid,
              beniHatirla,
              setButtonGizle
            )
          );
        } else if (res.data == -1) {
          setSmsModal(false);
          dispatch(hataDon("Girdiğiniz Bilgiler Hatalı!"));
          setButtonGizle(2);
        } else {
          setSmsModal(true);
          dispatch({ type: "KODSOR_FULFILLED" });
          dispatch({ type: "KODID_FULFILLED", payload: res.data });
          //sms kod sor
        }
      })
      .catch((err) => {
        sessionStorage.clear();
        // window.location.href = "/index.html";
      });
  };
};

export const yoneticiGirisYap = (
  userName,
  password,
  smskodu,
  kodid,
  beniHatirla,
  setButtonGizle
) => {
  return function (dispatch) {
    axios
      .post(
        `https://yonetici1.yonetimcell.com/token`,
        `grant_type=password&userName=${userName}&password=${password}&smskodu=${smskodu}&kodid=${kodid}`,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((result) => {
        sessionStorage.setItem("tokenKey", result.data.access_token);
        sessionStorage.setItem("userName", userName);
        dispatch(girisLog());
        if (beniHatirla) {
          setCookie("YCkod", smskodu, 5);
          setCookie("YCkodid", kodid, 5);
          dispatch({ type: "KODSORMA_FULFILLED" });
          dispatch({ type: "KODID_FULFILLED", payload: kodid });
        }
      })
      .catch((error) => {
        dispatch(yoneticiSorguHata(error.message));
        sessionStorage.clear();
        setCookie("YCkod", "", 5);
        setCookie("YCkodid", "", 5);
        setButtonGizle(2);
        dispatch(hataDon("Girdiğiniz Bilgiler Hatalı!"));

        //window.location.href = "/index.html";
      });
  };
};

export const girisLog = (token) => {
  var currentToken = sessionStorage.getItem("tokenKey");
  return function (dispatch) {
    axios
      .post(
        `https://istakibi.yonetimcell.com/account/girislog`,
        {
          kulid: sessionStorage.getItem("Kullanici"),
          siteid: sessionStorage.getItem("SiteID"),
          proid: sessionStorage.getItem("proid")
            ? sessionStorage.getItem("proid")
            : null,
          sunucuid: sessionStorage.getItem("SunucuID"),
          kultipi: "yonetici",
          token: currentToken,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + currentToken,
          },
        }
      )
      .then((res) => {
        if (sessionStorage.getItem("tokenKey")) {
          window.location.href = "/index.html";
        }
      })
      .catch((error) => {
        dispatch(yoneticiSorguHata(error.message));
        sessionStorage.clear();
        window.location.href = "/index.html";
      });
  };
};
export const kullaniciBilgileriniGetir = (siteid, sunucuid) => {
  currentToken = sessionStorage.getItem("tokenKey");
  return function (dispatch) {
    dispatch(loadingTrue());
    axios
      .get(
        `
        https://yonetici1.yonetimcell.com/account/KullaniciBilgileriniGetir?siteId=${siteid}&sunucuId=${sunucuid}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + currentToken,
          },
        }
      )
      .then((response) => {
        sessionStorage.setItem("KullaniciAd", response.data.Ad);
        sessionStorage.setItem("KullaniciEposta", response.data.Email);
        sessionStorage.setItem("KullaniciId", response.data.KullaniciId);
        sessionStorage.setItem("Roller", JSON.stringify(response.data.Roller));
        if (response.data.proid !== null) {
          sessionStorage.setItem("proid", response.data.proid);
        }
        dispatch(ykullaniciBigileriOk(response.data));
        dispatch(siteBilgileriniGetir(siteid, sunucuid));
      })
      .catch((data) => {
        if (data) {
          sessionStorage.clear();
          window.location.href = "/index.html";
        }
      });
  };
};

export const siteBilgileriniGetir = (siteid, sunucuid) => {
  currentToken = sessionStorage.getItem("tokenKey");
  return function (dispatch) {
    dispatch(loadingTrue());
    axios
      .get(
        `https://yonetici1.yonetimcell.com/siteayarlari/sitebilgilerinigetir?siteId=${siteid}&sunucuId=${sunucuid}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + currentToken,
          },
        }
      )
      .then((response) => {
        dispatch(loadingTrue());
        dispatch(sitebilgileriok(response.data));
        dispatch(siteSakinleriGetir(siteid, sunucuid));
      })
      .catch((error) => {
        dispatch(sitebilgileriHata(error.message));
        window.location.href = "/index.html";
      });
  };
};

export const siteSakinleriGetir = (siteid, sunucuid) => {
  currentToken = sessionStorage.getItem("tokenKey");
  return function (dispatch) {
    axios
      .get(
        `https://yonetici1.yonetimcell.com/sorgu/sorgu?siteId=${siteid}&sunucuId=${sunucuid}&strQuery=&sorguid=100`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + currentToken,
          },
        }
      )
      .then((response) => {
        dispatch(siteSakinleriOk(response.data));
        dispatch(siteTanimlariniGetir(siteid, sunucuid));
      })
      .catch((error) => dispatch(siteSakinleriHata(error.message)));
  };
};

export const siteTanimlariniGetir = (siteid, sunucuid) => {
  currentToken = sessionStorage.getItem("tokenKey");
  return function (dispatch) {
    axios
      .get(
        `https://yonetici1.yonetimcell.com/istakibi/sorgu?siteId=${siteid}&sunucuId=${sunucuid}&strQuery=&sorguid=1001${
          sessionStorage.getItem("proid")
            ? `&proid=${sessionStorage.getItem("proid")}`
            : ""
        }`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + currentToken,
          },
        }
      )
      .then((response) => {
        dispatch(siteTanimlariOk(response.data));
        dispatch(sayilariGoster(response.data.Table3[0]));
        //dispatch(yoneticiBekleyenleriGetir(API_YC_BASE, sessionStorage.getItem("SiteID") , sessionStorage.getItem("SunucuID")))
        dispatch(loadingFalse());
      })
      .catch((error) => dispatch(siteTanimlariHata(error.message)));
  };
};

export const kapilariGetir = (siteid, sunucuid, secilenBlok) => {
  currentToken = sessionStorage.getItem("tokenKey");
  return function (dispatch) {
    axios
      .get(
        `
        https://yonetici1.yonetimcell.com/sorgu/sorgu?siteId=${siteid}&sunucuId=${sunucuid}&strQuery=&sorguid=2120&int1=${secilenBlok}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + currentToken,
          },
        }
      )
      .then((response) => {
        dispatch(kapilarOk(response.data));
      })
      .catch((error) => dispatch(siteSakinleriHata(error.message)));
  };
};

export const siteSakiniGetir = (siteid, sunucuid, secilenBlok, secilenKapi) => {
  currentToken = sessionStorage.getItem("tokenKey");
  return function (dispatch) {
    axios
      .get(
        `https://yonetici1.yonetimcell.com/sorgu/sorgu?siteId=${siteid}&sunucuId=${sunucuid}&strQuery=&sorguid=104104&int1=${secilenBlok}&str1=${secilenKapi}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + currentToken,
          },
        }
      )
      .then((response) => {
        dispatch(siteSakinOk(response.data.Table[0]));
      })
      .catch((error) => dispatch(siteSakinleriHata(error.message)));
  };
};

export const siteSakiniAraGetir = (siteid, sunucuid, uyeid) => {
  currentToken = sessionStorage.getItem("tokenKey");
  return function (dispatch) {
    axios
      .get(
        `https://yonetici1.yonetimcell.com/sorgu/sorgu?siteId=${siteid}&sunucuId=${sunucuid}&strQuery=&sorguid=104&int1=${uyeid}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + currentToken,
          },
        }
      )
      .then((response) => {
        dispatch(siteSakinOk(response.data.Table[0]));
      })
      .catch((error) => dispatch(siteSakinleriHata(error.message)));
  };
};

export const isOlustur = (
  { ...secilenler },
  personelSms,
  { ...secilenPersonel },
  personeller,
  smsGonder,
  dosyalar
) => {
  return function (dispatch) {
    dispatch(loadingTrue());
    currentToken = sessionStorage.getItem("tokenKey");
    dispatch(
      postJson(
        `https://yonetici1.yonetimcell.com/istakibi/postsorgu`,
        secilenler,
        -1,
        (data) => {
          if (data.Table) {
            data = data.Table;
            dispatch(
              yoneticiBekleyenleriGetir(
                API_YC_BASE,
                sessionStorage.getItem("SiteID"),
                sessionStorage.getItem("SunucuID")
              )
            );
          }
          try {
            if (!secilenler.talepid) {
              if (secilenler.uyeid) {
                dispatch(
                  postJson(
                    `https://yonetici1.yonetimcell.com/mesajlar/ekle`,
                    {
                      siteid: sessionStorage.getItem("SiteID"),
                      metin: secilenler.isinaciklamasi,
                      uyeid: secilenler.uyeid,
                      gonderen: data && data.length > 0 ? data[0].id : 1,
                    },
                    0,
                    (msj) => {
                      dispatch(
                        dosyalariGonder(
                          dosyalar,
                          msj,
                          secilenler.uyeid,
                          "//dosya.yonetimcell.com/dosya",
                          (c) => {
                            var model = {
                              mesajid: msj,
                              metin: `İş takip süreci başlatıldı.. (${sessionStorage.getItem(
                                "KullaniciAd"
                              )})`,
                              uyeid: secilenler.uyeid,
                              mailbaslik: null,
                            };
                            dispatch(
                              postJson(
                                `https://yonetici1.yonetimcell.com/mesajlar/cevapla`,
                                {
                                  ...model,
                                },
                                0,
                                (dt) => {
                                  dispatch(
                                    getJson(
                                      `https://yonetici1.yonetimcell.com/istakibi/sorgu`,
                                      `&sorguid=1111&talepid=${msj}&id=${
                                        data && data.length > 0 ? data[0].id : 1
                                      }${
                                        sessionStorage.getItem("proid")
                                          ? `&proid=${sessionStorage.getItem(
                                              "proid"
                                            )}`
                                          : ""
                                      }`,
                                      (tlp) => {
                                        dispatch(
                                          sakinSmsGonderFunction(
                                            `Sayın ${
                                              secilenler.adsoyad
                                            }, Sizin için ${sessionStorage.getItem(
                                              "SiteAdi"
                                            )} tarafından iş takibi süreci başlatıldı.`,
                                            secilenler.uyeid,
                                            smsGonder,
                                            secilenler.tasinmazid,
                                            secilenler.telefon,
                                            secilenler.websifre,
                                            secilenler.int1,
                                            (sonuc) => {
                                              dispatch(
                                                personelSmsGonder(
                                                  { ...secilenler },
                                                  personelSms,
                                                  secilenPersonel,
                                                  personeller
                                                )
                                              );
                                            }
                                          )
                                        );
                                      }
                                    )
                                  );
                                }
                              )
                            );
                          }
                        )
                      );
                    }
                  )
                );
              } else {
                dispatch(
                  personelSmsGonder(
                    { ...secilenler },
                    personelSms,
                    { ...secilenPersonel },
                    personeller
                  )
                );
              }
            } else {
              dispatch(
                postJson(
                  `https://yonetici1.yonetimcell.com/mesajlar/guncelle`,
                  {
                    id: secilenler.talepid,
                    gonderen: data && data.length > 0 ? data[0].id : 1,
                  },
                  0,
                  (data) => {
                    // try{
                    // MESAJ CEVAP EKRANI KAPAT KODLARI GELECEK...
                    // }
                    var model = {
                      mesajid: secilenler.talepid,
                      metin: `Talebiniz için iş takip süreci başlatıldı.. (${sessionStorage.getItem(
                        "KullaniciAd"
                      )})`,
                      uyeid: secilenler.uyeid,
                      mailbaslik: `TALEBİNİZ İÇİN İŞ TAKİP SÜRECİ BAŞLATILDI (${sessionStorage.getItem(
                        "SiteAdi"
                      )})`,
                    };

                    dispatch(
                      postJson(
                        `https://yonetici1.yonetimcell.com/mesajlar/cevapla`,
                        model,
                        0,
                        (dt) => {
                          sakinSmsGonderFunction(
                            `Talebiniz için ${sessionStorage.getItem(
                              "SiteAdi"
                            )} tarafından iş takip süreci başlatıldı.`,
                            secilenler.uyeid,
                            smsGonder,
                            secilenler.tasinmazid,
                            secilenler.telefon,
                            secilenler.websifre,
                            (sonuc) => {}
                          );
                        }
                      )
                    );
                  }
                )
              );
            }
          } catch (err) {}
        }
      )
    );
    dispatch(loadingFalse());
  };
};
export const dosyaGonderr = (yol, dt, en, boy, ken, kboy, callback) => {
  var data = new FormData();
  data.append("uploadedImage", dt);
  data.append("tokenKey", sessionStorage.getItem("tokenKey"));
  axios
    .post(
      `https://dosya.yonetimcell.com/dosya/dosyayukle/${yol}/${sessionStorage.getItem(
        "SunucuID"
      )}/${sessionStorage.getItem("SiteID")}/${en}/${boy}/${ken}/${kboy}/`,
      data,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    )
    .then((data) => {
      if (data) {
        if (data.Table1 || !data.Table) {
          callback(data.data);
        } else {
          callback(data.Table);
        }
      } else {
        callback(data);
      }
    })
    .catch((data) => {
      if (data.status === 401) {
        window.location.href = "/index.html";
      }
    });
};
export const proDosyaGonderr = (yol, dt, en, boy, ken, kboy, callback) => {
  var data = new FormData();
  data.append("uploadedImage", dt);
  data.append("tokenKey", sessionStorage.getItem("tokenKey"));
  axios
    .post(
      `https://dosya.yonetimcell.com/dosya/dosyayukle/${yol}/${sessionStorage.getItem(
        "SunucuID"
      )}/${
        10000000 + parseInt(sessionStorage.getItem("proid"))
      }/${en}/${boy}/${ken}/${kboy}/`,
      data,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    )
    .then((data) => {
      if (data) {
        if (data.Table1 || !data.Table) {
          callback(data);
        } else {
          callback(data.Table);
        }
      } else {
        callback(data);
      }
    });
};

export const dosyaSill = (dosya, callback) => {
  var dsbm = {
    SiteId: sessionStorage.getItem("SiteID"),
    SunucuId: sessionStorage.getItem("SunucuID"),
    TokenKey: sessionStorage.getItem("tokenKey"),
    Dosyayolu: `dosya/${sessionStorage.getItem("SiteID")}/arsiv/${dosya}`,
  };
  axios
    .post(`https://dosya.yonetimcell.com/dosya/dosyasil/`, {
      ...dsbm,
    })
    .then(() => {
      callback();
    })
    .catch((data) => {
      if (data.status === 401) {
        window.location.href = "/index.html";
      }
    });
};

export const dsyaSil = (dosyaId, dosya, isId, dosyalar) => {
  return function (dispatch) {
    dispatch(loadingTrue());
    if (sessionStorage.getItem("proid")) {
      proDosyaSill(dosya, () => {
        getJson(
          `https://yonetici1.yonetimcell.com/istakibi/sorgu`,
          `&sorguid=1101&id=${dosyaId}&int1=${isId}&proid=${sessionStorage.getItem(
            "proid"
          )}`,
          (dt) => {
            dispatch({
              type: FETCH_DOSYASIL_FULFILLED,
              fetching: false,
              dosyaId: dosyaId,
            });
            dispatch(loadingFalse());
          }
        );
      });
    } else {
      dosyaSill(dosya, () => {
        getJson(
          `https://yonetici1.yonetimcell.com/istakibi/sorgu`,
          `&sorguid=1101&id=${dosyaId}&int1=${isId}`,
          (dt) => {
            dispatch({
              type: FETCH_DOSYASIL_FULFILLED,
              fetching: false,
              dosyaId: dosyaId,
            });
            dispatch(loadingFalse());
          }
        );
      });
    }
  };
};

export const proDosyaSill = (dosya, callback) => {
  var dsbm = {
    SiteId: sessionStorage.getItem("SiteID"),
    SunucuId: sessionStorage.getItem("SunucuID"),
    TokenKey: sessionStorage.getItem("tokenKey"),
    Dosyayolu: `dosya/${
      10000000 + parseInt(sessionStorage.getItem("proid"))
    }/${dosya}`,
  };

  axios
    .post(`https://dosya.yonetimcell.com/dosya/dosyasil`, {
      ...dsbm,
    })
    .then(() => {
      callback();
    })
    .catch((data) => {
      if (data.status === 401) {
        window.location.href = "/index.html";
      }
    });
};
export const dosyalariGonder = (dosyalar, id, uyeid, dosyagosteryolu, c) => {
  return function (dispatch) {
    if (dosyalar.length > 0) {
      dispatch(
        postJson(
          `https://yonetici1.yonetimcell.com/mesajlar/cevapla`,
          {
            mesajid: id,
            metin: dosyalar[0].aciklama,
            uyeid: uyeid,
            mailbaslik: null,
            dosya: `${dosyagosteryolu}/${sessionStorage.getItem(
              "SiteID"
            )}/arsiv/${dosyalar[0].dosya}`,
          },
          0,
          (dt) => {
            if (dosyalar.length > 1) {
              dispatch(
                postJson(
                  `https://yonetici1.yonetimcell.com/mesajlar/cevapla`,
                  {
                    mesajid: id,
                    metin: dosyalar[1].aciklama,
                    uyeid: uyeid,
                    mailbaslik: null,
                    dosya: `${dosyagosteryolu}/${sessionStorage.getItem(
                      "SiteID"
                    )}/arsiv/${dosyalar[1].dosya}`,
                  },
                  0,
                  (dt) => {
                    if (dosyalar.length > 2) {
                      dispatch(
                        postJson(
                          `https://yonetici1.yonetimcell.com/mesajlar/cevapla`,
                          {
                            mesajid: id,
                            metin: dosyalar[2].aciklama,
                            uyeid: uyeid,
                            mailbaslik: null,
                            dosya: `${dosyagosteryolu}/${sessionStorage.getItem(
                              "SiteID"
                            )}/arsiv/${dosyalar[2].dosya}`,
                          },
                          0,
                          (dt) => {
                            c();
                          }
                        )
                      );
                    } else {
                      c();
                    }
                  }
                )
              );
            } else {
              c();
            }
          }
        )
      );
    } else {
      c();
    }
  };
};
export const sakinSmsGonderFunction = (
  mesaj,
  uyeid,
  smsGonder,
  tasinmazid,
  telefon,
  websifre,
  secilisiteid,
  sonuc
) => {
  return function (dispatch) {
    var liste1 = [];
    var listeP = [];

    if (sessionStorage.getItem("proid")) {
      dispatch(
        bildirimGonderSakin(null, mesaj, uyeid, (sc) => {
          if (smsGonder && telefon) {
            listeP.push({
              smsbaslik: null,
              siteid: 0,
              proid: sessionStorage.getItem("proid"),
              alici: telefon,
              metin: mesaj,
              linkgonder: 1,
              linkmetin:
                ". Süreci <link> adresinden yada site sakini mobil uygulaması üzerinden takip edebilirsiniz..",
              sifre: "" + websifre + "",
              tasinmazid: tasinmazid,
              link:
                "https://aidattakipsistemi.com/mobil/?yeni=1&site=" +
                secilisiteid +
                "&siteid=" +
                secilisiteid +
                "&",
            });
          }
          dispatch(
            postJson(
              "https://yonetici1.yonetimcell.com/sms/prohitaplismsgonder",
              listeP,
              0,
              (d) => {
                sonuc(1);
              }
            )
          );
        })
      );
    } else {
      dispatch(
        bildirimGonderSakin(
          sessionStorage.getItem("SiteAdi"),
          mesaj,
          uyeid,
          (sc) => {
            if (smsGonder && telefon) {
              liste1.push({
                alici: telefon,
                metin: mesaj,
                linkgonder: 1,
                linkmetin: `. Süreci <link> adresinden yada site sakini mobil uygulaması üzerinden takip edebilirsiniz..`,
                sifre: ` ${websifre} `,
                tasinmazid: tasinmazid,
                link: `https://aidattakipsistemi.com/mobil/?yeni=1&site=${sessionStorage.getItem(
                  "SiteID"
                )}&siteid=${sessionStorage.getItem("SiteID")}&`,
              });
              dispatch(
                postJson(
                  `https://yonetici1.yonetimcell.com/sms/hitaplismsgonder`,
                  liste1,
                  0,
                  (d) => {
                    sonuc(1);
                  }
                )
              );
            } else {
              sonuc(1);
            }
          }
        )
      );
    }
  };
};
export const bildirimGonderSakin = (baslik, metin, uyeid, sc) => {
  return function (dispatch) {
    if (uyeid) {
      dispatch(
        postJson(
          `https://yonetici1.yonetimcell.com/bildirim/gonder/atsapp`,
          [
            {
              siteid: sessionStorage.getItem("SiteID"),
              uyeid: uyeid,
              baslik: baslik,
              icerik: metin,
            },
          ],
          -1,
          (dd) => {
            sc(1);
          }
        )
      );
    } else {
      sc(1);
    }
  };
};
export const personelSmsGonder = (
  { ...secilenler },
  personelSms,
  { ...secilenPersonel },
  personeller,
  sonuc
) => {
  return function (dispatch) {
    const Model = [];
    const bListe = [];
    var ModelP = [];
    var bListeP = [];
    if (sessionStorage.getItem("proid")) {
      // PRO PERSONEL SMS GONDER
      if (personelSms) {
        if (secilenPersonel.kulid) {
          ModelP.push({
            smsbaslik: null,
            siteid: 0,
            proid: sessionStorage.getItem("proid"),
            alici: personeller.filter((personel) => {
              return personel.kulid === secilenPersonel.kulid;
            })[0].telefon,
            metin: `${secilenPersonel.ad} TARAFINDAN ${secilenler.string3} İÇİN YENİ BİR İŞ OLUŞTURDU. ${secilenler.isinaciklamasi}`,
          });
          bListeP.push({
            username: personeller.filter((personel) => {
              return personel.kulid === secilenPersonel.kulid;
            })[0].telefon,
            baslik: `${secilenPersonel.ad} TARAFINDAN ${secilenler.string3} İÇİN YENİ BİR İŞ OLUŞTURDU. ${secilenler.isinaciklamasi}`,
            icerik: secilenler.isinaciklamasi
              ? secilenler.isinaciklamasi
              : "...",
          });
        } else {
          personeller.forEach((personel) => {
            ModelP.push({
              alici: personel.telefon,
              metin: `${secilenPersonel.ad} TARAFINDAN ${secilenler.string3} İÇİN YENİ BİR İŞ OLUŞTURDU. ${secilenler.isinaciklamasi}`,
              proid: sessionStorage.getItem("proid"),
              siteid: 0,
              smsbaslik: null,
            });
            bListeP.push({
              username: personel.telefon,
              baslik: `${secilenPersonel.ad} TARAFINDAN ${secilenler.string3} İÇİN YENİ BİR İŞ OLUŞTURDU. ${secilenler.isinaciklamasi}`,
              icerik: secilenler.isinaciklamasi
                ? secilenler.isinaciklamasi
                : "...",
            });
          });
        }
        dispatch(
          postJson(
            `https://yonetici1.yonetimcell.com/sms/prohitaplismsgonder`,
            ModelP,
            0,
            (dd) => {
              dispatch(
                postJson(
                  `https://yonetici1.yonetimcell.com/bildirim/gonder/istakibi`,
                  bListeP,
                  -1,
                  (d) => {
                    return d;
                  }
                )
              );
            }
          )
        );
      } else {
        if (secilenPersonel.kulid) {
          bListeP.push({
            username: personeller.filter((personel) => {
              return personel.kulid === secilenPersonel.kulid;
            })[0].telefon,
            baslik: `${secilenPersonel.ad} TARAFINDAN ${secilenler.string3} İÇİN YENİ BİR İŞ OLUŞTURDU.`,
            icerik: secilenler.isinaciklamasi
              ? secilenler.isinaciklamasi
              : "...",
          });
        } else {
          personeller.forEach((personel) => {
            bListeP.push({
              username: personel.telefon,
              baslik: `${secilenPersonel.ad} TARAFINDAN ${secilenler.string3} İÇİN YENİ BİR İŞ OLUŞTURDU. `,
              icerik: secilenler.isinaciklamasi
                ? secilenler.isinaciklamasi
                : "...",
            });
          });
        }
        dispatch(
          postJson(
            `https://yonetici1.yonetimcell.com/bildirim/gonder/istakibi`,
            bListeP,
            -1,
            (s) => {
              return s;
            }
          )
        );
      }
      // PRO PERSONEL SMS GONDER END
    } else {
      if (personelSms) {
        if (secilenPersonel.kulid) {
          Model.push({
            alici: personeller.filter((personel) => {
              return personel.kulid === secilenPersonel.kulid;
            })[0].telefon,
            metin: `SİZİN İÇİN ${sessionStorage.getItem(
              "SiteAdi"
            )} TARAFINDAN YENİ BİR İŞ OLUŞTURDU. ${secilenler.isinaciklamasi}`,
          });
          bListe.push({
            username: personeller.filter((personel) => {
              return personel.kulid === secilenPersonel.kulid;
            })[0].telefon,
            baslik: `SİZİN İÇİN ${sessionStorage.getItem(
              "SiteAdi"
            )} TARAFINDAN YENİ BİR İŞ OLUŞTURDU. `,
            icerik: secilenler.isinaciklamasi
              ? secilenler.isinaciklamasi
              : "...",
          });
        } else {
          personeller.forEach((personel) => {
            Model.push({
              alici: personel.telefon,
              metin: `${sessionStorage.getItem(
                "SiteAdi"
              )} TARAFINDAN YENİ BİR İŞ OLUŞTURDU. ${
                secilenler.isinaciklamasi
              }`,
            });
            bListe.push({
              username: personel.telefon,
              baslik: `${sessionStorage.getItem(
                "SiteAdi"
              )} TARAFINDAN YENİ BİR İŞ OLUŞTURDU. `,
              icerik: secilenler.isinaciklamasi
                ? secilenler.isinaciklamasi
                : "...",
            });
          });
        }
        dispatch(
          postJson(
            `https://yonetici1.yonetimcell.com/sms/hitaplismsgonder`,
            Model,
            0,
            (dd) => {
              dispatch(
                postJson(
                  `https://yonetici1.yonetimcell.com/bildirim/gonder/istakibi`,
                  bListe,
                  -1,
                  (d) => {
                    return d;
                  }
                )
              );
            }
          )
        );
      } else {
        if (secilenPersonel.kulid) {
          bListe.push({
            username: personeller.filter((personel) => {
              return personel.kulid === secilenPersonel.kulid;
            })[0].telefon,
            baslik: `SİZİN İÇİN ${sessionStorage.getItem(
              "SiteAdi"
            )} TARAFINDAN YENİ BİR İŞ OLUŞTURDU. `,
            icerik: secilenler.isinaciklamasi
              ? secilenler.isinaciklamasi
              : "...",
          });
        } else {
          personeller.forEach((personel) => {
            bListe.push({
              username: personel.telefon,
              baslik: `${sessionStorage.getItem(
                "SiteAdi"
              )} TARAFINDAN YENİ BİR İŞ OLUŞTURDU. `,
              icerik: secilenler.isinaciklamasi
                ? secilenler.isinaciklamasi
                : "...",
            });
          });
        }
        dispatch(
          postJson(
            `https://yonetici1.yonetimcell.com/bildirim/gonder/istakibi`,
            bListe,
            -1,
            (s) => {
              return s;
            }
          )
        );
      }
    }
    // currentToken = sessionStorage.getItem("tokenKey");
    // axios
    //   .post(
    //     `https://yonetici1.yonetimcell.com/sms/hitaplismsgonder`,
    //     {
    //       Model,
    //       ModelGelsinMi: 0,
    //       SiteId: sessionStorage.getItem("SiteID"),
    //       SunucuId: sessionStorage.getItem("SunucuID"),
    //     },
    //     {
    //       headers: {
    //         "Content-Type": "application/json",
    //         Authorization: "Bearer " + currentToken,
    //       },
    //     }
    //   )
    //   .then((result) => {
    //     console.log(result);
    //   });
  };
};
export const postJsonAction = (data) => {
  return {
    type: FETCH_POST_FULFILLED,
    fetching: false,
    dosya: data,
    posts: data,
  };
};
export const postJsonActions = (data) => {
  return {
    type: FETCH_POSTJSON_FULLFILLED,
    fetching: false,
    post: data,
  };
};

export const postJson = (url, data, ModelGelsinMi, callback) => {
  return function (dispatch) {
    currentToken = sessionStorage.getItem("tokenKey");
    if (!url) {
      return;
    }
    if (!data) {
      return;
    }
    if (ModelGelsinMi < 0) {
      var bindingModel = data;
    } else {
      bindingModel = {
        SiteId: sessionStorage.getItem("SiteID"),
        SunucuId: sessionStorage.getItem("SunucuID"),
        ModelGelsinMi: ModelGelsinMi,
        Model: data,
      };
    }

    axios
      .post(url, bindingModel, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + currentToken,
        },
      })
      .then((result) => {
        callback(result.data);
      })
      .catch((data) => {
        if (data.status === 401) {
          window.location.href = "/index.html";
        }
      });
  };
};
export const getJson = (url, query, callback) => {
  var domainx = "";
  if (!url) {
    return;
  }
  var afterDomain = "";

  if (query) {
    if (query !== "ana") {
      query = query.replace(/, /g, ",");
      afterDomain =
        url +
        "?siteId=" +
        sessionStorage.getItem("SiteID") +
        "&sunucuId=" +
        sessionStorage.getItem("SunucuID") +
        "&strQuery=" +
        query;
    } else {
      afterDomain = url;
    }
  } else {
    afterDomain =
      url +
      "?siteId=" +
      sessionStorage.getItem("SiteID") +
      "&sunucuId=" +
      sessionStorage.getItem("SunucuID");
  }
  axios
    .get(`${afterDomain}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentToken,
      },
    })
    .then((data) => {
      if (data) {
        if (data.Table1 || !data.Table) {
          callback(data);
        } else {
          callback(data.Table);
        }
      } else {
        callback(data);
      }
      try {
        data.Table = [];
      } catch (err) {}
      try {
        data.Table1 = [];
      } catch (err) {}
      try {
        data.Table2 = [];
      } catch (err) {}
    })
    .catch((data) => {
      if (data.status === 401) {
        window.location.href = "/index.html";
      }
    });
};

export const dosyaKaydet = (
  gelen,
  dosya,
  dosyaAd,
  isId,
  siteId,
  setYuklenenDosyalar
) => {
  return function (dispatch) {
    var dosyalar = [];
    dispatch(loadingTrue());
    dosyaGonderr("arsiv", dosya, 200, 0, 748, 0, (data) => {
      dispatch(
        postJson(
          "https://yonetici1.yonetimcell.com/istakibi/postsorgu",
          {
            SiteId: sessionStorage.getItem("SiteID"),
            SunucuId: sessionStorage.getItem("SunucuID"),
            sorguid: 1100,
            id: isId,
            string2: gelen,
            string1: `//dosya.yonetimcell.com/dosya/${siteId}/arsiv/${data}`,
          },
          -1,
          (dt) => {
            if (dt.Table) {
              dt = dt.Table;
            }
            dosyalar.push({
              id: dt[0].id,
              isId: dt[0].isid,
              dosya: data,
              aciklama: gelen,
              talepten: 0,
            });

            dispatch({
              type: FETCH_DOSYALAR_FULFILLED,
              fetching: false,
              dosyalar: { ...dosyalar },
            });
            dispatch(loadingFalse());
            isId = dt[0].isid;
          }
        )
      );
    });
  };
};

export const proDosyaKaydet = (
  gelen,
  dosya,
  dosyaAd,
  isId,
  siteId,
  setYuklenenDosyalar
) => {
  return function (dispatch) {
    var dosyalar = [];
    dispatch(loadingTrue());

    proDosyaGonderr("arsiv", dosya, 200, 0, 748, 0, (data) => {
      dispatch(
        postJson(
          "https://yonetici1.yonetimcell.com/istakibi/postsorgu",
          {
            SiteId: sessionStorage.getItem("SiteID"),
            SunucuId: sessionStorage.getItem("SunucuID"),
            sorguid: 1100,
            proid: sessionStorage.getItem("proid"),
            string2: gelen,
            string1: `//dosya.yonetimcell.com/dosya/${
              10000000 + sessionStorage.getItem("proid")
            }/arsiv/${data.data}`,
          },
          -1,
          (dt) => {
            if (dt.Table) {
              dt = dt.Table;
            }
            dosyalar.push({
              id: dt[0].id,
              isId: dt[0].isid,
              dosya: data.data,
              aciklama: gelen,
              talepten: 0,
            });

            dispatch({
              type: FETCH_DOSYALAR_FULFILLED,
              fetching: false,
              dosyalar: { ...dosyalar },
            });
            dispatch(loadingFalse());
            isId = dt[0].isid;
          }
        )
      );
    });
  };
};
