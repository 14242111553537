import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { isiGetir } from "../../redux/actions/isAction";
import { isiReddet } from "../../redux/actions/isAction";
import { isiKabulEt } from "../../redux/actions/isAction";
import txtYol from "../../helpers/dosyaiconları/txt.png";
import DialogScreen from "../pages/DialogScreen";
import Moment from "react-moment";
import PropTypes from "prop-types";
import { RingLoader } from "react-spinners";
import MesajGelen from "./MesajGelen";

const KanbanDetail = (props) => {
  const {isiGetir} = props;
  useEffect(() => {
    if (sessionStorage.getItem("token")) {
      props.isiGetir(props.id);
    }
    if (sessionStorage.getItem("tokenKey")) {
      props.yoneticiIsiGetir();
    }
  }, []);
  const [uyari, setUyari] = useState(false);
  const [redUyari, setRedUyari] = useState(false);
  const Table = props.isReducer.data.Table;
  const icerikTable = props.isReducer.data.Table2;
  const dosyaTable = props.isReducer.data.Table1;

  const isKabul = (event) => {
    // props.isiKabulEt(props.id);
    // setTimeout(() => {
    //   alert("İş Kabul Edildi. Yönlendiriliyorsunuz.");
    //   window.location.href = "/bekleyen";
    // }, 1000);
  };

  const uyariAc = (e) => {
    setUyari(true);
  };

  const redUyariAc = (e) => {
    setRedUyari(true);
  };

  return (
    <div>
      <div className="popUpAlert">
        {props.isReducer.fetching ? (
          <div className="load">
            <RingLoader
              size={100}
              color={"#648541"}
              loading={true}
            ></RingLoader>
          </div>
        ) : null}
        {props.kabulEtReducer.fetching ? (
          <div className="load">
            <RingLoader
              size={100}
              color={"#648541"}
              loading={true}
            ></RingLoader>
          </div>
        ) : null}

        {props.reddetReducer.fetching ? (
          <div className="load">
            <RingLoader
              size={100}
              color={"#648541"}
              loading={true}
            ></RingLoader>
          </div>
        ) : null}

        {Table &&
          Table.map((item) => (
            <aside key={item.id} className="popUpWrapper">
              {item.siteid !== null ? (
                <h4 className="siteAdi">{item.siteadi}</h4>
              ) : (
                <h4 className="siteAdid">{item.siteadi}</h4>
              )}

              <aside className="siteBilgileri">
                <aside className="cols colssol">
                  <p>
                    İşi Oluşturan: <b>{item.isiolusturan}</b>
                  </p>
                  <p>
                    İşin Durumu: <b>{item.durumadi}</b>
                  </p>
                </aside>
                <aside className="cols colsorta">
                  <p className="olusturmaTarihi">
                    Oluşturulma Tarihi:{" "}
                    <b>
                      {
                        <Moment format="DD MMMM YYYY">
                          {item.olusturmatarihi}
                        </Moment>
                      }
                    </b>
                  </p>
                  {item.isintarihi !== null ? (
                    <p className="teslimTarihi">
                      Teslim Tarihi:{" "}
                      <b>
                        {
                          <Moment format="DD MMMM YYYY">
                            {item.isintarihi}
                          </Moment>
                        }
                      </b>
                    </p>
                  ) : (
                    <p></p>
                  )}
                </aside>
                {item.uyeid !== null ? (
                  <div className="sitesakinibilgileris">
                    <p className="sitasknbslk">Site Sakini Bilgileri</p>
                    <aside className="cols colssag">
                      <aside className="ikiliDiv">
                        <p>
                          Ad Soyad: <b>{item.adsoyad}</b>
                        </p>
                        <p>
                          Daire: <b>{item.blok}</b> <b>{item.kapino}</b>
                        </p>
                      </aside>
                      <aside className="ikiliDiv">
                        {item.telefon !== null ? (
                          <p>
                            Telefon: <b>{item.telefon}</b>
                          </p>
                        ) : null}
                        {item.email !== null ? (
                          <p>
                            E-Mail: <b>{item.email}</b>
                          </p>
                        ) : null}
                      </aside>
                    </aside>
                  </div>
                ) : null}
              </aside>
              <aside className="isinAciklamasiBaslik">
                {dosyaTable &&
                  dosyaTable.map((dosya) =>
                    dosya.dosya.substr(dosya.dosya.length - 4) === ".png" ||
                    dosya.dosya.substr(dosya.dosya.length - 4) === ".jpg" ||
                    dosya.dosya.substr(dosya.dosya.length - 5) === ".jpeg" ||
                    dosya.dosya.substr(dosya.dosya.length - 5) === ".tiff" ? (
                      <aside key={dosya.id} className="gorselVarsa">
                        <img src={dosya.dosya} alt={dosya.aciklama}></img>
                        <p>
                          Dosya Açıklaması: <b>{dosya.aciklama}</b>
                        </p>
                      </aside>
                    ) : (
                      <aside className="txtGibiDosya">
                        <button
                          onClick={() =>
                            window.open(`${dosya.dosya}`)
                          }
                          className="dosyaIse"
                        >
                          {dosya.dosya.substr(dosya.dosya.length - 4)} Uzantılı
                          Dosyayı Aç
                        </button>
                      </aside>
                    )
                  )}
                {item.isinaciklamasi}
              </aside>
              <div className="popWrap">
                {icerikTable &&
                  icerikTable.map((icerik) => (
                    <MesajGelen key={icerik.id} icerik={icerik}></MesajGelen>
                    // <aside key={icerik.id} className="isHikayesi gelen">
                    //   <aside className="isItemKapsa gelenisItem">
                    //     <aside className="isItem gelen">
                    //       <p className="gonderen-ad">
                    //         <i
                    //           className="fa fa-user gonderenicon"
                    //           aria-hidden="true"
                    //         ></i>
                    //         <b>
                    //           {icerik.kaydeden} ( {icerik.durumadi} ){" "}
                    //         </b>
                    //       </p>
                    //       <aside className="mesaj-icerik">
                    //         {icerik.dosya ? (
                    //           <div>
                    //             {icerik.dosya.substr(
                    //               icerik.dosya.length - 4
                    //             ) === ".png" ||
                    //             icerik.dosya.substr(icerik.dosya.length - 4) ===
                    //               ".jpg" ||
                    //             icerik.dosya.substr(icerik.dosya.length - 4) ===
                    //               ".gif" ||
                    //             icerik.dosya.substr(icerik.dosya.length - 5) ===
                    //               ".tiff" ||
                    //             icerik.dosya.substr(icerik.dosya.length - 5) ===
                    //               ".jpeg" ? (
                    //               <div>
                    //                 <img
                    //                   className="mesaj-icerik-img"
                    //                   src={icerik.dosya}
                    //                   alt="Görsel Yüklenemedi..."
                    //                 />
                    //                 <p className="gonderilen-icerik">
                    //                   {icerik.dosyaaciklama !== "null" ? (
                    //                     <span>{icerik.dosyaaciklama}</span>
                    //                   ) : (
                    //                     <span> </span>
                    //                   )}
                    //                 </p>
                    //               </div>
                    //             ) : null}
                    //             {icerik.dosya.substr(
                    //               icerik.dosya.length - 4
                    //             ) === ".txt" ? (
                    //               <div>
                    //                 <img src={txtYol} alt="Txt"></img>
                    //                 <a href={icerik.dosya} download>
                    //                   Txt Dosyasını Açmak İçin Tıklayın.
                    //                 </a>
                    //                 <p className="gonderilen-icerik">
                    //                   {icerik.dosyaaciklama !== "null" ? (
                    //                     <span>{icerik.dosyaaciklama}</span>
                    //                   ) : (
                    //                     <span> </span>
                    //                   )}
                    //                 </p>
                    //               </div>
                    //             ) : null}
                    //           </div>
                    //         ) : null}
                    //         <p className="gonderilen-icerik ">
                    //           {icerik.aciklama !== null ? (
                    //             <span>{icerik.aciklama}</span>
                    //           ) : (
                    //             <span> </span>
                    //           )}
                    //         </p>
                    //       </aside>
                    //     </aside>
                    //     <p className="gndrmTrh">
                    //       <b className="gonderilen-saat">
                    //         {
                    //           <Moment format="DD MMMM YYYY HH:mm">
                    //             {icerik.tarihi}
                    //           </Moment>
                    //         }
                    //       </b>
                    //     </p>
                    //   </aside>
                    // </aside>
                  ))}
              </div>
              {uyari === true ? (
                <DialogScreen
                  dialog={uyari}
                  isid={props.id}
                  islem={"İşi"}
                  olay={"kabul etmek"}
                  dialogKapat={() => setUyari(false)}
                ></DialogScreen>
              ) : null}
              {item.isindurumu !== 7 ? (
                <aside className="buttonlar">
                  <button className="btn btn-onay" onClick={uyariAc}>
                    Kabul Et
                  </button>
                  {redUyari === true ? (
                    <DialogScreen
                      dialog={redUyari}
                      isid={props.id}
                      islem={"İşi"}
                      olay={"reddetmek"}
                      dialogKapat={() => setRedUyari(false)}
                    ></DialogScreen>
                  ) : null}
                  {item.kullaniciid !== null ? (
                    <button onClick={redUyariAc} className="btn btn-red">
                      Reddet
                    </button>
                  ) : null}
                </aside>
              ) : null}
            </aside>
          ))}
      </div>
    </div>
  );
};

const mapStateToProps = ({ isReducer, kabulEtReducer, reddetReducer }) => {
  return {
    isReducer,
    reddetReducer,
    kabulEtReducer,
  };
};

const mapDispatchToProps = {
  isiGetir,
  isiReddet,
  isiKabulEt,
};

KanbanDetail.propTypes = {
  isReducer: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(KanbanDetail);
