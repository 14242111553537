import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

const KriterBulunamadi = (props) => {
  return (
    <aside>
      <aside className="bulunmuyors">
        <i className="fa fa-exclamation-triangle" aria-hidden="true"></i>
        <p>
          {props.nerede} {props.islem} İşiniz Bulunmamaktadır.
        </p>
      </aside>
    </aside>
  );
};

const mapStateToProps = ({ kapatilanReducer }) => {
  return {
    kapatilanReducer,
  };
};
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(KriterBulunamadi);
