import React, { useState, useEffect } from "react";
import logo from "../../helpers/images/istakibison3.png";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { siteBilgileriniGetir } from "../../redux/actions/yoneticiAction";
import Fab from "@material-ui/core/Fab";
import FilterListIcon from "@material-ui/icons/FilterList";
import CloseIcon from "@material-ui/icons/Close";

const Header = (props) => {
  var yoneticiToken = sessionStorage.getItem("tokenKey");
  // useEffect(() => {
  //   if (sessionStorage.getItem("tokenKey")) {
  //     props.siteBilgileriniGetir(
  //       sessionStorage.getItem("SiteID"),
  //       sessionStorage.getItem("SunucuID")
  //     );
  //   }
  // }, []);
  const logout = () => {
    sessionStorage.clear();
    window.location.href = "/index.html?exit=1";
  };
  const [isToggle, setToggle] = useState(false);

  const acik = { display: "block" };
  const kapali = { display: "none" };
  const toggleCalis = (event) => {
    setToggle(!isToggle);
  };

  return (
    <div>
      <div className="headerWrapper">
        <aside className="headlogowrap">
          <aside className="logowrap">
            <img src={logo} className="headlogo" alt="logojpg" />
            {yoneticiToken ? (
              <div className="dikey-ikili">
                <p className="siteBaslikAdi">
                  {sessionStorage.getItem("SiteAdi")}
                </p>
                <p className="siteUygulamaBaslik">Yönetici Uygulaması</p>
              </div>
            ) : null}
          </aside>
          <nav className="navWrapper">
            {isToggle === true ? (
              <div className="darkOverlay darks"></div>
            ) : null}
            <div className="hamMenu">
              <i
                onClick={toggleCalis}
                className="fa fa-bars"
                aria-hidden="true"
              ></i>
            </div>
            <aside className="kapatIcon">
              <ul
                style={isToggle === true ? acik : kapali}
                className="mobilMenu"
              >
                <Fab
                  className="fab closeic closes"
                  onClick={toggleCalis}
                  size="medium"
                  arial-label="close"
                >
                  <CloseIcon></CloseIcon>
                </Fab>
                {yoneticiToken ? null : (
                  <li>
                    <NavLink
                      activeClassName="aktifheader"
                      onClick={toggleCalis}
                      style={isToggle === true ? acik : kapali}
                      className="navlinkstab"
                      to="/account"
                    >
                      <i className="fa fa-user-circle" aria-hidden="true"></i>
                      Hesabım
                    </NavLink>
                  </li>
                )}

                <li>
                  <NavLink
                    activeClassName="aktifheader"
                    onClick={toggleCalis}
                    style={isToggle === true ? acik : kapali}
                    className="navlinksroute navlinkstab"
                    to="/kapatilan"
                  >
                    <i className="fa fa-lock" aria-hidden="true"></i>
                    Kapatılan İşler
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    activeClassName="aktifheader"
                    onClick={toggleCalis}
                    style={isToggle === true ? acik : kapali}
                    className="navlinkstab"
                    to="/about"
                  >
                    <i className="fa fa-question-circle" aria-hidden="true"></i>
                    Uygulama Hakkında
                  </NavLink>
                </li>
                <li>
                  <div onClick={logout} className="navlinkstab">
                    <i className="fa fa-sign-out" aria-hidden="true"></i>
                    Güvenli Çıkış
                  </div>
                </li>
              </ul>
            </aside>

            <ul className="ulWrapper">
              <li>
                <NavLink
                  activeClassName="aktifheader"
                  className="navlinkstab"
                  to="/about"
                >
                  <i className="fa fa-question-circle" aria-hidden="true"></i>
                  Uygulama Hakkında
                </NavLink>
              </li>
              {yoneticiToken ? null : (
                <li>
                  <NavLink
                    activeClassName="aktifheader"
                    className="navlinkstab"
                    to="/account"
                  >
                    <i className="fa fa-user-circle" aria-hidden="true"></i>
                    Hesabım
                  </NavLink>
                </li>
              )}

              <li>
                <div onClick={logout} className="navlinkstab">
                  <i className="fa fa-sign-out" aria-hidden="true"></i>
                  Güvenli Çıkış
                </div>
              </li>
            </ul>
          </nav>
        </aside>
      </div>
    </div>
  );
};
const mapStateToProps = ({ yoneticiSiteReducer }) => {
  return {
    yoneticiSiteReducer,
  };
};

const mapDispatchToProps = { siteBilgileriniGetir };

export default connect(mapStateToProps, mapDispatchToProps)(Header);
