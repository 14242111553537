import axios from "axios";
import sayilariGetir from "./bekleyenAction";
import { getJson } from "./yoneticiAction";
import { sorguYolBaslangic } from "./isGuncelleActions";

export const FETCH_DEVAM_PENDING = "FETCH_DEVAM_PENDING";
export const FETCH_DEVAM_FULFILLED = "FETCH_DEVAM_FULFILLED";
export const FETCH_DEVAM_REJECTED = "FETCH_DEVAM_REJECTED";

var currentToken = sessionStorage.getItem("token");

export const devamEdenCagir = () => {
  return {
    type: FETCH_DEVAM_PENDING,
    fetching: true,
  };
};

export const devamEdenGelsin = (devamedens) => {
  return {
    type: FETCH_DEVAM_FULFILLED,
    fetching: false,
    payload: devamedens,
  };
};
export const devamEdenHata = (error) => {
  return {
    type: FETCH_DEVAM_REJECTED,
    fetching: false,
    payload: error,
  };
};

export const devamEdenleriGetir = () => {
  return function (dispatch) {
    dispatch(devamEdenCagir());
    axios
      .get("/yc/sorgu?sorguid=10002", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + currentToken,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          const devamedens = response.data;
          const sayilarD = response.data.Table1[0];
          dispatch(devamEdenGelsin(devamedens));
          dispatch(sayilariGetir(sayilarD));
        }
      })
      .catch((error) => {
        dispatch(devamEdenHata(error.message));
        window.location.href = "/index.html";
        sessionStorage.clear();
      });
  };
};

export const yoneticiDevamEdenleriGetir = () => {
  return (dispatch) => {
    dispatch(devamEdenCagir());
    getJson(
      `${sorguYolBaslangic}istakibi/sorgu`,
      `&sorguid=1004${
        sessionStorage.getItem("proid")
          ? `&proid=${sessionStorage.getItem("proid")}`
          : ""
      }`,
      (dt) => {
        dispatch({
          type: FETCH_DEVAM_FULFILLED,
          fetching: false,
          payload: dt.data.Table,
        });
      }
    );
  };
};
