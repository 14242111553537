import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { tamamlananlariGetir } from "../../redux/actions/tamamlananAction";
import KanbanDevamItem from "../pages/KanbanDevamItem";
import { RingLoader } from "react-spinners";
import { yoneticiTamamlananlariGetir } from "../../redux/actions/tamamlananAction";
import Selects from "./Selects";
import DialogScreen from "./DialogScreen";

const Success = (props) => {
  const [modal, setModal] = useState(false);
  const [uyari, setUyari] = useState(false);
  const [yonetici, setYonetici] = useState(false);
  const [yoneticiTamamlananlar, setYoneticiTamamlananlar] = useState(null);
  useEffect(() => {
    if (sessionStorage.getItem("token")) {
      props.tamamlananlariGetir();
      setYonetici(false);
    }
    if (sessionStorage.getItem("tokenKey")) {
      props.yoneticiTamamlananlariGetir();
      setYonetici(true);
    }
  }, []);

  useEffect(() => {
    setYoneticiTamamlananlar(props.tamamlananReducer.tamamlananlarYonetici);
  }, [props.tamamlananReducer.tamamlananlarYonetici]);
  const openModal = (e) => {
    props.guncellenecekIsiGetir(props.id);
    setModal(!modal);
    setUyari(true);
  };
  const tamamlananTable = props.tamamlananReducer.Table;
  return (
    <div>
      {props.tamamlananReducer.fetching ? (
        <div className="load">
          <RingLoader size={100} color={"#D21113"} loading={true}></RingLoader>
        </div>
      ) : null}
      {uyari ? (
        <DialogScreen
          open={openModal}
          yonetici={true}
          dialog={uyari}
          isid={props.id}
          islem={"İşi"}
          olay={"güncellemek"}
          dialogKapat={() => setUyari(false)}
        ></DialogScreen>
      ) : null}
      {!yonetici ? (
        <aside className="secNavWrapper">
          {tamamlananTable &&
            tamamlananTable.map((item, i) => (
              <KanbanDevamItem
                key={i}
                id={item.id}
                devamEdenIs={item.id}
                durum={item.isindurumu}
                ad={item.siteadi}
                yenimesaj={item.yeni}
                teslimtarihi={item.isintarihi}
                olusturmatarihi={item.olusturmatarihi}
                aciklama={item.isinaciklamasi}
              ></KanbanDevamItem>
            ))}
        </aside>
      ) : (
        <aside>
          <Selects is={"tamamlanan"}></Selects>
        </aside>
      )}
    </div>
  );
};
const mapStateToProps = ({ tamamlananReducer }) => {
  return {
    tamamlananReducer,
  };
};
const mapDispatchToProps = {
  tamamlananlariGetir,
  yoneticiTamamlananlariGetir,
};
export default connect(mapStateToProps, mapDispatchToProps)(Success);
