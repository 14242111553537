import {
  DOSYA_IS_PENDING,
  DOSYA_IS_FULFILLED,
  DOSYA_IS_REJECTED
} from "../actions/isAction";

const initialState = {
  data: {},
  fetching: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOSYA_IS_PENDING:
      return { ...state, fetching: true };

    case DOSYA_IS_FULFILLED:
      return {
        ...state,
        data: action.payload,
        fetching: true
      };
    case DOSYA_IS_REJECTED:
      return { error: action.payload, fetching: false, ...state };

    default:
      return state;
  }
};
