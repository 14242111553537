import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import KanbanDevamItem from "../pages/KanbanDevamItem";
import { siteTanimlariniGetir } from "../../redux/actions/yoneticiAction";
import KriterBulunamadi from "./KriterBulunamadi";
import Fab from "@material-ui/core/Fab";
import FilterListIcon from "@material-ui/icons/FilterList";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";

const Selects = (props) => {
  const [modal, setModal] = useState(false);
  const [uyari, setUyari] = useState(false);
  const [pro, setPro] = useState(false);
  const [isDurum, setIsDurum] = useState();
  const [isTurleri, setIsTurleri] = useState([
    { id: "", tanim: "İş Türü Seçebilirsiniz.." },
    { id: 1, tanim: "ÖNERİ İSTEK" },
    { id: 2, tanim: "BİLGİ TALEBİ" },
    { id: 3, tanim: "ARIZA" },
    { id: 5, tanim: "ÖDEME EMRİ" },
    { id: 4, tanim: "DİĞER" },
  ]);
  const [yoneticiler, setYoneticiler] = useState("");
  const [siteler, setSiteler] = useState("");
  const [secilebilenYoneticiler, setSecilebilenYoneticiler] = useState("");
  const [secilenIsler, setSecilenIsler] = useState("");
  const [personeller, setPersoneller] = useState("");
  const [isAlanlari, setIsAlanlari] = useState("");
  const [isler, setIsler] = useState("");
  const [secili, setSecili] = useState({
    yonetici: "",
    personel: "",
    gorevid: "",
    tur: "",
    site: "",
    sc: "",
  });
  const isDurumlari = ["Devam Eden", "Tamamlanan"];

  const popUpAc = (e) => {
    setModal(!modal);
    setUyari(!uyari);
  };
  useEffect(() => {
    setPersoneller(props.siteTanimReducer.personeller);
    setIsAlanlari(props.siteTanimReducer.bilgiler.Table);
    if (props.is === "devam") {
      setIsler(props.devamEdenReducer.devamedenler);
      setIsDurum(isDurumlari[0]);
    }
    if (props.is === "tamamlanan") {
      setIsler(props.tamamlananReducer.tamamlananlarYonetici);
      setIsDurum(isDurumlari[1]);
    }
    if (props.is === "kapatilan") {
      setIsler(props.kapatilanReducer.yoneticiKapatilanlar);
      setIsDurum("kapatilan");
    }
  }, [
    props.devamEdenReducer.devamedenler,
    props.is,
    props.tamamlananReducer.tamamlananlarYonetici,
    props.kapatilanReducer.yoneticiKapatilanlar,
    props.siteTanimReducer.bilgiler,
    props.siteTanimReducer.personeller,
    isDurumlari,
  ]);

  useEffect(() => {
    isler &&
      isler.forEach((v, i) => {
        setYoneticiler((yoneticiler) => [...yoneticiler, v.isiolusturan]);
      });
  }, [isler]);

  useEffect(() => {
    setSecilebilenYoneticiler(yoneticiler && yoneticiler.filter(onlyUnique));
  }, [yoneticiler]);

  useEffect(() => {
    setSiteler(JSON.parse(sessionStorage.getItem("siteler")));
  }, []);

  const onlyUnique = (gelen, index, aranacak) => {
    return aranacak.indexOf(gelen) === index;
  };
  const secerekgoster = (e) => {
    if (e.target.name === "site") {
    }
    setSecili({ ...secili, [e.target.name]: e.target.value });
  };

  // useEffect(() => {
  //   if (
  //     secili.personel ||
  //     secili.gorevid ||
  //     secili.tur ||
  //     secili.site ||
  //     secili.yonetici
  //   ) {
  //     console.log("ilk if girdi", { ...secili });
  //     isler &&
  //       isler.forEach((value, i) => {
  //         console.log("ilk döngü girdi", { ...secili });
  //         console.log(Number(secili.gorevid));
  //         console.log(value.gorevid);
  //         if (
  //           ((secili.personel && secili.personel === value.kullaniciid) ||
  //             !secili.personel) &&
  //           ((secili.gorevid && Number(secili.gorevid) === value.gorevid) ||
  //             !secili.gorevid) &&
  //           ((secili.tur && secili.tur === value.isinturu) || !secili.tur) &&
  //           ((secili.site && secili.site === value.siteid) || !secili.site) &&
  //           ((secili.yonetici && secili.yonetici === value.isiolusturanid) ||
  //             !secili.yonetici)
  //         ) {
  //           console.log("ilk if cevap girdi");
  //           setIsler((isler) => [...isler, value]);
  //         }
  //       });
  //   } else {
  //     console.log("ilk else girdi");
  //     setIsler(isler);
  //   }
  // }, [secili]);
  useEffect(() => {
    if (sessionStorage.getItem("proid")) {
      setPro(true);
    }
    setSecilenIsler([]);
    if (
      (secili.personel && secili.personel) ||
      (secili.gorevid && secili.gorevid) ||
      (secili.tur && secili.tur) ||
      (secili.site && secili.site) ||
      (secili.yonetici && secili.yonetici)
    ) {
      isler &&
        isler.forEach((v, i) => {
          if (
            ((Number(secili.gorevid) && Number(secili.gorevid) === v.gorevid) ||
              !secili.gorevid) &&
            ((secili.personel && secili.personel === v.kullaniciid) ||
              !secili.personel) &&
            ((Number(secili.tur) && Number(secili.tur) === v.isinturu) ||
              !secili.tur) &&
            ((Number(secili.site) && Number(secili.site) === v.siteid) ||
              !secili.site) &&
            ((secili.yonetici && secili.yonetici === v.isiolusturan) ||
              !secili.yonetici)
          ) {
            setSecilenIsler((secilenIsler) => [...secilenIsler, v]);
          }
        });
    } else {
      setSecilenIsler([...isler]);
    }
  }, [
    secili.personel,
    secili.yonetici,
    secili.gorevid,
    secili.site,
    secili.tur,
  ]);

  return (
    <div>
      {uyari ? (
        <aside>
          <Fab
            className="fab closeic"
            onClick={popUpAc}
            size="medium"
            arial-label="close"
          >
            <CloseIcon></CloseIcon>
          </Fab>
          <Fab
            className="fab doneic"
            onClick={popUpAc}
            size="medium"
            arial-label="close"
          >
            <DoneIcon></DoneIcon>
          </Fab>
        </aside>
      ) : (
        <Fab className="fab" onClick={popUpAc} size="medium" aria-label="add">
          <FilterListIcon />
        </Fab>
      )}
      {uyari ? <div className="darkOverlay"></div> : null}
      {uyari ? (
        <div className="selectsWrap">
          {pro ? (
            <select
              value={secili.site ? secili.site : ""}
              name={"site"}
              onChange={secerekgoster}
            >
              <option value={""}>Site/Apartman Seçebilirsiniz.</option>
              {siteler &&
                siteler.map((item, i) => (
                  <option value={item.ID} key={i}>
                    {item.SiteAdi}
                  </option>
                ))}
            </select>
          ) : null}
          <select
            value={secili.yonetici ? secili.yonetici : ""}
            name={"yonetici"}
            onChange={secerekgoster}
          >
            <option value={""}>İşi Oluşturan Seçebilirsiniz.</option>
            {secilebilenYoneticiler &&
              secilebilenYoneticiler.map((item, i) => (
                <option value={item} key={i}>
                  {item}
                </option>
              ))}
          </select>
          <select
            value={secili.gorevid ? secili.gorevid : ""}
            name={"gorevid"}
            onChange={secerekgoster}
          >
            <option value={""}>İş Alanı Seçebilirsiniz.</option>
            {isAlanlari &&
              isAlanlari.map((item, i) => (
                <option value={item.id} key={i}>
                  {item.tanim}
                </option>
              ))}
          </select>
          <select
            value={secili.tur ? secili.tur : ""}
            name={"tur"}
            onChange={secerekgoster}
          >
            {isTurleri &&
              isTurleri.map((item, i) => (
                <option value={item.id} key={i}>
                  {item.tanim}
                </option>
              ))}
          </select>
          <select
            value={secili.personel ? secili.personel : ""}
            name={"personel"}
            onChange={secerekgoster}
          >
            <option defaultValue={""}>Personel Seçebilirsiniz.</option>
            {personeller &&
              personeller.map((item, i) => (
                <option value={item.kulid} key={i}>
                  {item.ad}
                </option>
              ))}
          </select>
        </div>
      ) : null}

      <aside className="secNavWrapper">
        {secilenIsler &&
        secilenIsler.length >= 1 &&
        isDurum === isDurumlari[0] ? (
          secilenIsler &&
          secilenIsler.map((item, i) => (
            <KanbanDevamItem
              yonetici={true}
              key={i}
              p={isDurumlari[0]}
              gorevAdi={
                isAlanlari &&
                isAlanlari.filter((alans) => {
                  return Number(alans.id) === item.gorevid;
                })
              }
              gorevId={item.gorevid}
              isinTuru={
                isTurleri &&
                isTurleri.filter((turs) => {
                  return turs.id === item.isinturu;
                })
              }
              personel={
                personeller &&
                personeller.filter((pers) => {
                  return pers.kulid === item.kullaniciid;
                })
              }
              id={item.id}
              durumadi={item.durumadi}
              devamEdenIs={item.id}
              yenimesaj={item.yeni}
              mesajokundu={item.okundu}
              durum={item.isindurumu}
              siteSakin={item.adsoyad}
              isiolusturan={item.isiolusturan}
              ad={item.siteadi}
              teslimtarihi={item.isintarihi}
              olusturmatarihi={item.olusturmatarihi}
              aciklama={item.isinaciklamasi}
            ></KanbanDevamItem>
          ))
        ) : secili && secili.gorevid === "" && isDurum === isDurumlari[0] ? (
          isler &&
          isler.map((item, i) => (
            <KanbanDevamItem
              yonetici={true}
              key={i}
              p={isDurumlari[0]}
              gorevAdi={
                isAlanlari &&
                isAlanlari.filter((alans) => {
                  return Number(alans.id) === item.gorevid;
                })
              }
              gorevId={item.gorevid}
              isinTuru={
                isTurleri &&
                isTurleri.filter((turs) => {
                  return turs.id === item.isinturu;
                })
              }
              personel={
                personeller &&
                personeller.filter((pers) => {
                  return pers.kulid === item.kullaniciid;
                })
              }
              id={item.id}
              durumadi={item.durumadi}
              devamEdenIs={item.id}
              yenimesaj={item.yeni}
              mesajokundu={item.okundu}
              durum={item.isindurumu}
              ad={item.siteadi}
              siteSakin={item.adsoyad}
              isiolusturan={item.isiolusturan}
              teslimtarihi={item.isintarihi}
              olusturmatarihi={item.olusturmatarihi}
              aciklama={item.isinaciklamasi}
            ></KanbanDevamItem>
          ))
        ) : secilenIsler &&
          secilenIsler.length >= 1 &&
          isDurum === isDurumlari[1] ? (
          secilenIsler &&
          secilenIsler.map((item, i) => (
            <KanbanDevamItem
              yonetici={true}
              key={i}
              p={isDurumlari[1]}
              gorevAdi={
                isAlanlari &&
                isAlanlari.filter((alans) => {
                  return Number(alans.id) === item.gorevid;
                })
              }
              isinTuru={
                isTurleri &&
                isTurleri.filter((turs) => {
                  return turs.id === item.isinturu;
                })
              }
              personel={
                personeller &&
                personeller.filter((pers) => {
                  return pers.kulid === item.kullaniciid;
                })
              }
              id={item.id}
              devamEdenIs={item.id}
              durum={item.isindurumu}
              ad={item.siteadi}
              siteSakin={item.adsoyad}
              isiolusturan={item.isiolusturan}
              yenimesaj={item.yeni}
              mesajokundu={item.okundu}
              teslimtarihi={item.isintarihi}
              olusturmatarihi={item.olusturmatarihi}
              aciklama={item.isinaciklamasi}
            ></KanbanDevamItem>
          ))
        ) : secili && secili.gorevid === "" && isDurum === isDurumlari[1] ? (
          isler &&
          isler.map((item, i) => (
            <KanbanDevamItem
              yonetici={true}
              key={i}
              p={isDurumlari[1]}
              gorevAdi={
                isAlanlari &&
                isAlanlari.filter((alans) => {
                  return Number(alans.id) === item.gorevid;
                })
              }
              isinTuru={
                isTurleri &&
                isTurleri.filter((turs) => {
                  return turs.id === item.isinturu;
                })
              }
              personel={
                personeller &&
                personeller.filter((pers) => {
                  return pers.kulid === item.kullaniciid;
                })
              }
              gorevId={item.gorevid}
              id={item.id}
              durumadi={item.durumadi}
              devamEdenIs={item.id}
              yenimesaj={item.yeni}
              siteSakin={item.adsoyad}
              mesajokundu={item.okundu}
              isiolusturan={item.isiolusturan}
              durum={item.isindurumu}
              ad={item.siteadi}
              teslimtarihi={item.isintarihi}
              olusturmatarihi={item.olusturmatarihi}
              aciklama={item.isinaciklamasi}
            ></KanbanDevamItem>
          ))
        ) : secilenIsler &&
          secilenIsler.length >= 1 &&
          isDurum === "kapatilan" ? (
          secilenIsler &&
          secilenIsler.map((item, i) => (
            <KanbanDevamItem
              yonetici={true}
              key={item.id}
              p={isDurum}
              gorevAdi={
                isAlanlari &&
                isAlanlari.filter((alans) => {
                  return Number(alans.id) === item.gorevid;
                })
              }
              isinTuru={
                isTurleri &&
                isTurleri.filter((turs) => {
                  return turs.id === item.isinturu;
                })
              }
              personel={
                personeller &&
                personeller.filter((pers) => {
                  return pers.kulid === item.kullaniciid;
                })
              }
              id={item.id}
              devamEdenIs={item.id}
              durum={item.isindurumu}
              yenimesaj={item.yeni}
              isiolusturan={item.isiolusturan}
              ad={item.siteadi}
              mesajokundu={item.okundu}
              siteSakin={item.adsoyad}
              teslimtarihi={item.isintarihi}
              olusturmatarihi={item.olusturmatarihi}
              aciklama={item.isinaciklamasi}
            ></KanbanDevamItem>
          ))
        ) : secili && secili.gorevid === "" && isDurum === "kapatilan" ? (
          isler &&
          isler.map((item, i) => (
            <KanbanDevamItem
              yonetici={true}
              key={i}
              p={isDurum}
              gorevAdi={
                isAlanlari &&
                isAlanlari.filter((alans) => {
                  return Number(alans.id) === item.gorevid;
                })
              }
              isinTuru={
                isTurleri &&
                isTurleri.filter((turs) => {
                  return turs.id === item.isinturu;
                })
              }
              personel={
                personeller &&
                personeller.filter((pers) => {
                  return pers.kulid === item.kullaniciid;
                })
              }
              gorevId={item.gorevid}
              id={item.id}
              durumadi={item.durumadi}
              devamEdenIs={item.id}
              yenimesaj={item.yeni}
              mesajokundu={item.mesajokundu}
              durum={item.isindurumu}
              ad={item.siteadi}
              siteSakin={item.adsoyad}
              isiolusturan={item.isiolusturan}
              teslimtarihi={item.isintarihi}
              olusturmatarihi={item.olusturmatarihi}
              aciklama={item.isinaciklamasi}
            ></KanbanDevamItem>
          ))
        ) : (
          <KriterBulunamadi
            nerede={"Seçilen Kriterlerde "}
            islem={`${isDurum}`}
          ></KriterBulunamadi>
        )}
      </aside>
    </div>
  );
};

const mapStateToProps = ({
  kapatilanReducer,
  tamamlananReducer,
  siteTanimReducer,
  devamEdenReducer,
  yKullaniciBilgileriReducer,
}) => {
  return {
    siteTanimReducer,
    kapatilanReducer,
    devamEdenReducer,
    tamamlananReducer,
    yKullaniciBilgileriReducer,
  };
};
const mapDispatchToProps = { siteTanimlariniGetir };

export default connect(mapStateToProps, mapDispatchToProps)(Selects);
