import {
  FETCH_YKULLANICI_FULFILLED,
  FETCH_YKULLANICI_REJECTED,
  FETCH_YKULLANICI_PENDING,
} from "../actions/yoneticiAction";

const initialState = {
  yonetici: {},
  error: null,
  done: false,
  fetching: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_YKULLANICI_FULFILLED:
      return {
        ...state,
        yonetici: action.payload,
        done: true,
        error: null,
        fetching: false,
      };
    case FETCH_YKULLANICI_PENDING:
      return {
        ...state,
        done: false,
        error: null,
        fetching: true,
      };
    case FETCH_YKULLANICI_REJECTED:
      return { error: action.payload, fetching: false, done: false };

    default:
      return state;
  }
};
