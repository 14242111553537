import {
  MESAJ_SIL_PENDING,
  MESAJ_SIL_FULFILLED,
  MESAJ_SIL_REJECTED
} from "../actions/isAction";

const initialState = {
  Table: [],
  fetching: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MESAJ_SIL_PENDING:
      return { ...state, fetching: true };

    case MESAJ_SIL_FULFILLED:
      return {
        ...state,
        Table: action.payload.Table,
        fetching: false
      };

    case MESAJ_SIL_REJECTED:
      return { error: action.payload, fetching: false, ...state };

    default:
      return state;
  }
};
