import {
  GERI_IS_PENDING,
  GERI_IS_FULFILLED,
  GERI_IS_REJECTED
} from "../actions/isAction";

const initialState = {
  Table: [],
  fetching: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GERI_IS_PENDING:
      return { ...state, fetching: false };

    case GERI_IS_FULFILLED:
      return {
        ...state,
        fetching: true,
        Table: action.payload.Table
      };
    case GERI_IS_REJECTED:
      return { error: action.payload, fetching: false, ...state };

    default:
      return state;
  }
};
