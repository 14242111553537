import axios from "axios";
import { bilgilerimiGetir } from "../actions/isAction";
import { loadingTrue, loadingFalse } from "./yoneticiAction";

export const FETCH_SAYILAR_PENDING = "FETCH_SAYILAR_PENDING";
export const FETCH_SAYILAR_FULFILLED = "FETCH_SAYILAR_FULFILLED";
export const FETCH_SAYILAR_REJECTED = "FETCH_SAYILAR_REJECTED";

export const FETCH_BEKLEYEN_PENDING = "FETCH_BEKLEYEN_PENDING";
export const FETCH_BEKLEYEN_FULFILLED = "FETCH_BEKLEYEN_FULFILLED";
export const FETCH_BEKLEYEN_REJECTED = "FETCH_BEKLEYEN_REJECTED";

export const FETCH_BEKLEYEN_YONETICI_PENDING =
  "FETCH_BEKLEYEN_YONETICI_PENDING";
export const FETCH_BEKLEYEN_YONETICI_FULFILLED =
  "FETCH_BEKLEYEN_YONETICI_FULFILLED";
export const FETCH_BEKLEYEN_YONETICI_REJECTED =
  "FETCH_BEKLEYEN_YONETICI_REJECTED";

// export const bekleyenleriGetir = () => {
var currentToken = sessionStorage.getItem("token");
var yoneticiToken = sessionStorage.getItem("tokenKey");

//   return dispatch => {
//     dispatch({
//       type: "FETCH_BEKLEYEN",
//       payload: fetch("/yc/sorgu?sorguid=1000&id=104", {
//         method: "GET",
//         async: true,
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: "Bearer " + currentToken
//         }
//       })
//         .then(result => {
//           return result.json();
//         })
//         .then(data => {
//           return data;
//         })
//     });
//   };
// // };

export const bekleyenCagir = () => {
  return {
    type: FETCH_BEKLEYEN_PENDING,
    fetching: true,
  };
};

export const bekleyenGelsin = (bekleyenler) => {
  return {
    type: FETCH_BEKLEYEN_FULFILLED,
    fetching: false,
    payload: bekleyenler,
  };
};
export const bekleyenHata = (error) => {
  return {
    type: FETCH_BEKLEYEN_REJECTED,
    fetching: false,
    payload: error,
  };
};

export const bekleyenYoneticiCagir = () => {
  return {
    type: FETCH_BEKLEYEN_YONETICI_PENDING,
    fetching: true,
  };
};

export const bekleyenYoneticiGelsin = (bekleyenler) => {
  return {
    type: FETCH_BEKLEYEN_YONETICI_FULFILLED,
    fetching: false,
    payload: bekleyenler,
  };
};
export const bekleyenYoneticiHata = (error) => {
  return {
    type: FETCH_BEKLEYEN_YONETICI_REJECTED,
    fetching: false,
    payload: error,
  };
};

export const bekleyenleriGetir = () => {
  return function (dispatch) {
    dispatch(bekleyenCagir());
    dispatch(sayilariCagir());
    axios
      .get("/yc/sorgu?sorguid=10001", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + currentToken,
        },
      })
      .then((response) => {
        const status = response.status;
        if (status === 200) {
          const bekleyens = response.data;
          const sayilarB = response.data.Table1[0];
          dispatch(bekleyenGelsin(bekleyens));
          dispatch(sayilariGoster(sayilarB));
        } else if (status === 401) {
          window.location.href = "/index.html";
          sessionStorage.clear();
        } else {
          window.location.href = "/index.html";
        }
      })
      .catch((error) => {
        dispatch(bekleyenHata(error.message));
        dispatch(sayilarHata(error.message));
        window.location.href = "/index.html";
        sessionStorage.clear();
      });
  };
};
export const yoneticiBekleyenleriGetir = (API_YC_BASE, siteid, sunucuid) => {
  return function (dispatch) {
    dispatch(loadingTrue());

    axios
      .get(
        `${API_YC_BASE}/istakibi/sorgu?siteId=${siteid}&sunucuId=${sunucuid}&strQuery=&sorguid=1002${
          sessionStorage.getItem("proid")
            ? `&proid=${sessionStorage.getItem("proid")}`
            : ""
        }`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + yoneticiToken,
          },
        }
      )
      .then((response) => {
        const bekleyens = response.data;
        dispatch(bekleyenYoneticiGelsin(bekleyens));
        dispatch(loadingFalse());
      })
      .catch((error) => dispatch(bekleyenYoneticiHata(error.message)));
  };
};

const sayilariGoster = (sayilar) => {
  return {
    type: FETCH_SAYILAR_FULFILLED,
    payload: sayilar,
    fetched: true,
    fetching: false,
  };
};

export const sayilariCagir = () => {
  return {
    type: FETCH_SAYILAR_PENDING,
    fetched: false,
    fetching: true,
  };
};
export const sayilarHata = (error) => {
  return {
    type: FETCH_SAYILAR_REJECTED,
    fetched: false,
    payload: error,
    fetching: false,
  };
};

export default sayilariGoster;
