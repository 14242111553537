import React, { useState, useEffect } from "react";
import { devamEdenleriGetir } from "../../redux/actions/devamEdenAction";
import { yoneticiDevamEdenleriGetir } from "../../redux/actions/devamEdenAction";
import KanbanDevamItem from "../pages/KanbanDevamItem";
import { RingLoader } from "react-spinners";
import { connect } from "react-redux";
import Selects from "./Selects";
import DialogScreen from "./DialogScreen";

const Procces = (props) => {
  const [modal, setModal] = useState(false);
  const [uyari, setUyari] = useState(false);
  const [filtre, setFiltre] = useState(false);

  const [isloading, setloading] = useState(false);
  const [table1L, setTable] = useState(props.devamEdenReducer.Table);
  const [yonetici, setYonetici] = useState(false);
  const [devamEdenYonetici, setDevamEdenYonetici] = useState(null);

  useEffect(() => {
    if (sessionStorage.getItem("token")) {
      props.devamEdenleriGetir();
      setYonetici(false);
      setloading(true);
      setTable(props.devamEdenReducer.Table);
    }
    if (sessionStorage.getItem("tokenKey")) {
      props.yoneticiDevamEdenleriGetir();
      setYonetici(true);
    }
  }, []);
  const openModal = (e) => {
    if (sessionStorage.getItem("tokenKey")) {
      setModal(!modal);
      setUyari(true);
    }
  };

  useEffect(() => {
    setDevamEdenYonetici(props.devamEdenReducer.devamedenler);
  }, [props.devamEdenReducer.devamedenler]);
  const gelenTable = props.devamEdenReducer.Table;

  return (
    <div>
      {filtre === true ? <div className="darkOverlay"></div> : null}
      {props.devamEdenReducer.fetching ? (
        <div className="load">
          <RingLoader size={100} color={"#D21113"} loading={true}></RingLoader>
        </div>
      ) : null}
      {uyari ? (
        <DialogScreen
          open={openModal}
          yonetici={true}
          dialog={uyari}
          isid={props.id}
          islem={"İşi"}
          olay={"güncellemek"}
          dialogKapat={() => setUyari(false)}
        ></DialogScreen>
      ) : null}
      {!yonetici ? (
        <aside className="secNavWrapper">
          {gelenTable &&
            gelenTable.map((item) => (
              <KanbanDevamItem
                key={item.id}
                id={item.id}
                durumadi={item.durumadi}
                devamEdenIs={item.id}
                yenimesaj={item.yeni}
                mesajokundu={item.mesajokundu}
                durum={item.isindurumu}
                ad={item.siteadi}
                teslimtarihi={item.isintarihi}
                olusturmatarihi={item.olusturmatarihi}
                aciklama={item.isinaciklamasi}
              ></KanbanDevamItem>
            ))}
        </aside>
      ) : (
        <aside>
          <Selects is={"devam"}></Selects>
        </aside>
      )}
    </div>
  );
};
const mapStateToProps = ({ devamEdenReducer }) => {
  return {
    devamEdenReducer,
  };
};

const mapDispatchToProps = {
  devamEdenleriGetir,
  yoneticiDevamEdenleriGetir,
};

export default connect(mapStateToProps, mapDispatchToProps)(Procces);
