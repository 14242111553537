import React from "react";
import LoginPage from "../pages/LoginPage";
import { Route } from "react-router-dom";
import DashboardPage from "../pages/DashboardPage";

function IsTakipApp() {
  return (
    <div className="wrapper">
      {sessionStorage.getItem("tokenKey") || sessionStorage.getItem("token") ? (
        <Route component={DashboardPage}></Route>
      ) : (
        <Route component={LoginPage}></Route>
      )}
    </div>
  );
}

export default IsTakipApp;
