import {
  FETCH_ISOLUSTUR_PENDING,
  FETCH_ISOLUSTUR_FULFILLED,
  FETCH_ISOLUSTUR_REJECTED,
  FETCH_POST_FULFILLED,
  FETCH_DOSYALAR_FULFILLED,
  FETCH_POSTJSON_FULLFILLED,
  FETCH_DOSYASIL_FULFILLED,
} from "../actions/yoneticiAction";

const initialState = {
  bilgiler: {},
  error: null,
  dosyalar: [],
  posts: {},
  postJson: {},
  done: false,
  fetching: false,
};

export const FETCH_DOSYALAR_BOSALT = "FETCH_DOSYALAR_BOSALT";

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ISOLUSTUR_PENDING:
      return { fetching: true, error: null, done: false };

    case FETCH_ISOLUSTUR_FULFILLED:
      return {
        ...state,
        bilgiler: action.payload,
        done: true,
        error: null,
        fetching: false,
      };
    case FETCH_POST_FULFILLED:
      return {
        ...state,
        posts: action.dosya.Table[0],
        done: true,
        error: null,
        fetching: false,
      };
    case FETCH_POSTJSON_FULLFILLED:
      return {
        ...state,
        postJson: action.payload,
        done: true,
        fetching: false,
        error: null,
      };
    case FETCH_DOSYALAR_FULFILLED:
      return {
        ...state,
        dosyalar: [...state.dosyalar, action.dosyalar[0]],
        done: true,
        error: null,
        fetching: false,
      };
    case FETCH_DOSYALAR_BOSALT:
      return {
        ...state,
        dosyalar: [],
      };
    case FETCH_DOSYASIL_FULFILLED:
      return {
        ...state,
        dosyalar: state.dosyalar.filter((item) => item.id !== action.dosyaId),
      };
    case FETCH_ISOLUSTUR_REJECTED:
      return { error: action.payload, fetching: false, done: false };

    default:
      return state;
  }
};
