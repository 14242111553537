import React from "react";
import IsTakipApp from "../src/components/containers/IsTakipApp";

function App() {
  return (
    <div className="App">
      <IsTakipApp />
    </div>
  );
}

export default App;
