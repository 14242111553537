import {
  FETCH_SAYILAR_PENDING,
  FETCH_SAYILAR_FULFILLED,
  FETCH_SAYILAR_REJECTED
} from "../actions/bekleyenAction";

const initialState = {
  data: {},
  fetching: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SAYILAR_PENDING:
      return { ...state, fetching: true };

    case FETCH_SAYILAR_FULFILLED:
      return {
        ...state,
        data: action.payload,
        fetching: false
      };
    case FETCH_SAYILAR_REJECTED:
      return { error: action.payload, fetching: false, ...state };

    default:
      return state;
  }
  
};
