import {
  FETCH_SITEBILGI_PENDING,
  FETCH_SITEBILGI_FULFILLED,
  FETCH_SITEBILGI_REJECTED,
} from "../actions/yoneticiAction";

const initialState = {
  bilgiler: {},
  error: null,
  done: false,
  fetching: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SITEBILGI_PENDING:
      return { fetching: true, error: null, done: false };

    case FETCH_SITEBILGI_FULFILLED:
      return {
        ...state,
        bilgiler: action.payload,
        done: true,
        error: null,
        fetching: false,
      };
    case FETCH_SITEBILGI_REJECTED:
      return { error: action.payload, fetching: false, done: false };

    default:
      return state;
  }
};
