import {
  FETCH_YONETICI_PENDING,
  FETCH_YONETICI_FULFILLED,
  FETCH_YONETICI_REJECTED,
  HATA_GOSTER,
} from "../actions/yoneticiAction";

const initialState = {
  yonetici: [],
  telNo: "",
  error: null,
  siteler: [],
  done: false,
  kodid: "",
  fetching: false,
  sor: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_YONETICI_PENDING:
      return { ...state, fetching: true, error: null, done: false };

    case "KODID_FULFILLED":
      return {
        ...state,
        kodid: action.payload,
        done: true,
        error: null,
        fetching: false,
      };
    case HATA_GOSTER:
      return {
        ...state,
        done: false,
        error: action.payload,
        fetching: false,
      };

    case "KODSORMA_FULFILLED":
      return {
        ...state,
        sor: false,
        done: true,
        error: null,
        fetching: false,
      };

    case "KODSOR_FULFILLED":
      return {
        ...state,
        sor: true,
        done: true,
        error: null,
        fetching: false,
      };

    case FETCH_YONETICI_FULFILLED:
      return {
        ...state,
        yonetici: action.payload,
        telNo: action.telNo,
        siteler: action.payload[0].Siteler,
        done: true,
        error: null,
        fetching: false,
      };

    case FETCH_YONETICI_REJECTED:
      return { ...state, error: action.payload, fetching: false, done: false };

    default:
      return state;
  }
};
