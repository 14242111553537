import {
  TAMAMLANDI_IS_PENDING,
  TAMAMLANDI_IS_FULFILLED,
  TAMAMLANDI_IS_REJECTED
} from "../actions/isAction";

const initialState = {
  Table: [],
  fetching: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TAMAMLANDI_IS_PENDING:
      return { ...state, fetching: true };

    case TAMAMLANDI_IS_FULFILLED:
      return {
        ...state,
        fetching: false,
        Table: action.payload
      };
    case TAMAMLANDI_IS_REJECTED:
      return { error: action.payload, fetching: false, ...state };

    default:
      return state;
  }
};
