import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { RingLoader } from "react-spinners";
import {
  getCookie,
  yoneticiGirisYap,
  kontrol,
} from "../../redux/actions/yoneticiAction";
import { connect } from "react-redux";
import NumberFormat from "react-number-format";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import Basarili from "../../components/containers/Basarili";
import androidicon from "../../helpers/images/istakibiandroid.svg";
import iosicon from "../../helpers/images/istakibiios.svg";

const SiteleriGetir = (props) => {
  const [secSite, setSecSite] = useState(
    props.yoneticiReducer.yonetici[0].KullaniciAdi
  );
  const [smsModal, setSmsModal] = useState(false);
  const [smsSor, setSmsSor] = useState(false);
  const [kodid, setKodid] = useState(props.yoneticiReducer.kodid);
  const [sure, setSure] = useState(180);
  useEffect(() => {
    if (smsSor && sure > 0) {
      setTimeout(() => setSure(sure - 1), 1000);
    }
  });
  useEffect(() => {
    setKodid(props.yoneticiReducer.kodid);
  }, [props.yoneticiReducer.kodid]);

  useEffect(() => {
    setSmsSor(props.yoneticiReducer.sor);
  }, [props.yoneticiReducer.sor]);

  const [secilenSiteAdi, setSecilenSiteAdi] = useState("");
  const [sitebilgileri, setSiteBilgileri] = useState([]);
  const [proKullanici, setProKullanici] = useState(false);

  var siteBilgileri = [];

  const [pass, setPass] = useState();
  const [smsDogrulamaKodu, setSmsDogrulamaKodu] = useState(
    getCookie("YCkod") ? getCookie("YCkod") : ""
  );
  const [cihaziHatirla, setCihaziHatirla] = useState(props.yoneticiReducer.sor);

  const sitelerTable = props.yoneticiReducer.yonetici;

  const secilenSite = (event) => {
    var index = event.nativeEvent.target.selectedIndex;
    setSecSite(event.target.value);
    setSecilenSiteAdi(event.nativeEvent.target[index].text);
  };
  const onChangePass = (event) => {
    setPass(event.target.value);
  };

  const onChangeSms = (e) => {
    setSmsDogrulamaKodu(e.target.value);
    if (smsDogrulamaKodu === "____") {
      setSmsDogrulamaKodu("");
    }
  };
  const onChangeHatirla = (e) => {
    setCihaziHatirla(!cihaziHatirla);
  };

  const yoneticiGiris = (event) => {
    if (secSite && pass && kodid === "") {
      if (siteBilgileri.length === 1) {
        sessionStorage.setItem("SiteID", siteBilgileri[0].siteid);
        sessionStorage.setItem("SiteAdi", siteBilgileri[0].siteadi);
        sessionStorage.setItem("SunucuID", siteBilgileri[0].sunucuid);
        sessionStorage.setItem("Kullanici", siteBilgileri[0].kullaniciadi);
        // props.yoneticiGirisYap(
        //   secSite,
        //   pass,
        //   smsDogrulamaKodu,
        //   kodid,
        //   cihaziHatirla,
        //   props.setButtonGizle
        // );
        props.kontrol(
          secSite,
          pass,
          smsDogrulamaKodu,
          kodid,
          cihaziHatirla,
          props.setButtonGizle,
          setSmsModal
        );
      } else {
        var users = siteBilgileri.find(function (user) {
          if (
            user.siteadi === secilenSiteAdi &&
            user.kullaniciadi === secSite
          ) {
            sessionStorage.setItem("SiteID", user.siteid);
            sessionStorage.setItem("SiteAdi", user.siteadi);
            sessionStorage.setItem("SunucuID", user.sunucuid);
            sessionStorage.setItem("Kullanici", user.kullaniciadi);
            if (user.kullaniciadi.indexOf(".") !== -1) {
              sessionStorage.setItem(
                "proid",
                user.kullaniciadi.substr(11, user.kullaniciadi.length)
              );
            }
            props.kontrol(
              secSite,
              pass,
              smsDogrulamaKodu,
              kodid,
              cihaziHatirla,
              props.setButtonGizle,
              setSmsModal
            );
            // props.yoneticiGirisYap(
            //   secSite,
            //   pass,
            //   smsDogrulamaKodu,
            //   kodid,
            //   cihaziHatirla,
            //   props.setButtonGizle
            // );
            return user;
          } else if (secilenSiteAdi === "" && user.kullaniciadi === secSite) {
            if (user.kullaniciadi.indexOf(".") !== -1) {
              sessionStorage.setItem(
                "proid",
                user.kullaniciadi.substr(11, user.kullaniciadi.length)
              );
            }
            sessionStorage.setItem("SiteID", siteBilgileri[0].siteid);
            sessionStorage.setItem("SiteAdi", siteBilgileri[0].siteadi);
            sessionStorage.setItem("SunucuID", siteBilgileri[0].sunucuid);
            sessionStorage.setItem("Kullanici", siteBilgileri[0].kullaniciadi);
            props.kontrol(
              secSite,
              pass,
              smsDogrulamaKodu,
              kodid,
              cihaziHatirla,
              props.setButtonGizle,
              setSmsModal
            );
            // props.yoneticiGirisYap(
            //   secSite,
            //   pass,
            //   smsDogrulamaKodu,
            //   kodid,
            //   cihaziHatirla,
            //   props.setButtonGizle
            // );
            return user;
          }
        });
      }
    } else if (secSite && pass && smsDogrulamaKodu !== "" && kodid !== "") {
      props.yoneticiGirisYap(
        secSite,
        pass,
        smsDogrulamaKodu,
        kodid,
        cihaziHatirla,
        props.setButtonGizle
      );
    }
    event.preventDefault();
  };

  return (
    <div>
      {props.yonetici === 1 ? (
        <aside>
          {props.siteSakinleriReducer.fetching ? (
            <div className="load">
              <RingLoader
                size={100}
                color={"#648541"}
                loading={true}
              ></RingLoader>
            </div>
          ) : null}

          <div className="inps tek">
            <div className="pst">
              <div style={{ display: "flex" }}>
                <label className="sitesecimSelectLabel">
                  <i
                    className="fa fa-building siteSecIcon"
                    aria-hidden="true"
                  ></i>
                  Kayıtlı Apartman/Site Seçiniz
                </label>
              </div>
              <select
                defaultValue={secSite}
                onChange={secilenSite}
                className="inpSelect"
              >
                {sitelerTable &&
                  sitelerTable.map((data, i) => {
                    return data.Siteler.map((dt, index) => {
                      siteBilgileri.push({
                        kullaniciadi: data.KullaniciAdi,
                        siteid: dt.ID,
                        proid: data.proid,
                        sunucuid: dt.SunucuID,
                        siteadi: dt.SiteAdi,
                        kayittarihi: dt.KayitTarihi
                          ? dt.KayitTarihi
                          : "2016-12-15",
                        onaylimi: dt.OnayliMi,
                        lisansson: dt.LisansSon,
                      });

                      return (
                        <option value={data.KullaniciAdi} key={index}>
                          {dt.SiteAdi}
                        </option>
                      );
                    });
                  })}
              </select>
            </div>
          </div>
          {smsModal ? (
            <>
              {smsSor ? (
                <div className="inps tek">
                  <div className="pst">
                    <div style={{ display: "flex" }}>
                      <label className="sitesecimSelectLabel">
                        <i
                          className="fa fa-phone smsKodTel"
                          aria-hidden="true"
                        ></i>
                        Sms Doğrulama Kodunu Giriniz
                      </label>
                    </div>
                    <aside className="smsrow smsinp">
                      <NumberFormat
                        className="smsinp"
                        allowEmptyFormatting
                        placeholder="_____"
                        format="#####"
                        value={smsDogrulamaKodu}
                        onChange={onChangeSms}
                        mask="_"
                      />
                      <label className="ssm klnsr">Kalan Süre {sure}</label>
                    </aside>
                    <label className="ssm">
                      <input
                        onChange={onChangeHatirla}
                        className="hatirlacls"
                        type="checkbox"
                      ></input>
                      Bu cihaz için hatırla
                    </label>
                  </div>
                </div>
              ) : (
                <div className="inps tek flx">
                  <Basarili giris={true}></Basarili>
                  {/* 
                  <DoneAllIcon className="smsony"></DoneAllIcon>
                  <p className="gecerli">Sms onayı geçerli.</p> */}
                </div>
              )}
            </>
          ) : null}
          <div className="inps tek Son">
            <label></label>
            <div className="pst">
              <input
                onChange={onChangePass}
                placeholder="Parola giriniz"
                id="pass"
                name="pass"
                type="password"
              ></input>
              <i className="fa fa-lock parla" aria-hidden="true"></i>{" "}
            </div>
          </div>
          {props.yoneticiReducer.error ? (
            <p className="errorYazi">{props.yoneticiReducer.error}</p>
          ) : null}
          <div className="loginokbtn inps tek">
            <button type="button" onClick={yoneticiGiris}>
              Giriş Yap
            </button>
            <aside className="mobileIconlar">
              <a>
                <img className="iosicon" src={iosicon} alt="iosicon"></img>
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.yonetimcell.istakibi"
                target="_blank"
              >
                <img src={androidicon} alt="androidicon"></img>
              </a>
            </aside>
          </div>
        </aside>
      ) : null}
    </div>
  );
};
const mapStateToProps = ({ yoneticiReducer, siteSakinleriReducer }) => {
  return {
    yoneticiReducer,
    siteSakinleriReducer,
  };
};

const mapDispatchToProps = {
  yoneticiGirisYap,
  kontrol,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SiteleriGetir));
