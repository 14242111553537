import React, { useState, useEffect } from "react";
import bekleyenIconYolu from "../../helpers/images/time-leftmavi.png";
import devamEdenIconYolu from "../../helpers/images/islemdekiler.png";
import tamamlananIcon from "../../helpers/images/confirmationmavi.png";
import kapatilanIcon from "../../helpers/images/private.png";
import { guncellenecekIsiGetir } from "../../redux/actions/isGuncelleActions";
import { connect, useDispatch } from "react-redux";
import DialogScreen from "../pages/DialogScreen";
import Moment from "react-moment";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { NavLink } from "react-router-dom";

const KanbanDevamItem = (props) => {
  const [modal, setModal] = useState(false);
  const [uyari, setUyari] = useState(false);
  const [genislet, setGenislet] = useState(false);
  const [windowsize, setWindowSize] = useState({ width: undefined });

  const openModal = (e) => {
    if (sessionStorage.getItem("tokenKey")) {
      setModal(!modal);
      setUyari(true);
    }
  };

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
      });
      if (windowsize.width < 1200) {
        setGenislet(false);
      } else {
        setGenislet(true);
      }
    }

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, [windowsize.width]);
  return (
    <div className="kanbanItemWrapper">
      {uyari ? (
        <DialogScreen
          open={openModal}
          yonetici={true}
          dialog={uyari}
          p={props.p}
          isid={props.id}
          islem={"İşi"}
          olay={"güncellemek"}
          dialogKapat={() => setUyari(false)}
        ></DialogScreen>
      ) : null}
      {!props.yonetici ? (
        <NavLink to={`islemgorendetay/${props.id}`} className="kanbanItemClick">
          <aside
            onClick={openModal}
            className={`${
              props.yenimesaj !== 0 ? "msjokunmadi" : ""
            } kanbanItem`}
          >
            <aside className="itemLeft">
              {props.durumadi === "İŞLEME GERİ ALINDI" ? (
                <img src={devamEdenIconYolu} alt="işleme geri alınan iş"></img>
              ) : null}
              {props.durum === 2 || props.durum === 3 || props.durum === 4 ? (
                <img src={devamEdenIconYolu} alt="devam eden iş"></img>
              ) : null}
              {props.durum === 5 ? (
                <img src={tamamlananIcon} alt="iş tamamlandı."></img>
              ) : null}
              {props.durum === 7 ? (
                <img src={kapatilanIcon} alt="iş kapatıldı"></img>
              ) : null}
              {props.durum === 1 ? (
                <img src={bekleyenIconYolu} alt="bekleyen iş"></img>
              ) : null}
            </aside>
            <aside className="itemTop">
              {props.ad !== null ? <h4>{props.ad}</h4> : null}
              <p className="itemaciklama">{props.aciklama.replace(/<[^>]*>?/gm, ' ')}</p>

              <aside className="itemMiddle">
                <aside className="itemBottomInfo">
                  <b className="itemtarih">
                    <span>Kayıt Tarihi: </span>{" "}
                    {
                      <Moment format="DD/MMM/YYYY">
                        {props.olusturmatarihi}
                      </Moment>
                    }
                  </b>
                  {props.teslimtarihi !== null ? (
                    <p className="itemtarih">
                      <span className="brolcak"> Teslim Tarihi: </span>
                      <b>
                        {
                          <Moment format="DD/MMM/YYYY">
                            {props.teslimtarihi}
                          </Moment>
                        }
                      </b>
                    </p>
                  ) : null}
                </aside>
              </aside>
            </aside>
            {props.yenimesaj !== 0 ? (
              <span className="yeniIsGeldi">
                <b>{props.yenimesaj}</b>
              </span>
            ) : null}
          </aside>
        </NavLink>
      ) : (
        <div className="kanbanItemClick">
          <aside
            className={`${
              props.yenimesaj !== 0 ? "msjokunmadi" : ""
            } kanbanItem`}
          >
            <aside className="itemLeft">
              {props.durumadi === "İŞLEME GERİ ALINDI" ? (
                <img src={devamEdenIconYolu} alt="işleme geri alınan iş"></img>
              ) : null}
              {props.durum === 2 || props.durum === 3 || props.durum === 4 ? (
                <img src={devamEdenIconYolu} alt="devam eden iş"></img>
              ) : null}
              {props.durum === 5 ? (
                <img src={tamamlananIcon} alt="iş tamamlandı."></img>
              ) : null}
              {props.durum === 7 ? (
                <img src={kapatilanIcon} alt="iş kapatıldı"></img>
              ) : null}
              {props.durum === 1 ? (
                <img src={bekleyenIconYolu} alt="bekleyen iş"></img>
              ) : null}
            </aside>
            {genislet ? (
              <ExpandLessIcon
                onClick={() => {
                  setGenislet(!genislet);
                }}
                className="expandicon"
              ></ExpandLessIcon>
            ) : (
              <ExpandMoreIcon
                onClick={() => {
                  setGenislet(!genislet);
                }}
                className="expandicon"
              ></ExpandMoreIcon>
            )}
            <aside onClick={openModal} className="itemTop">
              {props.ad !== null ? <h4>{props.ad}</h4> : null}
              <p className="itemaciklama">{props.aciklama.replace(/<[^>]*>?/gm, ' ')}</p>
              {genislet ? (
                <aside className="itemMiddle">
                  <aside className="itemBottomInfo">
                    <b className="itemtarih">
                      <span>Kayıt Tarihi: </span>{" "}
                      {
                        <Moment format="DD/MMM/YYYY">
                          {props.olusturmatarihi}
                        </Moment>
                      }
                    </b>
                    <b className="itemtarih">
                      <span>İş Oluşturan:</span>
                      {props.isiolusturan && props.isiolusturan
                        ? props.isiolusturan
                        : "---"}
                    </b>
                    <b className="itemtarih">
                      <span>İş Türü:</span>
                      {props.isinTuru[0] && props.isinTuru[0].tanim
                        ? props.isinTuru[0].tanim
                        : "---"}
                    </b>
                    <b className="itemtarih">
                      <span>Personel:</span>
                      {props.personel[0] && props.personel[0].ad
                        ? props.personel[0].ad
                        : "---"}
                    </b>
                    <b className="itemtarih">
                      <span>İş Alanı:</span>
                      {props.gorevAdi &&
                      props.gorevAdi[0] &&
                      props.gorevAdi[0].tanim
                        ? props.gorevAdi[0].tanim
                        : "---"}
                    </b>
                    <b className="itemtarih">
                      <span>Site Sakini:</span>

                      {props.siteSakin && props.siteSakin
                        ? props.siteSakin
                        : "---"}
                    </b>
                    {props.teslimtarihi !== null ? (
                      <p className="itemtarih">
                        <span className="brolcak"> Teslim Tarihi: </span>
                        <b>
                          {
                            <Moment format="DD/MMM/YYYY">
                              {props.teslimtarihi}
                            </Moment>
                          }
                        </b>
                      </p>
                    ) : null}
                  </aside>
                </aside>
              ) : null}
            </aside>
            {props.yenimesaj !== 0 ? (
              <span className="yeniIsGeldi">
                <b>{props.yenimesaj}</b>
              </span>
            ) : null}
          </aside>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = ({ isGuncelleReducer }) => {
  return {
    isGuncelleReducer,
  };
};
const mapDispatchToProps = {
  guncellenecekIsiGetir,
};
export default connect(mapStateToProps, mapDispatchToProps)(KanbanDevamItem);
