import {
  FETCH_SMS_PENDING,
  FETCH_SMS_FULFILLED,
  FETCH_SMS_REJECTED
} from "../actions/isAction";

const initialState = {
  data: null,
  error: false,
  fetching: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SMS_PENDING:
      return { fetching: true, error: false, data: null };

    case FETCH_SMS_FULFILLED:
      return {
        error: false,
        data: action.payload,
        fetching: false
      };
    case FETCH_SMS_REJECTED:
      return { error: action.payload, fetching: false, data: null };

    default:
      return state;
  }
};
